<template>
	<pop-up-window
		:show="this.$store.state.postDrop.displayAddDepartmentUser && this.$store.state.supplierUsersList.length"
		:showClose=false
		class="doc-statuses-popup"
		additional-info=""
		:positionFixed=true
		:top10=true
		:stickToScrollPosition=true
	>
		<template #title>
			<h1 class="text-2xl">{{ $t('postDrop.addDepartmentUserTitle') }}</h1>
			<h1>{{ name }}</h1>
		</template>
		<template #content>
			<div class="one-line">
				<h2 class="base-text header-text">{{ $t('postDrop.selectUser') }}:</h2>
				<v-select
					@click="setErrors(false)"
					v-model="userId"
					:options=this.$store.state.supplierUsersList
					:clearable="false"
				>
					<template #no-options="{search}">
						{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
					</template>
				</v-select>
			</div>
			<div v-if="errors">
				<p class="error error-styling">
					{{ $t('errors.emptyUser') }}
				</p>
			</div>
			<div class="grid grid-rows-1 justify-items-center my-6 grey-line">
				<div>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="close">
						{{ $t('buttons.cancel') }}
					</button>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="add">
						{{ $t('buttons.add') }}
					</button>
				</div>

			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow"

export default {
	name: "AddDepartmentUser",
	components: {PopUpWindow},
	data() {
		return {
			departmentId: '',
			name: '',
			userId: '',
			errors: false,
			pageSize: 50,
			search: '',
			options: []
		}
	},
	computed: {
		departmentData() {
			return this.$store.state.postDrop.departmentData
		},
		usersData() {
			return this.$store.state.postDrop.usersData
		}
	},
	watch: {
		usersData() {
			this.setDropdownOptions()
		},
		departmentData(newVal) {
			this.setDepartmentData(newVal)
		}
	},
	methods: {
		setDropdownOptions() {
			let options = []
			this.$store.state.postDrop.usersData.forEach(user => {
				let tempItem = {
					label: user.email + " / " + user.group,
					value: user.id
				}
				options.push(tempItem)
			})
			this.options = options
		},
		add() {
			if (!this.userId || !this.userId.value) {
				this.setErrors(true);
				return
			}
			let formData = {
				userId: this.userId.value,
				departmentId: this.departmentId
			}
			this.$store.dispatch("department/addDepartmentUser", formData);
			this.close()
		},
		setErrors(value) {
			this.errors = value
		},
		setDepartmentData(data) {
			if (data.departmentId) {
				this.departmentId = data.departmentId
				this.name = data.departmentName
			}
		},
		close() {
			this.$store.commit('displayAddDepartmentUser', false)
			this.userId = ''
			this.$store.commit('setDepartmentData', {})
			this.$store.commit("setUsersDataForPostDrop", [])
		}
	}
}
</script>

<style scoped>
.one-line {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100px auto;
	align-items: center;
	margin-top: 10px;
}
</style>