<template>
    <div class="intro-y">
        <loader-page/>
        <div class="page-container mt-6">
            <div class="page-container-inner mt-6">
                <div class="description">
                    <h2 class="text-lg text-center ">{{ $t('postDrop.uploadFileDescription') }}</h2>
                </div>

                <div class="upload-pdf">
                    <div class="one-item mt-6">
                        <div>
							<h3 class="font-bold">{{ $t('postDrop.validateFile') }}</h3>
                        </div>
                        <div>
                            <input
								type="file"
                                   ref="fileInput"
                                   accept=".pdf"
                                   class="form-control text-popup "
                                   @change="uploadFile"
                                   @click="cleanErrors()"
                            />
                        </div>
                    </div>

                    <div class="mt-2">
                        <p class="error" v-if="errors.fileLength">{{ $t('errors.attachFileIsEmpty') }}</p>
                        <p class="error" v-if="errors.fileType">{{ $t('errors.validateFileType') }}</p>
                    </div>
                    <div class="one-item mt-6">
                        <div>
                            <h3 class="font-bold">{{ $t('postDrop.postDropType') }}</h3>
                        </div>
                        <v-select
                              v-model="mailType"
                              :options='options'
                              :clearable='false'
                        >
                            <template #no-options="{search}">
                                {{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
                            </template>
                        </v-select>
                    </div>
                    <div class="btn-content mt-8 grey-top-border">
                        <div>
                            <button
								class="btn custom-button shadow-md mr-2 text-popup"
                                    @click="validate"
                            >
                                {{ $t('buttons.validate') }}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import i18n from "../../i18n"
import LoaderPage from "../UI/LoadingPage.vue"

export default {
    name: "ValidateFile",
    components: {LoaderPage},
    mounted(){
        this.$store.dispatch("updateToken")
    },
    beforeUnmount(){
        this.cleanErrors()
    },

    data() {
        return {
            i18n: i18n,
            file: null,
            mailType: {label: i18n.t('postDrop.postDropTypeList.regular'), value: 'REGULAR'},
            errors :{
                fileLength: false,
                fileType : false
            },
            options: [
                {label: i18n.t('postDrop.postDropTypeList.regular'), value: 'REGULAR'},
                {label: i18n.t('postDrop.postDropTypeList.registered'), value: 'REGISTERED'},
            ]
        }
    },
    watch: {
        locale() {
            let selectedMailType = this.mailType.value.toLowerCase()
            let key = 'postDrop.postDropTypeList.' + selectedMailType
            this.mailType.label = i18n.t(key)
            this.setOptions()
        }
    },
    computed: {
        locale() {
            return this.$store.state.locale
        },
        fileProcessing() {
            return i18n.t('general.fileProcessing')
        }
    },
    methods: {
        cleanErrors(){
            this.setError("fileLength", false)
            this.setError("fileType", false)
        },
        setOptions() {
            this.options.forEach(option => {
                let type = option.value.toLowerCase()
                let key = 'postDrop.postDropTypeList.' + type
                option.label = i18n.t(key)
            })
        },
        setError(field, value) {
            this.errors[field] = value
        },
        uploadFile() {
            this.file = this.$refs.fileInput.files[0]
        },
        clearFileInput() {
          const fileInput = this.$refs.fileInput
          if (fileInput) {
            fileInput.value = ''
          }
        },
        validate() {
            if (!this.file) {
                this.setError("fileLength", true)
                return
            }
            if(this.file.type !== "application/pdf"){
                this.setError("fileType", true)
                return
            }
            const file = new FormData()
            file.append('file', this.file)

            let formData = {
                fileName : this.file.name,
                file: file,
                mailType: this.mailType.value
            }
            this.clearFileInput();
            this.$store.commit("displayLoader", true)
            this.$store.dispatch('validateFilePostDrop', formData)
        }
    }
}
</script>

<style scoped>
.page-container {
    display: grid;
    grid-template-columns: 100%;
    grid-template-rows: 100%;
    justify-items: center;
}

.page-container-inner{
    width: 60%;
}

.description{
    margin-bottom: 20px;
}

.upload-pdf{
    width: 600px;
    margin: 0 auto;
}

.one-item {
    display: grid;
    grid-template-columns: 28% 72%;
    grid-template-rows: 100%;
    grid-auto-flow: row;
    align-items: center;
}

@media only screen and (max-width: 1100px) and (min-width: 850px) {
    .page-container-inner {
        width: 80%;
    }
}
@media only screen and (max-width: 849px)  {
    .page-container-inner {
        width: 100%;
    }
}

@media only screen and (max-width: 650px) {
    .one-item {
        grid-template-rows: auto auto;
        grid-template-columns: 100%;
    }
    .upload-pdf {
        width: 100%;
    }
}
</style>