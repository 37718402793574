<template>
    <div class="data-studio-container intro-y" v-if="tokenChecked">
        <div class="data-studio-container-inner">
            <iframe class="pdfContainer" :src=reportSrc title="Data studio pdf">
                <p>Your browser does not support PDFs.</p>
                <a target="_blank" :href=reportSrc>
                    <button class="btn custom-button w-full sm:w-16 mt-2 sm:mt-0 sm:ml-1">
                        View document
                        <p>description</p>
                    </button>
                </a>
            </iframe>
        </div>
    </div>
</template>

<script>
export default {
    name: "DataStudioContainer",
    data() {
        return {
            kcToken: this.$root.token,
            tokenChecked: false,
        }
    },
    mounted() {
        this.reportUrl()
    },
    methods: {
        reportUrl() {
            this.$store.dispatch('updateToken').then(() => {this.tokenChecked = true})
        }
    },
    computed: {
        reportSrc() {
            let domainName = window.location.hostname
            let reportSrc = ""
            let params = {
                "bffUrl": domainName + '/',
                "kcToken": this.kcToken
            }
            let paramsAsString = JSON.stringify(params)
            let encodedParams = encodeURIComponent(paramsAsString)
            reportSrc = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.reportUrl + "?params=" + encodedParams
            return reportSrc
        }
    }
}
</script>

<style scoped>
.pdfContainer {
    width: 100%;
    min-height: 800px;
}

.data-studio-container {
    display: grid;
    grid-template-rows: 100%;
    grid-template-columns: 100%;
    grid-auto-flow: column;
    justify-items: center;
}

.data-studio-container-inner {
    width: 90%
}

h1 {
    font-size: 20px;
    margin-bottom: var(--generalPaddings);
    text-align: center;
}

</style>