export default function getPaymentButton(payment, documentType) {
	let paid = false
	let pendingTran = false
	if (payment === undefined || payment.paymentUpdates === undefined) {
		return null
	}

	payment.paymentUpdates.forEach(update => {
		if (update.paymentStatus === "PENDING_TRAN") pendingTran = true
		if (update.paymentStatus === 'PAID'
			|| update.paymentStatus === "PAYMENT_CONFIRMED"
			|| update.paymentStatus === "PAID_ONLINE"
			|| update.paymentStatus === "PAID_TRAN") {
			paid = true
		}
	})

	if (documentType.toLowerCase() === "invoice" && pendingTran && !paid) return null
	else if (documentType.toLowerCase() === "invoice") {
		if (!(paid)) return payment.paymentLink
	}
	return null
}