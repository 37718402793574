<template>
	<div>
		<error-notification/>
		<!-- BEGIN: HTML Table Data -->
		<div id="tabulatorOuter">
			<div class="flex flex-row justify-between">
				<div class="quick-search-container" style="width: 400px" >
					<input
						type='text'
						v-model="filter.value"
						@keyup="quickSearch"
						:placeholder="$t('placeholders.search')"
						class="form-control text-popup"
						style="margin-right: 10px; height: 36px"
					/>
					<extended-search-filter
						current-page="nymdrop"
						@search="onFilter"
						class="mr-1.5"
					/>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" style="height: 36px;" @click="cleanQuickSearch">
						{{ $t('buttons.reset') }}
					</button>
				</div>
				<div class="refreshButton">
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="cleanQuickSearch">
						{{ $t('buttons.refresh') }}
					</button>
				</div>
			</div>
			<filter-label @remove-label="onFilter"/>
			<div class="totalNumberDocuments">
				{{ $t('table.totalNumberDocuments') }}: {{ totalDocuments }}
			</div>

			<div class="overflow-x-auto scrollbar-hidden">
				<div
					id="tabulator"
					ref="tableRef"
					class="table-report table-report--tabulator"
				/>
			</div>
		</div>
		<!-- END: HTML Table Data -->
	</div>
</template>

<script>
import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref, watch} from 'vue'
import feather from 'feather-icons'
import {TabulatorFull as Tabulator} from 'tabulator-tables'
import {useStore} from 'vuex'
import i18n from "../../../i18n"
import ErrorNotification from "../../UI/ErrorNotification"
import cash from "cash-dom"
import en from "../../../locales/en.json"
import nl from "../../../locales/nl.json"
import de from "../../../locales/de.json"
import fr from "../../../locales/fr.json"
import sk from "../../../locales/sk.json"
import es from "../../../locales/es.json"
import axios from 'axios'
import qs from "qs"
import moment from "moment"
import userGroups from "../../../userGoups"
import getStatusesHtml from "../../../mixins/nymdropTable/calcStatusesHtml"
import getStatusBar from "../../../mixins/nymdropTable/calcStatusBar"
import ExtendedSearchFilter from "@/components/extendedSearch/ExtendedSearchFilter.vue";
import FilterLabel from "@/components/extendedSearch/FilterLabel.vue";

const env = window.environment
export default defineComponent({
	components: {FilterLabel, ExtendedSearchFilter, ErrorNotification},
	emits: ['update:modelValue'],
	props: {
		token: String,
		supplierGroupId: String,
		requestUrl: String,
		modelValue: String,
	},
	setup(props) {
		const store = useStore()
		const requestUrl = ref(props.requestUrl)
		const supplierGroupId = ref(props.supplierGroupId)
		const totalDocuments = ref('')
		const searchTableValue = ref(store.state.postDropTableSearchValue)
		const tableRef = ref()
		const tabulator = ref()
		const pageSize = ref(10)
		const tableIsLoading = ref(false)
		const filter = reactive({
			value: searchTableValue
		})
		const allDocIds = ref([])

		let options = ref([])
		let selectedDocuments = ref([])
		let tableItems = ref(0)
		let showExtendedSearch = ref(store.state.postDrop.extendedSearch.displayExtendedSearch)

		let displayHandleErrors = computed(() => {
			return (userGroups.optipostNames.includes(store.state.currentUserData.data.group) || userGroups.supplierNames.includes(store.state.currentUserData.data.group))
		})
		let tableColumns = computed(() => {
			let columns = [
				{
					title: 'DEPARTMENT NAME',
					field: 'departmentName',
					cssClass: "text-centered",
					headerSort: true,
					minWidth: 220,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     	<div>${cell.getData().department}</div>
                                	</div>`)
						return a[0]
					}
				},
				{
					title: 'POSTDROP NAME',
					field: 'postDropName',
					cssClass: "text-centered",
					headerSort: true,
					minWidth: 220,
					formatter(cell) {
						const a = cash(
							`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     	<div>${cell.getData().postDropName}</div>
                                	</div>`)
						return a[0]
					}
				},
				{
					title: 'FILE NAME',
					field: 'fileName',
					cssClass: "text-centered",
					headerSort: false,
					minWidth: 220,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     	<div>${cell.getData().fileName}</div>
                                	</div>`)
						return a[0]
					}
				},
				{
					title: 'BATCH ID',
					field: 'batchId',
					cssClass: "text-centered",
					headerSort: false,
					minWidth: 220,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     	<div>${cell.getData().batchId}</div>
                                	</div>`)
						return a[0]
					}
				},
				{
					title: 'CREATED AT',
					field: 'createdAt',
					cssClass: "text-centered",
					headerSort: true,
					minWidth: 220,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                     	<div>${cell.getData().createdOn}</div>
                                	</div>`)
						return a[0]
					}
				},
				{
					title: 'PRINTER STATUSES',
					field: 'printerStatuses',
					cssClass: "text-centered color-grey",
					headerSort: false,
					width: 250,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center ${cell.getData().departmentIsDeletedClass}">
                                    	<div>${cell.getData().statusBar}</div>
                                	</div>`)
						return a[0]
					}
				}
			]
			return columns
		})


		watch(() => store.state.postDrop.departmentsUpdated, (newVal) => {
			if (newVal) {
				setTimeout(() => {
					initTabulator()
					store.commit("departmentsUpdated", false)
				}, 1000)
			}
		})
		watch(() => store.state.documentErrorHandled, (newVal) => {
			if (newVal) {
				setTimeout(() => {
					initTabulator()
				}, 1500)
			}
		})

		watch(() => store.getters.getLocale, function () {
			tabulator.value.setLocale(store.getters.getLocale)
		})

		const initTabulator = async () => {
			tabulator.value = new Tabulator(tableRef.value)
			let params = {
				supplierGroupId: supplierGroupId.value,
				pageNo: 1,
				pageSize: pageSize.value,
				quick: filter.value,
				extended: {}
			}

			if (store.state.extendedSearchValue.extendedSearch?.departmentCode?.value) {
				params.extended.departmentCode = store.state.extendedSearchValue.extendedSearch.departmentCode.value
			}

			if (store.state.extendedSearchValue.extendedSearch?.printStatus?.value) {
				params.extended.printStatus = store.state.extendedSearchValue.extendedSearch?.printStatus.value
			}

			if (store.state.tablePostdropSorters[0].order) {
				params.sortOn = store.state.tableSorters[0]
			}
			if (filter.value === '') {
				delete params.quick
			}
			await store.dispatch("updateToken")
			tabulator.value = new Tabulator(tableRef.value, {
				ajaxURL: requestUrl.value,
				ajaxConfig: {
					method: "POST",
					headers: {
						'Authorization': 'bearer ' + store.state.token,
						'Accept': 'application/json',
					},
				},
				ajaxParams: params,
				ajaxResponse: function (url, params, response) {
					let data = []
					const res = response.data.items

					totalDocuments.value = response.data.numResults
					tableItems.value = response.data.items.length

					for (let item of res) {
						let tempItem = {
							docId: item.docId,
							createdOn: moment(item.createdOn).format("DD/MM/YYYY"),
							department: item.documentData.department,
							dropDate: item.documentData.dropDate ? moment(item.documentData.dropDate).format("DD/MM/YYYY") : '-',
							postDropName: '-',
							departmentIsDeletedClass: '',
							fileName: '',
							statusesHTML: '',
							statusbar: '',
							allData: item,
							errorsHandled: false,
							errorsHandledClass: 'display-none',
							batchId: '-'
						}
						if (item.documentData && item.documentData.batch) {
							tempItem.batchId = item.documentData.batch
						}
						allDocIds.value.push(item.docId)

						let printStatuses = []
						tempItem.statusesHTML = getStatusesHtml(printStatuses, item.print)
						tempItem.statusBar = getStatusBar(printStatuses, item.documentData.errorsHandled)

						if (item.documentData.errorsHandled && printStatuses.includes('printerreturned')) {
							tempItem.errorsHandled = true
							tempItem.errorsHandledClass = ""
						}

						if (item.fileStructure) {
							let nameArray = item.fileStructure.opDocumentOriginalFileName.split('/')
							tempItem.fileName = nameArray[nameArray.length - 1]
						}

						let name = ''
						store.state.userDepartmentCodeList.forEach(department => {
							if (department.value === item.documentData.department) {
								name = department.label
							}
						})

						if (name === '') {
							name = item.documentData.department + " (Department DELETED)"
							tempItem.departmentIsDeletedClass = 'deleted-department'
						}
						tempItem.department = name

						if (item.print && item.print.postalAddress && item.print.postalAddress.companyName) {
							tempItem.postDropName = item.print.postalAddress.companyName
						}
						data.push(tempItem)
					}

					let last_page = Math.ceil(response.data.numResults / pageSize.value)
					return {
						last_page: last_page,
						data: data,
					}
				},
				dataSendParams: {
					page: "pageNo",
					size: "page_size",
				},
				ajaxURLGenerator: function (url, config, params) {
					updateToken()
					if (params.sort?.length > 0) {
						let sortFields = {
							dropDate: 'POST_DROP_DATE',
							createdAt: 'POST_DROP_CREATED_ON',
							departmentName: 'POST_DROP_DEPARTMENT',
							postDropName: 'POST_DROP_NAME',
						}

						store.commit('setTablePostdropSorters', {
							order: params.sort[0].dir.toUpperCase(),
							sortField: sortFields[params.sort[0].field].toUpperCase()
						})
						params.sortOn = {
							order: params.sort[0].dir.toUpperCase(),
							sortField: sortFields[params.sort[0].field]
						}
					} else {
						delete params.sortOn
					}
					return url
				},
				headerSortElement: "<span><i class='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
				ajaxContentType: "json",
				ajaxFiltering: true,
				printAsHtml: true,
				printStyled: true,
				pagination: true,
				sortMode: "remote",
				paginationMode: 'remote',
				paginationSize: pageSize.value,
				paginationInitialPage: store.state.tableInitialPage.mandates,
				layout: 'fitColumns',
				responsiveLayout: 'collapse',
				placeholder: i18n.t('placeholders.noMatchingFound'),
				rowClick: function (_, row) {
					store.commit('setProcessedDocumentData', row._row.data.allData)
				},
				locale: true,
				langs: {
					"en": en,
					"nl": nl,
					"de": de,
					"fr": fr,
					"sk": sk,
					"es": es,
				},
				columns: tableColumns.value,
			})
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
				tableIsLoading.value = false
			})
			tabulator.value.on('dataLoading', () => {
				tableIsLoading.value = true
			})
			tabulator.value.on('rowClick', async (e, row) => {
				await store.dispatch('updateToken')
				if (e.detail === 1 && store.state.tokenIsValid) {
					store.commit('setProcessedDocumentData', row._row.data.allData)
				}

			})
		}
		watch(tableIsLoading, () => {
			if (tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
		const tokenIsExpired = () => {
			let exp
			if (JSON.parse(atob(store.state.token.split('.')[1])).exp) {
				exp = parseInt(JSON.parse(atob(store.state.token.split('.')[1])).exp)
			}
			let now = parseInt(new Date().getTime() / 1000)
			return exp - now <= 0
		}
		const updateToken = () => {
			if (tokenIsExpired()) {
				let payload = {
					client_id: env.keycloakClient,
					grant_type: "refresh_token",
					refresh_token: store.state.refreshToken
				}

				let body = qs.stringify(payload)
				axios.post(store.state.API.keycloakToken, body, {
						headers: {
							'Content-Type': "application/x-www-form-urlencoded",
						}
					}).then(res => {
						store.commit('setToken', res.data.access_token)
						store.commit('setRefreshToken', res.data.refresh_token)
						initTabulator()
					}).catch(() => {
						store.commit("setGeneralNotificationText", {field: "sessionExpiredText", text: "Ok"})
						store.commit('displayNotification', true)
					})
			}
		}
		const handleErrors = () => {
			store.commit("setDocumentErrorHandled", false)
			store.dispatch("documents/handleErrors", {
				formData: {
					docIds: selectedDocuments.value,
					postDropDocuments: true
				}
			})
		}
		const cleanQuickSearch = () => {
			filter.value = ''
			store.commit("setPostDropTableSearch", filter.value)
			store.state.extendedSearchValue.extendedSearch = {}
			onFilter()
		}
		const setLocale = () => {
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
			})
		}
		const reInitOnResizeWindow = () => {
			window.addEventListener('resize', () => {
				tabulator.value.redraw()
				feather.replace({
					'stroke-width': 1.5
				})
			})
		}
		const quickSearch = () => {
			store.commit("setPostDropTableSearch", filter.value)
			store.commit("setTablePage", {field: "postDrop", pageNo: 1})
			onFilter()
		}
		const onFilter = () => {
			initTabulator()
		}

		const cleanExtendedSearchValues = () => {
			onFilter()
		}
		const fetchUsers = (search) => {
			let url = store.state.domainName + store.state.API.getUsers + "?size=500" + "&from=0" + "&filter=" + search
			store.dispatch("users/fetchUsers", url)
		}

		onMounted(() => {
			store.commit('setTablePostdropSorters', [{}])
			initTabulator()
			reInitOnResizeWindow()
			setLocale()
		})

		onBeforeUnmount(() => {
			store.commit("setDocumentErrorHandled", false)
			store.commit("setTablePage", {field: "postDrop", pageNo: tabulator.value.getPage()})
		})

		return {
			tableRef,
			filter,
			onFilter,
			totalDocuments,
			quickSearch,
			tabulator,
			cleanQuickSearch,
			tableItems,
			showExtendedSearch,
			cleanExtendedSearchValues,
			fetchUsers,
			options,
			selectedDocuments,
			handleErrors,
			displayHandleErrors
		}
	}
})
</script>

<style scoped>
.leg-entity-container img {
	padding-right: var(--generalPaddings);
}

.leg-entity-container .table-report img {
	border: none;
	box-shadow: none !important;
}

.totalNumberDocuments {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	justify-items: end;
	padding-top: 5px;
	padding-right: 8px;
}
.quick-search-container {
	display: flex;
	flex-direction: row;
	flex-basis: 400px;
}

.tabulator-buttons-container {
	display: grid;
	grid-template-columns: 90% 10%;
	grid-auto-flow: column;
	align-items: center;
}

.arrow-up:after {
	content: ' \2191';
}

.arrow-down:after {
	content: ' \2193';
}

/* Hide all icons by default */
.tabulator-col .tabulator-col-sorter i {
	display: none;
}

/* Display the fa-sort-up icon when the column is sorted in ascending order */
.tabulator-col[aria-sort="asc"] .tabulator-col-sorter i.arrow-up {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-down icon when the column is sorted in descending order */
.tabulator-col[aria-sort="desc"] .tabulator-col-sorter i.arrow-down {
	display: inline-block;
	color: var(--theme-primary-100);
}

.download-outer button {
	margin-left: 5px;
}

.refreshButton {
	width: auto;
	text-align: right;
}

.download-buttons .btn span {
	grid-column: 1/2;
	grid-row: 1/2;
}

.download-buttons .btn:disabled:hover {
	display: block;
}

.extended-search {
	margin-top: 10px;
	width: 40%;
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto auto;
	grid-gap: 10px;

}

.extended-search-check-box {
	margin-top: 10px;
	line-height: 1.5em;

}

.search-button {
	grid-column: 1/3;
	justify-items: center;
	display: grid;
}

.extended-search-check-box span {
	margin-right: 10px;
	position: relative;
	top: -5px;
}

.quickSearch {
	display: grid;
	grid-template-columns: 400px 100px;
	grid-template-rows: 100%;
	grid-column-gap: 10px;
}

.filter-container {
	width: 660px;
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto;
	grid-auto-flow: row;

}

.handle-error span {
	grid-column: 1/2;
	grid-row: 1/2;
}

@media only screen and (max-width: 560px) {
	.quickSearch {
		grid-template-columns: 80% 20%;
	}
}

@media only screen and (max-width: 750px) {
	.filter-container {
		width: 90%;
	}

	.extended-search {
		width: 100%;
	}
}

@media only screen and (max-width: 520px) {
	.extended-search {
		grid-template-columns: 100%;
	}
}
</style>