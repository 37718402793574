<template>
	<pop-up-window
		:show="this.$store.state.userManagement.displayEditUser"
		:showClose=false
		@close="closeEditUser"
		additional-info=""
		:positionFixed=true
		:top10=true
		:stickToScrollPosition=true
	>
		<template #title>
			<h1>{{ $t('management.editUser.title') }}</h1>
			<h1>{{ this.$store.state.userManagement.userData.email }}</h1>
		</template>
		<template #content>
			<div class="user-info">
				<div class="form-container">
					<div class="item-container text-popup">
						<h1 class="form-control color-grey">{{ this.$store.state.userManagement.userData.email }}</h1>
					</div>
					<div class="item-container text-popup">
						<v-select
							:options="langOptions"
							v-model="selectedUserLang"
							:clearable=false
						/>
					</div>
					<div class="item-container text-popup">
						<h1 class="form-control color-grey">{{ this.accessType }}</h1>
					</div>
					<div class="item-container text-popup">
						<v-select
							:options="this.accessRights"
							v-model="accessRight"
							:clearable=false
						/>
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-center my-6">
				<div>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="closeEditUser">
						{{ $t('buttons.cancel') }}
					</button>
					<button
						class="btn custom-button shadow-md mr-2 showStatuses text-popup"
						:disabled=disableUpdate
						@click="updateUser"
					>
						{{ $t('buttons.save') }}
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>

</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow.vue"
import userGroups from "../../../userGoups"

export default {
	name: "EditUser",
	components: {PopUpWindow},
	mounted() {
		this.setPortalData()
	},
	data() {
		return {
			userGroups: userGroups,
			selectedUserLang: {
				label: this.$store.state.userManagement.userData.language,
				value: this.$store.state.userManagement.userData.language
			},
			userLegalEntities: [],
			accessType: '',
			accessRight: '',
			accessRights: [],
			userId: '',
			formData: {
				language: '',
				group: '',
				legalEntities: []
			},
			postDrop: false,
			billing: false,
			peppol: false,
		}
	},
	computed: {
		disableUpdate() {
			return (this.billing && !this.postDrop && this.peppol && this.accessRight.value === 'SupplierSubUser' && !this.$store.state.userManagement.userData.legalEntities.length)
				|| (this.billing && !this.postDrop && !this.peppol && this.accessRight.value === 'SupplierSubUser' && !this.$store.state.userManagement.userData.legalEntities.length)
				|| (!this.billing && this.postDrop && this.peppol && this.accessRight.value === 'SupplierSubAdmin')
				|| (!this.billing && this.postDrop && this.peppol && this.accessRight.value === 'ReceiverAdmin')
				|| (!this.billing && this.postDrop && this.peppol && this.accessRight.value === 'ReceiverUser')
				|| (!this.billing && this.postDrop && !this.peppol && this.accessRight.value === 'SupplierSubAdmin')
				|| (!this.billing && this.postDrop && !this.peppol && this.accessRight.value === 'SupplierSubUser' && this.$store.state.userManagement.userData.legalEntities.length)
				|| (!this.billing && this.postDrop && !this.peppol && this.accessRight.value === 'ReceiverAdmin')
				|| (!this.billing && this.postDrop && !this.peppol && this.accessRight.value === 'ReceiverUser')
				|| (!this.billing && !this.postDrop && this.peppol && this.accessRight.value === 'SupplierSubAdmin')
				|| (!this.billing && !this.postDrop && this.peppol && this.accessRight.value === 'ReceiverAdmin')
				|| (!this.billing && !this.postDrop && this.peppol && this.accessRight.value === 'ReceiverUser')
		},
		langOptions() {
			let options = []
			let langs = this.$store.state.supplierGroup.data.languages
			langs.sort().forEach(lang => {
				let tempItem = {
					label: lang,
					value: lang
				}
				options.push(tempItem)
			})
			return options
		},
		language() {
			return this.$store.state.userManagement.userData.language.toLowerCase()
		},
		userData() {
			return this.$store.state.userManagement.userData;
		}
	},
	watch: {
		userData(newVal) {
			this.userId = newVal.id
			if (newVal.language) {
				this.selectedUserLang = {label: newVal.language, value: newVal.language}
			} else {
				this.selectedUserLang = {label: '', value: ''}
			}

			let tempLegalEntities = []
			if (newVal.legalEntities) {
				let legEntities = newVal.legalEntities
				legEntities.forEach(legEnt => {
					let temptItem = {
						add: [],
						remove: [],
						legalEntityId: legEnt.legalEntityId,
						receiversIds: legEnt.receiverIds
					}
					tempLegalEntities.push(temptItem)
				})
			} else {
				tempLegalEntities = [{
					add: [],
					remove: [],
					legalEntityId: '',
					receiversIds: []
				}]
			}
			this.legalEntities = tempLegalEntities
			if (newVal.group) {
				this.accessType = this.userGroups[newVal.group].accessType
				if (this.accessType === "OPTIPOST") {
					this.accessType = "NYMUS"
				}
				this.accessRight = {
					label: this.userGroups[newVal.group].accessRight,
					value: this.userGroups[newVal.group].name
				}

				//in case if Supplier is chosen as accessType
				if (userGroups.supplierNames.includes(this.userGroups[newVal.group].name)) {
					this.chosenAccessTypeSupplierGroup()
				}
				//in case if Optipost is chosen as accessType
				if (userGroups.optipostNames.includes(this.userGroups[newVal.group].name)) {
					this.chosenAccessTypeOptipost()
				}
				//in case if Receiver is chosen as accessType
				if (userGroups.receiverNames.includes(this.userGroups[newVal.group].name)) {
					this.chosenAccessTypeReceiver()
				}
			} else {
				this.accessType = "UNDEFINED"
				this.accessRights = [{
					label: "UNDEFINED",
					value: "UNDEFINED"
				}]
			}
			this.formData = {
				language: this.selectedUserLang,
				group: this.accessRight.value,
				legalEntities: this.legalEntities
			}
		}
	},

	methods: {
		chosenAccessTypeSupplierGroup() {
			if (this.$store.state.currentUserData.data.group === 'OpAdmin') {
				this.chosenAccessTypeOpAdmin()
			}
			if (this.$store.state.currentUserData.data.group === 'OpHelpdesk' || this.$store.state.currentUserData.data.group === 'SupplierGroupSuperAdmin') {
				this.chosenAccessTypeOpHelpDeskAndSupplierGroupSuperAdmin()
			}
			if (this.$store.state.currentUserData.data.group === 'SupplierSubAdmin') {
				this.accessRights = [
					{label: 'Can Not Be Created', value: ''}
				]
			}
		},
		chosenAccessTypeOpAdmin(){
			this.accessRights = []
			let supplierGroupIsG4s = this.chosenAccessTypeG4s()
			if (!supplierGroupIsG4s && this.$store.state.userManagement.userData.group === 'SupplierGroupSuperAdmin') {
				this.accessRights = [{
					label: this.userGroups["SupplierGroupSuperAdmin"].accessRight,
					value: this.userGroups["SupplierGroupSuperAdmin"].name
				}]
			} else {
				if ((this.billing && this.postDrop && this.peppol)
					|| (this.billing && this.postDrop && !this.peppol)
					|| (this.billing && !this.postDrop && this.peppol)
					|| (this.billing && !this.postDrop && !this.peppol)) {
					this.accessRights.unshift({
						label: this.userGroups["SupplierSubAdmin"].accessRight,
						value: this.userGroups["SupplierSubAdmin"].name
					})
				}
				if (this.$store.state.userManagement.userData.legalEntities && this.$store.state.userManagement.userData.legalEntities.length) {
					if (!(!this.billing && this.postDrop && !this.peppol)) {
						this.accessRights.push({
							label: this.userGroups["SupplierSubUser"].accessRight,
							value: this.userGroups["SupplierSubUser"].name
						})
					}
				} else if ((this.billing && this.postDrop && this.peppol)
					|| (this.billing && this.postDrop && !this.peppol)
					|| (!this.billing && this.postDrop && this.peppol)
					|| (!this.billing && this.postDrop && !this.peppol)) {
					this.accessRights.push({
						label: this.userGroups["SupplierSubUser"].accessRight,
						value: this.userGroups["SupplierSubUser"].name
					})
				}

				this.accessRights.push({
					label: this.userGroups["SupplierGroupSuperAdmin"].accessRight,
					value: this.userGroups["SupplierGroupSuperAdmin"].name
				})
			}
		},
		chosenAccessTypeG4s(){
			if ((this.$store.state.supplierGroup.data.supplierGroupId === 'g4sbelgium')
				|| (this.$store.state.supplierGroup.data.supplierGroupId === 'g4sluxemburg')) {
				if (this.$store.state.userManagement.userData.group === 'SupplierGroupSuperAdmin') {
					this.accessRights = [{
						label: this.userGroups["SupplierGroupSuperAdmin"].accessRight,
						value: this.userGroups["SupplierGroupSuperAdmin"].name
					}]
				}
				else {
					this.accessRights = []
					if ((this.billing && this.postDrop && this.peppol)
						|| (this.billing && this.postDrop && !this.peppol)
						|| (this.billing && !this.postDrop && this.peppol)
						|| (this.billing && !this.postDrop && !this.peppol)) {
						this.accessRights.push({
							label: this.userGroups["SupplierLimitedSubUser"].accessRight,
							value: this.userGroups["SupplierLimitedSubUser"].name
						})
					}

					if ((this.billing && this.postDrop && this.peppol)
						|| (this.billing && this.postDrop && !this.peppol)
						|| (this.billing && !this.postDrop && this.peppol)
						|| (this.billing && !this.postDrop && !this.peppol)) {
						this.accessRights.push({
							label: this.userGroups["SupplierSubAdmin"].accessRight,
							value: this.userGroups["SupplierSubAdmin"].name
						})
					}

					if (this.$store.state.userManagement.userData.legalEntities?.length) {
						if (!(!this.billing && this.postDrop && !this.peppol)) {
							this.accessRights.push({
								label: this.userGroups["SupplierSubUser"].accessRight,
								value: this.userGroups["SupplierSubUser"].name
							})
						}
					} else if ((this.billing && this.postDrop && this.peppol)
						|| (this.billing && this.postDrop && !this.peppol)
						|| (!this.billing && this.postDrop && this.peppol)
						|| (!this.billing && this.postDrop && !this.peppol)) {
						this.accessRights.push({
							label: this.userGroups["SupplierSubUser"].accessRight,
							value: this.userGroups["SupplierSubUser"].name
						})
					}

					this.accessRights.push({
						label: this.userGroups["SupplierGroupSuperAdmin"].accessRight,
						value: this.userGroups["SupplierGroupSuperAdmin"].name
					})
				}
				return true
			} //to exclude SupplierLimitedSubUser for SG: g4sBelgium or g4sLuxembourg
			return false
		},
		chosenAccessTypeOpHelpDeskAndSupplierGroupSuperAdmin(){
			let supplierGroupIsG4s = this.chosenAccessTypeG4s()
			if (!supplierGroupIsG4s && this.$store.state.userManagement.userData.group === 'SupplierGroupSuperAdmin') {
				this.accessRights = [{
					label: this.userGroups["SupplierGroupSuperAdmin"].accessRight,
					value: this.userGroups["SupplierGroupSuperAdmin"].name
				}]
			}
			else {
				this.accessRights = []
				if ((this.billing && this.postDrop && this.peppol)
					|| (this.billing && this.postDrop && !this.peppol)
					|| (this.billing && !this.postDrop && this.peppol)
					|| (this.billing && !this.postDrop && !this.peppol)) {
					this.accessRights.push({
						label: this.userGroups["SupplierSubAdmin"].accessRight,
						value: this.userGroups["SupplierSubAdmin"].name
					})
				}
				if (this.$store.state.userManagement.userData.legalEntities && this.$store.state.userManagement.userData.legalEntities.length) {
					if (!(!this.billing && this.postDrop && !this.peppol)) {
						this.accessRights.push({
							label: this.userGroups["SupplierSubUser"].accessRight,
							value: this.userGroups["SupplierSubUser"].name
						})
					}
				} else if ((this.billing && this.postDrop && this.peppol)
					|| (this.billing && this.postDrop && !this.peppol)
					|| (!this.billing && this.postDrop && this.peppol)
					|| (!this.billing && this.postDrop && !this.peppol)) {
					this.accessRights.push({
						label: this.userGroups["SupplierSubUser"].accessRight,
						value: this.userGroups["SupplierSubUser"].name
					})
				}

				this.accessRights.push({
					label: this.userGroups["SupplierGroupSuperAdmin"].accessRight,
					value: this.userGroups["SupplierGroupSuperAdmin"].name
				})
			}
		},
		chosenAccessTypeReceiver() {
			if (this.$store.state.currentUserData.data.group === 'OpAdmin'
				|| this.$store.state.currentUserData.data.group === 'OpHelpdesk'
				|| this.$store.state.currentUserData.data.group === 'SupplierGroupSuperAdmin'
				|| this.$store.state.currentUserData.data.group === 'SupplierSubAdmin'
				|| this.$store.state.currentUserData.data.group === 'SupplierSubUser'
				|| this.$store.state.currentUserData.data.group === 'SupplierLimitedSubUser') {

				if (this.$store.state.docType === "GENERAL" || this.$store.state.docType === "SALARY") {
					this.accessRights = []
					if ((this.billing && this.postDrop && this.peppol)
						|| (this.billing && this.postDrop && !this.peppol)
						|| (this.billing && !this.postDrop && this.peppol)
						|| (this.billing && !this.postDrop && !this.peppol)) {
						this.accessRights.push({
							label: this.userGroups["ReceiverUser"].accessRight,
							value: this.userGroups["ReceiverUser"].name
						})
					}
				} else {
					this.accessRights = []
					if ((this.billing && this.postDrop && this.peppol)
						|| (this.billing && this.postDrop && !this.peppol)
						|| (this.billing && !this.postDrop && this.peppol)
						|| (this.billing && !this.postDrop && !this.peppol)) {
						this.accessRights.push({
							label: this.userGroups["ReceiverAdmin"].accessRight,
							value: this.userGroups["ReceiverAdmin"].name
						})
						this.accessRights.push({
							label: this.userGroups["ReceiverUser"].accessRight,
							value: this.userGroups["ReceiverUser"].name
						})
					}
				}
			}

			if (this.$store.state.currentUserData.data.group === 'ReceiverAdmin') {
				this.accessRights = []
				if ((this.billing && this.postDrop && this.peppol)
					|| (this.billing && this.postDrop && !this.peppol)
					|| (this.billing && !this.postDrop && this.peppol)
					|| (this.billing && !this.postDrop && !this.peppol)) {
					this.accessRights.push({
						label: this.userGroups["ReceiverAdmin"].accessRight,
						value: this.userGroups["ReceiverAdmin"].name
					})
					this.accessRights.push({
						label: this.userGroups["ReceiverUser"].accessRight,
						value: this.userGroups["ReceiverUser"].name
					})
				}
			}
		},
		chosenAccessTypeOptipost() {
			if (this.$store.state.currentUserData.data.group === 'OpAdmin') {
				this.accessRights = [
					{label: this.userGroups["OpAdmin"].accessRight, value: this.userGroups["OpAdmin"].name},
					{
						label: this.userGroups["OpHelpdesk"].accessRight,
						value: this.userGroups["OpHelpdesk"].name
					}
				]
			}
		},

		setPortalData() {
			if (this.$store.state.supplierGroup.data.channelsConfig.docImage?.configPortal?.additionalPortalConfig?.generalPost) {
				this.postDrop = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
			}
			if (this.$store.state.supplierGroup.data.channelsConfig.docImage?.configPortal?.additionalPortalConfig?.billing) {
				this.billing = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.billing
			}
			if (this.$store.state.supplierGroup.data.channelsConfig.docImage?.configPortal?.additionalPortalConfig?.peppol) {
				this.peppol = this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.peppol
			}
		},
		closeEditUser() {
			this.selectedUserLang = {
				label: this.$store.state.userManagement.userData.language,
				value: this.$store.state.userManagement.userData.language
			}
			this.accessRight = {
				label: this.userGroups[this.$store.state.userManagement.userData.group].accessRight,
				value: this.userGroups[this.$store.state.userManagement.userData.group].name
			}
			this.$store.commit('displayEditUser', false)
		},
		updateUser() {
			this.formData.language = this.selectedUserLang.value
			this.formData.group = this.accessRight.value
			this.$store.dispatch('users/updateUser', {
				userData: this.formData,
				userId: this.userId
			})
			this.$emit('userUpdated')
			this.closeEditUser()
		}
	}
}
</script>

<style scoped>
.form-container {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: 50% 50%;
	grid-auto-flow: row;
	border-bottom: 1px solid var(--theme-primary-50);
	grid-column-gap: 0;
	grid-row-gap: 10px;
	padding-bottom: var(--generalPaddings);
}

.item-container {
	box-sizing: border-box;
}

.item-container:nth-child(even) {
	margin-left: 5px
}

.item-container:nth-child(odd) {
	margin-right: 5px
}

@media only screen and (max-width: 600px) {
	.form-container {
		grid-template-columns: 100%;
		grid-template-rows: repeat(4, 25%);
	}
}
</style>