<template>
	<div>
		<error-notification/>
		<div style="display: unset !important;">
			<div class="quick-search-container">
				<input
					type='text'
					v-model="filter.value"
					@keyup="onFilter"
					:placeholder="$t('placeholders.search')"
					class="form-control mr-1.5"
				/>
				<div class="flex flex-row margin-small-screen">
					<extended-search-filter
						current-page="failedTransactions"
						@search="onFilter"
						@reset-filter="resetExtendedSearch"
						:margin-left-small-screen="8"
						class="mr-1.5"
					/>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="cleanQuickSearch">
						{{ $t('buttons.reset') }}
					</button>
				</div>
			</div>
			<filter-label @remove-label="onFilter" current-page="failedTransactions"/>
			<div class="totalNumberDocuments">
				{{ $t('table.totalNumberFailedTransactions') }}: {{ totalDocuments }}
			</div>
			<div class="overflow-x-auto scrollbar-hidden">
				<div
					id="tabulator"
					ref="tableRef"
					class="mt-5 table-report table-report--tabulator"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref, watch} from 'vue'
import {TabulatorFull as Tabulator} from 'tabulator-tables'
import {useStore} from 'vuex'
import i18n from "../../../i18n"
import ErrorNotification from "../../UI/ErrorNotification"
import cash from "cash-dom"
import moment from "moment"
import axios from 'axios'
import qs from "qs"
import router from "../../../router"
import ExtendedSearchFilter from "../../extendedSearch/ExtendedSearchFilter.vue"
import FilterLabel from "../../extendedSearch/FilterLabel.vue"

const env = window.environment
export default defineComponent({
	components: {FilterLabel, ExtendedSearchFilter, ErrorNotification},
	emits: ['update:modelValue'],
	props: {
		token: String,
		supplierGroupId: String,
		requestUrl: String,
		modelValue: String,
	},
	setup(props) {
		const en = require('../../../locales/en.json')
		const nl = require('../../../locales/nl.json')
		const fr = require('../../../locales/fr.json')
		const de = require('../../../locales/de.json')
		const es = require('../../../locales/es.json')
		const sk = require('../../../locales/sk.json')
		const store = useStore()

		const requestUrl = ref(props.requestUrl)
		const supplierGroupId = ref(props.supplierGroupId)
		const totalDocuments = ref('')
		const tableIsLoading = ref(false)
		const searchTableValue = store.state.tableSearchValueMandates
		const token = ref(props.token)

		const tableRef = ref()
		const tabulator = ref()
		const filter = reactive({value: searchTableValue})
		const pageSize = ref(10)

		let tableItems = ref(0)

		watch(() => store.state.failedTransactionUpdated, (newVal) => {
			if (newVal) {
				setTimeout(() => {
					initTabulator()
					store.commit("failedTransactionUpdated", false)
				}, 1500)
			}
		})
		watch(() => props.token, (newVal) => {
			token.value = newVal
		})

		let tableColumns = computed(() => {
			return [
				{
					title: 'LEGAL ENTITY',
					field: 'legalEntityName',
					minWidth: 90,
					cssClass: 'text-start',
					headerSort: false,
					formatter(cell) {
						return `<div class="font-medium whitespace-nowrap">
                                   ${cell.getData().leName}
                              	</div>`
					}
				},
				{
					title: 'MANDATE ID',
					field: 'mandateId',
					cssClass: "text-centered",
					headerSort: true,
					minWidth: 100,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center common customer-name-outer">
                                     	<span id = ${cell.getData().mandateId}>${cell.getData().mandateId}</span>
                                       	<span class="tooltip view-mandate">${cell.getData().mandateIdTooltip}</span>
                                    </div>`
						)
						cash(a).on('click', function () {
							store.commit("setMandateTableSearch", cell.getData().mandateId)
							store.commit("setActivePaymentTab", "mandates")
						})
						return a[0]
					}
				},
				{
					title: 'RECEIVER NAME',
					field: 'receiverName',
					minWidth: 230,
					cssClass: "text-centered",
					hozAlign: "center",
					headerSort: true,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center common customer-name-outer">
                          				<span>${cell.getData().receiverName}</span>
                          				<span class="tooltip tooltip-receiver">${cell.getData().receiverId}</span>
                    				</div>`
						)
						return a[0]
					}
				},
				{
					title: 'DOC NUMBER',
					field: 'docNumber',
					cssClass: "text-centered",
					headerSort: false,
					minWidth: 190,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center customer-name-outer">
                                     	<span >${cell.getData().docNumber}</span>
                                      	<span class="tooltip view-document">${cell.getData().docNumberTooltip}</span>
                                	</div>`
						);
						cash(a).on('click', function () {
							router.push('/overview/' + cell.getData().docId)
						})
						return a[0]
					}
				},
				{
					title: 'TRANSACTION ID',
					field: 'transactionId',
					cssClass: "text-centered",
					headerSort: true,
					width: 110,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div >${cell.getData().transactionId}</div>
                                	</div>`
						)
						return a[0]
					}
				},
				{
					title: 'TRANSACTION DATE',
					field: 'transactionDate',
					cssClass: "text-centered",
					headerSort: true,
					width: 110,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div>${cell.getData().transactionDate}</div>
                                	</div>`
						)
						return a[0]
					}
				},
				{
					title: 'DUE DATE',
					field: 'dueDate',
					cssClass: "text-centered",
					headerSort: true,
					width: 120,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div>${cell.getData().dueDate}</div>
                                	</div>`
						)
						return a[0]
					}
				},
				{
					title: 'AMOUNT',
					field: 'amount',
					cssClass: "text-centered",
					headerSort: true,
					width: 80,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div >&#8364 ${cell.getData().transactionAmount}</div>
                                	</div>`
						)
						return a[0]
					}
				},
				{
					title: 'ERROR CODE',
					field: 'transactionErrorCode',
					cssClass: "text-centered",
					headerSort: true,
					width: 120,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div >${cell.getData().transactionError}</div>
                                	</div>`
						)
						return a[0]
					}
				},
				{
					title: 'MESSAGE',
					field: 'transactionMessage',
					cssClass: "text-centered",
					headerSort: true,
					minWidth: 100,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div>${cell.getData().transactionErrorMsg}</div>
                                	</div>`
						)
						return a[0]
					}
				},
				{
					title: 'MARK HANDLED',
					field: 'markHandled',
					cssClass: "text-centered color-grey",
					headerSort: false,
					width: 80,
					formatter(cell) {
						if (!cell.getData().handled) {
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                         	<div class="tooltip-outer">
                                            	<i class="icon-cog"></i>
                                                <span class="tooltip-inner mark-handled tooltip-ml-min40">${cell.getData().markHandledTooltip}</span>
                                            </div>
                                    	</div>`
							)
							cash(a).on('click', function () {
								store.dispatch('documents/markHandled', {transactionIds: [cell.getData().transactionId]})
							})
							return a[0]
						} else {
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                             <div class="tooltip-outer">
                                                 <i class="icon-checkmark icon-color-disable"></i>
                                                 <span class="tooltip-inner transaction-handled tooltip-ml-min30"> ${cell.getData().transactionHandledTooltip}</span>
                                            </div>
                                    	</div>`
							)
							return a[0]
						}
					}
				},
				{
					title: 'REOFFER',
					field: 'reoffer',
					cssClass: "text-centered color-grey",
					headerSort: false,
					width: 80,
					formatter(cell) {
						const a = cash(
							`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     	<div class="tooltip-outer">
                                        	<i class="icon-spinner11"></i>
                                            <span class="tooltip-inner reoffer ${cell.getData().leftMarginClass}">${cell.getData().reofferTooltip}</span>
                                        </div>
                                    </div> `
						)
						cash(a).on('click', function () {
							store.dispatch('reofferTransaction', {
								entityId: cell.getData().legalEntityId,
								transactionId: cell.getData().transactionId,
								mandateId: cell.getData().mandateId
							})
						})
						return a[0]
					}
				}
			]
		})

		const cleanQuickSearch = () => {
			store.state.extendedSearchValue.extendedSearch = {}
			store.commit("setTablePage", {field: "mandates", pageNo: 1})
			setInitialTableSorters()
			filter.value = ''
			onFilter()
		}
		const resetExtendedSearch = () => {
			setInitialTableSorters()
			store.commit("setTablePage", {field: "mandates", pageNo: 1})
			onFilter()
		}
		const setLocale = () => {
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
			})
		}
		const onFilter = () => {
			initTabulator()
		}

		const initTabulator = async () => {
			tabulator.value = new Tabulator(tableRef.value)
			let params = {
				supplierGroupId: supplierGroupId.value,
				pageNo: 1,
				pageSize: pageSize.value,
				state: "ERROR",
				final: true,
				quick: filter.value,
			}
			if (store.state.tableSorters[0].order) params.sortOn = store.state.tableSorters[0]
			if (filter.value === '') delete params.quick
			if (store.state.extendedSearchValue.extendedSearch.transactionId) {
				params.transactionId = store.state.extendedSearchValue.extendedSearch.transactionId.trim()
			}
			if (store.state.extendedSearchValue.extendedSearch.receiverId) {
				params.receiverId = store.state.extendedSearchValue.extendedSearch.receiverId.trim()
			}
			if (store.state.extendedSearchValue.extendedSearch.documentNumber) {
				params.docNumber = store.state.extendedSearchValue.extendedSearch.documentNumber.trim()
			}
			if (store.state.extendedSearchValue.extendedSearch.mandateId) {
				params.mandateId = store.state.extendedSearchValue.extendedSearch.mandateId.trim()
			}
			if (store.state.extendedSearchValue.extendedSearch.errorCode?.value) {
				params.errorCode = store.state.extendedSearchValue.extendedSearch.errorCode.value
			}
			if (store.state.extendedSearchValue.extendedSearch.handled?.value !== undefined && store.state.extendedSearchValue.extendedSearch.handled?.value !== '') {
				params.handled = store.state.extendedSearchValue.extendedSearch.handled.value
			}
			await store.dispatch("updateToken")
			tabulator.value = new Tabulator(tableRef.value, {
				ajaxURL: requestUrl.value,
				ajaxConfig: {
					method: "POST",
					headers: {
						'Authorization': 'bearer ' + store.state.token,
						'Accept': 'application/json',
					},
				},
				ajaxParams: params,
				ajaxResponse: function (url, params, response) {
					let data = []
					const res = response.data.items

					totalDocuments.value = response.data.numResults
					tableItems.value = response.data.items.length

					for (let item of res) {
						let tempItem = {
							mandateIdTooltip: i18n.t('tooltips.viewMandate'),
							docNumberTooltip: i18n.t('tooltips.viewDocument'),
							docId: item.docId,
							mandateId: item.mandateId,
							docDate: item.documentDate ? moment(item.documentDate).format('DD/MM/YYYY') : '-',
							dueDate: item.documentDueDate ? moment(item.documentDueDate).format('DD/MM/YYYY') : '-',
							transactionDate: item.transactionInfo.transactionDate ? moment(item.transactionInfo.transactionDate).format('DD/MM/YYYY') : '-',
							transactionError: item.transactionInfo.transactionError ? item.transactionInfo.transactionError : '-',
							docNumber: item.documentNumber,
							legalEntityId: item.legalEntityId,
							receiverId: item.receiverId,
							receiverName: item.receiverName,
							transactionAmount: '-',
							transactionErrorMsg: item.transactionInfo.transactionErrorMsg ? item.transactionInfo.transactionErrorMsg : '-',
							logo: 'no-logo',
							transactionId: item.transactionInfo.transactionId,
							leName: store.state.supplierGroup.data.supplierLegalEntities[item.legalEntityId].internalName ? store.state.supplierGroup.data.supplierLegalEntities[item.legalEntityId].internalName : store.state.supplierGroup.data.supplierLegalEntities[item.legalEntityId].name,
							reofferTooltip: i18n.t("tooltips.reoffer"),
							leftMarginClass: '',
							markHandledTooltip: i18n.t("tooltips.markHandled"),
							transactionHandledTooltip: i18n.t("tooltips.transactionHandled"),
							handled: item.handled ? item.handled : false
						}

						if (store.state.locale !== 'nl') tempItem.leftMarginClass = 'tooltip-ml-min40'
						else tempItem.leftMarginClass = 'tooltip-ml-min90'

						if (item.transactionInfo.transactionErrorMsg) {
							let msg = item.transactionInfo.transactionErrorMsg

							if (msg.length > 30) {
								let msgArray = msg.split(' ')
								let breakStrIndex = Math.floor(msgArray.length / 2)

								let errorStr1 = ''
								let errorStr2 = ''

								for (let i = 0; i <= breakStrIndex; i++) {
									errorStr1 = errorStr1 + msgArray[i] + " "
								}
								for (let i = breakStrIndex + 1; i <= msgArray.length - 1; i++) {
									errorStr2 = errorStr2 + msgArray[i] + ' '
								}
								msg = '<div class="error-split-massage"> <div>' + errorStr1 + "</div>" + '<div>' + errorStr2 + "</div></div>"
							}
							tempItem.transactionErrorMsg = msg
						}

						if (item.transactionInfo.transactionAmount) {
							let amount = item.transactionInfo.transactionAmount
							amount = amount.toString().replace(".", ',')
							let index = amount.search(',')
							if (index !== -1) {
								if ((index - 3) > 0) {
									amount = amount.slice(0, index - 3) + ' ' + amount.slice(index - 3)
								}
								if ((index - 6) > 0) {
									amount = amount.slice(0, index - 6) + ' ' + amount.slice(index - 6)
								}
							} else {
								let array = amount.split("")
								if ([array.length - 3] !== undefined) {
									array[array.length - 3] = " " + array[array.length - 3]
								}
								if ([array.length - 6] !== undefined) {
									array[array.length - 6] = " " + array[array.length - 6]
								}
								if ([array.length - 9] !== undefined) {
									array[array.length - 9] = " " + array[array.length - 9]
								}
								let amountString = ''
								array.forEach((item) => {
									amountString = amountString + item
								});
								amount = amountString
							}
							tempItem.transactionAmount = amount
						}
						if (store.state.supplierGroup.data.supplierLegalEntities[item.legalEntityId].legalEntityConfig?.logo) {
							tempItem.logo = store.state.supplierGroup.data.supplierLegalEntities[item.legalEntityId].legalEntityConfig.logo
						}
						data.push(tempItem)
					}
					let last_page = Math.ceil(response.data.numResults / pageSize.value)
					let formatedData = {
						last_page: last_page,
						data: data,
					}

					store.commit("setTablePage", {field: "failedTransactions", pageNo: tabulator.value.getPage()})
					return formatedData; //return the tableData property of a response json object
				},
				dataSendParams: {
					page: "pageNo",
					size: "page_size",
				},
				ajaxURLGenerator: function (url, config, params) {
					updateToken()
					if (params.sort?.length > 0) {
						let sortFields = {
							transactionDate: 'TRANSACTION_DATE',
							dueDate: 'TRANSACTION_DOCUMENT_DUE_DATE',
							amount: 'TRANSACTION_AMOUNT',
							mandateId: 'TRANSACTION_MANDATE_ID',
							receiverName: 'TRANSACTION_RECEIVER_NAME',
							transactionId: 'TRANSACTION_ID',
							transactionErrorCode: 'TRANSACTION_ERROR_CODE',
							transactionMessage: 'TRANSACTION_ERROR_MESSAGE',
						}
						store.commit('setTableSorters',
							{
								place: 'failedTransactions',
								order: params.sort[0].dir.toUpperCase(),
								sortField: sortFields[params.sort[0].field].toUpperCase()
							})

						params.sortOn = {
							order: params.sort[0].dir.toUpperCase(),
							sortField: sortFields[params.sort[0].field]
						}
					}
					return url
				},
				headerSortElement: "<span><i class='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
				ajaxContentType: "json",
				ajaxFiltering: true,
				printAsHtml: true,
				printStyled: true,
				pagination: true,
				sortMode: "remote",
				paginationMode: 'remote',
				paginationInitialPage: store.state.tableInitialPage.failedTransactions,
				paginationSize: pageSize.value,
				layout: 'fitColumns',
				responsiveLayout: 'collapse',
				placeholder: i18n.t('placeholders.noMatchingFound'),
				locale: true,
				langs: {
					"en": en,
					"nl": nl,
					"de": de,
					"fr": fr,
					"sk": sk,
					"es": es,
				},
				columns: tableColumns.value,
			})
			tabulator.value.on('dataLoading', () => {
				tableIsLoading.value = true
			})
			tabulator.value.on('dataLoaded', () => {
				tableIsLoading.value = false
			})
		}
		watch(tableIsLoading, () => {
			if (tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
		const tokenIsExpired = () => {
			let exp
			if (JSON.parse(atob(store.state.token.split('.')[1])).exp) {
				exp = parseInt(JSON.parse(atob(store.state.token.split('.')[1])).exp)
			}
			let now = parseInt(new Date().getTime() / 1000)
			return exp - now <= 0
		}
		const updateToken = () => {
			if (tokenIsExpired()) {
				let payload = {
					client_id: env.keycloakClient,
					grant_type: "refresh_token",
					refresh_token: store.state.refreshToken
				}
				let body = qs.stringify(payload)
				axios.post(store.state.API.keycloakToken, body, {
					headers: {
						'Content-Type': "application/x-www-form-urlencoded",
					}
				}).then(res => {
					store.commit('setToken', res.data.access_token)
					store.commit('setRefreshToken', res.data.refresh_token)
					initTabulator()

				}).catch(() => {
					store.commit("setGeneralNotificationText", {field: "sessionExpiredText", text: "Ok"})
					store.commit('displayNotification', true)
				})
			}
		}
		const setInitialTableSorters = () => {
			store.commit('setTableSorters', [{}])
		}

		onMounted(() => {
			store.commit("setTablePage", {field: "failedTransactions", pageNo: 1})
			setInitialTableSorters()
			initTabulator()
			setLocale()
		})

		return {
			tableRef,
			filter,
			onFilter,
			totalDocuments,
			tabulator,
			cleanQuickSearch,
			resetExtendedSearch,
			tableItems
		}
	}
})
</script>

<style scoped>
.quick-search-container {
	display: flex;
	flex-direction: row;
	width: 400px;
	height: 37px;
}

.leg-entity-container img {
	padding-right: var(--generalPaddings);
}

.leg-entity-container .table-report img {
	border: none;
}

.leg-entity-container .table-report img {
	box-shadow: none !important;
}

.totalNumberDocuments {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	justify-items: end;
	padding-top: var(--generalPaddings);
}

.arrow-up:after {
	content: ' \2191';
}

.arrow-down:after {
	content: ' \2193';
}

/* Hide all icons by default */
.tabulator-col .tabulator-col-sorter i {
	display: none;
}

/* Display the fa-sort icon when the column is not sorted */
.tabulator-col[aria-sort="none"] .tabulator-col-sorter i.no-sorting {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-up icon when the column is sorted in ascending order */
.tabulator-col[aria-sort="asc"] .tabulator-col-sorter i.arrow-up {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-down icon when the column is sorted in descending order */
.tabulator-col[aria-sort="desc"] .tabulator-col-sorter i.arrow-down {
	display: inline-block;
	color: var(--theme-primary-100);
}

.download-outer button {
	margin-left: 5px;
}

.tooltip {
	display: none;
	position: relative;
	top: 20px;
	background-color: var(--theme-primary-500);
	color: white;
	border-radius: 5px;
	font-size: 12px;
}

.extended-search-check-box span {
	margin-right: 10px;
	position: relative;
	top: -5px;
}

@media only screen and (max-width: 1030px) {
	.quick-search-container {
		display: flex;
		flex-direction: row;
		width: 400px;
		height: 37px
	}
}

@media only screen and (max-width: 739px) {
	.quick-search-container {
		display: flex;
		flex-direction: column;
		width: 400px;
		height: 37px
	}

	.margin-small-screen {
		margin-top: 5px;
	}
}
</style>