<template>
	<pop-up-window
		:show="this.$store.state.errorNotification.display"
		:showClose=false
		@close="closeError"
		class="doc-statuses-popup position"
		:positionFixed="true"
		:top0="true"
		:bottomDiv="false"
	>
		<template #title>
			<h1 class="text-2xl">{{ titleText }}</h1>
		</template>
		<template #content>
			<div class="user-info">
				<h2 class="text-popup-header text-center">
					<i class="icon-notification" :class="{'error' : error, 'warning' : warning, 'info' : info}"></i>
					{{ errorText }}
				</h2>
			</div>
			<div class="grid grid-rows-1 justify-items-center my-6">
				<div>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="closeError">
						{{ $t('buttons.close') }}
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "./PopUpWindow.vue"
import i18n from '../../i18n'

export default {
	name: "ErrorNotification",
	components: {PopUpWindow},

	data() {
		return {
			reportError: false,
			i18n: i18n,
			error: true,
			warning: false,
			info: false,
		}
	},
	computed: {
		titleText() {
			if (this.$store.state.errorNotification.type === "MandateNoPdf") {
				return i18n.t('general.notification')
			}
			return i18n.t('errors.errorOccurred')
		},
		errorText() {
			if (this.$store.state.errorNotification.type === "INVITE" && this.$store.state.errorNotification.errorStatus === 409) {
				this.isWarning()
				if (this.$store.state.docType === "SALARY") {
					if (this.$store.state.errorNotification.errorText === 'warning-ra-user-extended') {
						return i18n.t('errors.warningRaUserExtendedSalary')
					}
					if (this.$store.state.errorNotification.errorText === 'warning-ssa-user-extended') {
						return i18n.t('errors.warningSsaUserExtendedSalary')
					}
					if (this.$store.state.errorNotification.errorText === 'error-ssa-no-creation-allowed') {
						return i18n.t('errors.errorSsaNoCreationAllowedSalary')
					}
					if (this.$store.state.errorNotification.errorText === 'error-no-creation-allowed') {
						return i18n.t('errors.errorNoCreationAllowedSalary')
					}
				} else {
					if (this.$store.state.errorNotification.errorText === 'warning-ra-user-extended') {
						return i18n.t('errors.warningRaUserExtended')
					}
					if (this.$store.state.errorNotification.errorText === 'warning-ssa-user-extended') {
						return i18n.t('errors.warningSsaUserExtended')
					}
					if (this.$store.state.errorNotification.errorText === 'error-ssa-no-creation-allowed') {
						return i18n.t('errors.errorSsaNoCreationAllowed')
					}
					if (this.$store.state.errorNotification.errorText === 'error-no-creation-allowed') {
						return i18n.t('errors.errorNoCreationAllowed')
					}
				}
				return i18n.t('errors.inviteErrorText')
			}
			if (this.$store.state.errorNotification.type === "RESENDEMAIL") {
				return i18n.t('errors.resendEmailErrorText')
			}
			if (this.$store.state.errorNotification.type === "DELETEDOCUMENT") {
				return i18n.t('errors.deleteDocumentErrorText')
			}
			if (this.$store.state.errorNotification.type === "MANAGEMENTRCEIVEREXISTS") {
				return i18n.t('errors.receiverExists').replace("INSERT_RECEIVERID", this.$store.state.receiverManagement.createReceiverId)
			}
			if (this.$store.state.errorNotification.type === "REPORT"
				&& this.$store.state.errorNotification.errorStatus === 500
				&& this.$store.state.errorNotification.errorText === "Request failed with status code 500") {
				this.isWarning();
				return i18n.t('errors.reportDownloading')
			}
			if (this.$store.state.errorNotification.type === "MandateNoPdf") {
				this.setIconColors();
				return i18n.t('management.mandates.noMandatePdf')
			}
			return i18n.t('errors.errorOccurred')
		}
	},
	methods: {
		setIconColors() {
			this.error = false
			this.info = true
			this.warning = false
		},
		isWarning() {
			if (this.$store.state.errorNotification.errorText.includes('warning')
				|| (this.$store.state.errorNotification.type === "REPORT"
					&& this.$store.state.errorNotification.errorStatus === 500
					&& this.$store.state.errorNotification.errorText === "Request failed with status code 500")) {
				this.error = false
				this.info = false
				this.warning = true;
			}
		},
		closeError() {
			this.$store.commit('clearErrors')
		}
	}
}
</script>

<style scoped>
.error {
	color: var(--errorColor);
}

.warning {
	color: var(--warningColor);
}
</style>
