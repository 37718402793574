<template>
	<pop-up-window
		:show="this.$store.state.displayLoader"
		:showClose=false
		class="doc-statuses-popup"
		additional-info=""
		:positionFixed="true"
		:top0="true"
		:bottomDiv="false"
	>
		<template #title>
			<h1 class="text-2xl">{{ $t('general.loaderTitle') }}</h1>
		</template>
		<template #content>
			<the-spinner/>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "./PopUpWindow"
import TheSpinner from "./TheSpinner"

export default {
	components: {TheSpinner, PopUpWindow},
}
</script>