<template>
	<div>
		<div>
			<h1>{{ $t('reportSettings.reportReceivers') }}</h1>
			<div class="existing-contacts">
				<div v-for="(contact, index) in inputExistingContacts" :key="contact.id" style="margin-bottom: 10px;">
					<i class="icon icon-user1 "></i>
					{{ contact.email }} - {{ contact.language }}
					<i class="icon icon-bin" @click="removeContact(index)"></i>
				</div>
			</div>
		</div>

		<div v-if="showInPutMail">
			<h1>{{ $t('reportSettings.setNewReceivers') }}</h1>
			<button class="button-styling button-styling-plus" @click="AddNewMail">
				<span class="material-icons">add</span>
			</button>

			<div v-for="(contact, index) in newContacts" :key="index">
				<div class="add-new-emails">
					<input
						id='email'
						type='email'
						class="form-control text-popup"
						v-model="contact.email"
						style="width: 45%; margin-right: 10px"
						:placeholder="$t('placeholders.email')"
					/>
					<v-select
						style="width: 45%"
						:options="languages"
						v-model="contact.language"
						:placeholder="$t('documentDetail.resend.resendSelectLang')"
					/>
					<button
						class="button-styling button-styling-min"
						v-if="newContacts.length > 1"
						@click="deleteNewMail(index)"
					>
						<span class="material-icons center-icon">minimize</span>
					</button>
				</div>
				<p v-if="errorGivenInput[index]" class="error" style="margin-bottom: 5px">
					{{ $t('errors.provideCorrectData') }}
				</p>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props: {
		inputExistingContacts: Array,
		errorGivenInput: Array,
		languages: {
			type: Array,
			required: true
		},
		showInPutMail: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	emits: ['removeContact'],
	data() {
		return {
			newContacts: [{
				email: '',
				language: ''
			}],
			existingContacts: this.inputExistingContacts
		}
	},
	methods: {
		AddNewMail() {
			this.newContacts.push({
				email: '',
				language: ''
			})
		},
		removeContact(index) {
			this.$emit('removeContact', index)
		},
		deleteNewMail(index) {
			this.newContacts.splice(index, 1)
		}
	},
}
</script>

<style scoped>
h1 {
	font-weight: bolder;
	font-size: 14px;
	margin-bottom: calc(0.5 * var(--generalPaddings));
}

.add-new-emails {
	display: flex;
	flex-direction: row;
	margin-bottom: 15px;
}

.button-styling {
	background-color: #555555;
	color: white;
}

.button-styling-plus {
	padding-top: 2px;
	width: 50px;
	height: 30px;
	border-radius: 10%;
	margin-bottom: 10px;
}

.button-styling-min {
	padding-top: -5px;
	margin-left: 5px;
	margin-top: 10px;
	border-radius: 20%;
	left: 300px;
	bottom: 5px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 10%;
}

.center-icon {
	margin-top: -7px;
	margin-bottom: 7px;
}

.existing-contacts {
	display: flex;
	flex-direction: column;
	flex-wrap: wrap;
}
</style>