<template>
	<transition name="dialog">
		<div>
			<page-container class="page-content" v-if="this.$store.state.emailSuppression.isReady">
				<tabulator
					:supplierGroupId="this.$store.state.supplierGroup.data.supplierGroupId"
					:requestUrl="this.requestUrl"
				/>
			</page-container>
		</div>
	</transition>
</template>

<script>
import PageContainer from "../../PageContainer"
import Tabulator from './MailsTable'

export default {
	name: "ManageMailsContent",
	components: {PageContainer, Tabulator},
	data() {
		return {
			requestUrl: this.$store.state.domainName + this.$store.state.API.retrieveSuppressions,
		}
	},
	mounted() {
		this.$store.dispatch("updateToken")
	},
	created() {
		this.retrieveSuppressionEmails()
	},
	methods: {
		retrieveSuppressionEmails() {
			let email = this.$store.state.emailSuppression.search
			if (!this.$store.state.emailSuppression.search) {
				email = null;
			}
			this.$store.dispatch('retrieveSuppressionEmails', {
				formData: {
					email: email,
					from: null,
					to: null,
					reason: null
				}
			})
		}
	},
	unmounted() {
		this.$store.commit('setEmailSuppressionsSearch', '')
		this.$store.commit('setEmailSuppressionsStatus', false)
		this.$store.commit('setEmailSuppressions', [])
		this.$store.commit("emailReactivated", false)
		this.$store.commit('setManagementActiveTab', 'users')
	}
}
</script>