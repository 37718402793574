export default function getAmount(item) {
	let currency = ''
	let amount = ''

	if (item.billingData?.documentAmounts) {
		amount = item.billingData.documentAmounts.mandatoryAmounts.totalAmountDue
		currency = item.billingData.documentAmounts.currency
	} else if (item.salaryData) {
		amount = item.salaryData.amount
		currency = item.salaryData.currency
	} else return ''

	if (item.documentType === 'CREDIT_NOTE') {
		amount = Math.abs(Number(amount))
	}

	if (currency === 'EUR') {
		currency = '&#8364'
	} else if (currency === 'USD') {
		currency = '&#36'
	} else if (currency === 'GBP') {
		currency = '&#65505'
	}

	return currency + ' ' + amount.toLocaleString('nl-BE', {timeZone: "UTC"}).replaceAll('.', ' ')
}