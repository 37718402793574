<template>
	<div class="topBanner box  text-3xl" :style="{'background-color': brandSettings.mainColor}">
		<slot></slot>
	</div>
</template>
<script>
export default {
	name: "TopBanner",
	props: ['text'],
	computed: {
		brandSettings() {
			return this.$store.state.supplierGroup.branding
		}
	},
	data() {
		return {
			bannerData: this.$store.getters.getBannerData,
			primaryColor: this.$store.getters.getPrimaryColor,
		}
	}
}
</script>

<style scoped>

.topBanner {
	color: var(--secondaryFontColor);
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-auto-flow: column;
	align-items: center;
	justify-items: center;
	padding: calc(1 * var(--generalPaddings) / 3);
	text-align: center;
}

@media only screen and (max-width: 520px) {
	.topBanner {
		padding: calc(0.5 * var(--generalPaddings));
	}
}

</style>