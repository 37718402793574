<template>
	<div class="menu-container" @mouseleave="mouseLeaveToggle">
		<div class="tooltip-button">
			<button @mouseenter="toggleMenu" :disabled="isDisabled" class="btn custom-button shadow-md no-break-text"
					style="height: 37px">
				{{ mainButtonName }} <span v-if="showArrow" class="material-icons" style="margin-left: 5px">keyboard_arrow_down</span>
			</button>
			<span v-if="tooltip !== null" class="tooltipMenu"
				  :style="{visibility: visible, width: widthTooltip + 'px'}">{{ tooltip }}</span>
		</div>

		<div class="menu-button-container" :style="{width: widthPx+'px'}" v-if="isOpen" @click="close">
			<slot name="options"></slot>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		mainButtonName: {
			type: String,
			required: false
		},
		widthPx: {
			type: Number,
			required: false,
		},
		isDisabled: {
			type: Boolean,
			required: false,
			default: false
		},
		tooltip: {
			type: String,
			required: false,
			default: null
		},
		widthTooltip: {
			type: Number,
			required: false,
			default: 200
		},
		showArrow: {
			type: Boolean,
			required: false,
			default: true
		}
	},
	data() {
		return {
			isOpen: false,
			visible: 'hidden'
		}
	},
	methods: {
		toggleMenu() {
			if (this.isDisabled === false) this.isOpen = !this.isOpen
			else this.visible = 'visible'
		},
		close(){
			this.isOpen = false
		},
		mouseLeaveToggle() {
			this.isOpen = false
			this.visible = 'hidden'
		}
	}
}
</script>

<style scoped>
.menu-container {
	position: relative;
}

.tooltip-button {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.menu-button-container {
	position: absolute;
	z-index: 100;
	box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
	background-color: white;
	transition: 0.3s;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	text-align: left;
}

.menu-button-container >>> button {
	text-align: left;
	padding: 5px;
}

.tooltipMenu {
	position: absolute;
	margin-top: 30px;
	padding: 1px;
	background-color: var(--theme-primary-500);
	color: white;
	border-radius: 5px;
	text-align: center;
	opacity: 0.7;
}

</style>