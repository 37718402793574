<template>
	<transition name="dialog">
		<div class="page-container">
			<div class="banner" :style="{'background-image': bannerUrl, 'background-color' : bgColor}"/>
			<div class="login-container-outer">
				<div class="login-container">
					<div class="input-container-outer" v-if="showLogin">
						<div class="input-container">
							<div class="input-header">
								<div
									:style="{'border-bottom-color' : this.$store.state.supplierGroup.branding.secondColor}">
									{{ $t('general.signIn') }}
								</div>
							</div>

							<input
								id='email'
								type='email'
								@click="clearLoginErrors"
								v-model="formData.email"
								class="form-control text-popup input"
								:placeholder="$t('placeholders.email')"
							/>
							<input
								id='password'
								type='password'
								@click="clearLoginErrors"
								v-model="formData.password"
								class="form-control text-popup input"
								:placeholder="$t('placeholders.password')"
							/>

						</div>
						<div class="button-container">
							<div>
								<p v-if="errors.login" class="error">{{ $t('errors.correctEmail') }}</p>
								<p v-if="this.$store.state.errors.loginCredentials" class="error">
									{{ $t('errors.invalidCredentials') }}
								</p>
							</div>
						</div>
						<div class="button-container">
							<div>
								<button
									class="btn custom-button shadow-md mr-2 showStatuses text-popup"
									:style="{'background-color' : this.$store.state.supplierGroup.branding.mainColor}"
									@click="login"
								>
									Login via Nymus
								</button>
								<button class="mt-2" @click="displayResetPassword">
									{{ $t('buttons.forgotPassword') }}
								</button>
							</div>
						</div>
					</div>
					<div v-if="!showLogin">
						<div class="input-container">
							<div class="input-header">
								<div
									:style="{'border-bottom-color' : this.$store.state.supplierGroup.branding.secondColor}">
									{{ $t('general.forgotPassword') }}
								</div>
							</div>
							<input
								@click="setErrors('resetPassword', false)"
								type='email'
								v-model="forgotEmail"
								class="form-control text-popup input"
								:placeholder="$t('placeholders.email')"
							/>
						</div>
						<div class="button-container">
							<div>
								<p v-if="errors.resetPassword" class="error">{{ $t('errors.correctEmail') }}</p>
								<p v-if="this.$store.state.passwordResetSuccessDisplay" class="success">
									{{ $t('passwordResetSuccessfully') }}
								</p>
							</div>
						</div>
						<div class="button-container">
							<div>
								<button
									@click="resetPassword"
									class="btn custom-button shadow-md mr-2 showStatuses text-popup"
									:style="{'background-color' : this.$store.state.supplierGroup.branding.mainColor}"
								>
									{{ $t('buttons.resetPassword') }}
								</button>

								<button @click="displayLogin">
									{{ $t('buttons.backToLogin') }}
								</button>
							</div>
						</div>
					</div>
					<div class="login-texts">
						<div class="header-outer">
							<h1 class="header"
								:style="{'border-bottom-color' : this.$store.state.supplierGroup.branding.secondColor}"
							>
								{{ header }}
							</h1>
						</div>

						<div class="description">
							<h2 class="description-header">
								{{ descriptionHeader }}
							</h2>
							<div v-if='descriptionItems.length'>
								<div v-for="(item, index) in descriptionItems" :key=index>
									<h3>- {{ item }}</h3>
								</div>
							</div>

							<div v-if="this.descriptionItemsFooter.length">
								<div v-for="(item, index) in this.descriptionItemsFooter" :key=index>
									<h3 class="description-footer">{{ item }}</h3>
								</div>
							</div>
							<div v-if="this.$store.state.supplierGroup.branding.contactEmail">
								<h1 class="contactEmail">{{ $t('loginPage.contactEmail') }}
									<a :href="href">
										{{ this.$store.state.supplierGroup.branding.contactEmail }}
									</a>
								</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import i18n from "../i18n"
import textEN from "../locales/en.json"
import textNL from "../locales/nl.json"
import textFR from "../locales/fr.json"
import textDE from "../locales/de.json"

export default {
	data() {
		return {
			i18n: i18n,
			formData: {
				email: '',
				password: '',
				client_id: this.$store.state.keycloakClientId,
				grant_type: "password",

			},
			forgotEmail: '',
			showLogin: true,
			errors: {
				resetPassword: false,
				login: false

			},
			header: "",
			descriptionHeader: "",
			descriptionItems: [],
			descriptionItemsFooter: [],
			descriptionItemsFull: {
				en: textEN.loginPage.description.items,
				fr: textFR.loginPage.description.items,
				nl: textNL.loginPage.description.items,
				de: textDE.loginPage.description.items,

			},
			descriptionFooterFull: {
				en: textEN.loginPage.footer.items,
				fr: textFR.loginPage.footer.items,
				nl: textNL.loginPage.footer.items,
				de: textDE.loginPage.footer.items,

			},
		}
	},
	mounted() {
		this.setPasswordReset(false)
		this.checkLang()
		this.setTexts()
	},
	beforeUnmount() {
		this.setPasswordReset(false)
	},
	computed: {
		href() {
			let href = ''
			if (this.$store.state.supplierGroup.branding.contactEmail) {
				href = "mailto:" + this.$store.state.supplierGroup.branding.contactEmail
			}
			return href
		},
		locale() {
			return this.$store.state.locale
		},
		bannerUrl() {
			let url = ''
			if (this.$store.state.supplierGroup.branding.bannerUrl) {
				url = 'url("' + this.$store.state.supplierGroup.branding.bannerUrl + '")'
			}
			return url
		},
		bgColor() {
			let color = ''
			if (this.$store.state.supplierGroup.branding.mainColor) {
				color = this.$store.state.supplierGroup.branding.mainColor
			}
			return color
		}
	},
	watch: {
		locale() {
			this.setTexts()
		}
	},
	methods: {
		setPasswordReset(value) {
			this.$store.commit('setPasswordResetDisplay', value)
		},
		checkLang() {
			if (localStorage.getItem("language")) {
				this.$store.state.locale = localStorage.getItem("language")
			}
		},
		setTexts() {
			if (this.$store.state.supplierGroup.data.sgType) {
				let sgType = this.$store.state.supplierGroup.data.sgType.toLowerCase()

				let headerKey = 'loginPage.header.' + sgType
				this.header = i18n.t(headerKey).replace("INSERT_SG", this.$store.state.supplierGroup.data.name)

				let descriptionHeaderKey = 'loginPage.description.header.' + sgType
				this.descriptionHeader = i18n.t(descriptionHeaderKey).replace("INSERT_SG", this.$store.state.supplierGroup.data.name)


				let descriptionTexts = this.descriptionItemsFull[this.$store.state.locale][sgType]
				this.descriptionItems = []
				for (let item in descriptionTexts) {
					if (descriptionTexts[item] !== '') {
						this.descriptionItems.push(descriptionTexts[item])
					}
				}

				let descriptionFooterTexts = this.descriptionFooterFull[this.$store.state.locale][sgType]
				this.descriptionItemsFooter = []

				for (let item in descriptionFooterTexts) {
					if (descriptionFooterTexts[item] !== '') {
						this.descriptionItemsFooter.push(descriptionFooterTexts[item])
					}
				}
			}

		},
		validateEmail(email) {
			const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
			return re.test(email)
		},
		resetPassword() {
			let email = this.forgotEmail.trim()
			if (this.validateEmail(email)) {
				this.$store.dispatch('resetPasswordOnLogin', {
					formData: {
						language: this.$store.state.locale.toUpperCase(),
						userEmail: email,
					}
				})
			} else {
				this.setErrors('resetPassword', true)
			}
		},
		clearLoginErrors() {
			this.setErrors('login', false)
			this.$store.commit('setErrors', {field: 'loginCredentials', value: false})
		},
		setErrors(field, value) {
			this.errors[field] = value
		},
		displayLogin() {
			this.setErrors('resetPassword', false)
			this.setPasswordReset(false)
			this.showLogin = true
		},
		displayResetPassword() {
			this.clearLoginErrors()
			this.showLogin = false
		},
		login() {
			let email = this.formData.email.trim()

			if (this.validateEmail(email)) {
				this.formData.username = email
				this.formData.email = email
				this.$store.dispatch("login", this.formData)
			} else {
				this.setErrors('login', true)
			}
		},
	}
}
</script>

<style scoped>
.page-container {
	min-height: calc(100vh - var(--headerheight) - 100px);
}

.banner {
	height: 350px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100vw;
	position: relative;
	margin-left: -2rem;
}

.login-container-outer {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	align-items: center;
	height: 100%;
	margin-top: 40px;
}

.login-container {
	margin-top: 40px;
	display: grid;
	grid-template-columns: 40% 60%;
	grid-template-rows: 100%;
}

.login-texts {
	margin-left: 40px;
	margin-right: 20%;
}

.input, .input-header {
	margin-bottom: 10px;
	width: 350px
}

.input-container, .button-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: auto auto;
	grid-auto-flow: row;
	justify-items: end;
}

.header-outer {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: start;
}

.header-outer .header {
	border-bottom-width: 2px;
	border-bottom-style: solid;
}

.button-container > div {
	width: 350px;
}

.input-header {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	grid-auto-flow: row;
	justify-items: start;
}

.input-header > div {
	font-size: 16px;
	font-weight: bolder;
	border-bottom-width: 2px;
	border-bottom-style: solid;
}

.button-container p {
	margin-bottom: 4px;
}

.header {
	font-size: 28px;
	font-weight: bold;
	line-height: 1.5em;
}

h2 {
	margin-top: 30px;
	font-size: 18px;
	line-height: 1.5em;
}

h3 {
	margin-top: 10px;
	font-size: 16px;
	margin-left: 20px;
}

.description-footer {
	line-height: 1.5em;
	margin-top: 30px;
	margin-left: 0;
}

@media only screen and (max-width: 1450px) and (min-width: 1350px) {
	.banner {
		height: 300px;
	}
}

@media only screen and (max-width: 1349px) and (min-width: 1250px) {
	.banner {
		height: 280px;
	}
}

@media only screen and (max-width: 1249px) and (min-width: 950px) {
	.banner {
		height: 250px;
	}
}

@media only screen and (max-width: 949px) and (min-width: 650px) {
	.banner {
		height: 200px;
	}
}

@media only screen and (max-width: 639px) and (min-width: 0px) {
	.banner {
		height: 180px;
		margin-left: -1rem;
	}

	.login-container-outer {
		margin-top: 0;
	}
}

@media only screen and (max-width: 750px) and (min-width: 0px) {
	.banner {
		margin-top: -40px;
	}
}

@media only screen and (max-width: 900px) and (min-width: 0px) {
	.login-container {
		grid-template-columns: 100%;
		grid-template-rows: auto auto;
	}

	.input-container-outer {
		grid-column: 1/2;
		grid-row: 2/3;
		width: 350px;
		margin: 10px auto;
	}

	.login-texts {
		grid-column: 1/2;
		grid-row: 1/2;
		margin-left: 0;
		margin-right: 0;
	}

	.header {
		font-size: 24px;
		text-align: center;
	}

	h2 {
		font-size: 18px;
	}

	h3 {
		font-size: 14px;
	}

	.description-footer {
		margin-top: 20px;
	}
}

@media only screen and (max-width: 420px) and (min-width: 0px) {
	.input, .input-header {
		width: 290px;
	}

	.input-container {
		justify-items: start;
	}
}

@media only screen and (max-width: 390px) and (min-width: 0px) {
	.input {
		width: 250px;
	}
}

@media only screen and (max-width: 450px) and (min-width: 0px) {
	.header {
		font-size: 20px;
	}

	h2, .contactEmail {
		font-size: 16px;
		margin-top: 20px;
	}

	h3 {
		font-size: 12px;
	}
}

.success {
	color: var(--successeColor);
}

.contactEmail {
	margin-top: 10px;
	font-size: 16px;
}

.contactEmail a:hover {
	color: var(--infoColor);
}
</style>