import axios from "axios"
import router from "@/router"

export default {
	async mandateCancel(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.mandateCancel.replace(':entityId', payload.entityId).replace(':clientId', payload.clientId).replace(':mandateId', payload.mandateId)
		await axios.post(url, payload, {
				headers: {
					'Authorization': context.rootState.token,
				}
			}
		).then(resp => {
			if (resp.data.data.code === "404") {
				context.commit("setGeneralNotificationText", {field: "couldNotCancelMandate", text: "Ok"}, {root: true})
				context.commit('displayNotification', true, {root: true})
				context.commit('setMandateCanceled', true, {root: true})
			} else {
				context.commit('setMandateCanceled', true, {root: true})
				context.commit('setDeleteMandateText', 'Ok', {root: true})
				context.commit("setGeneralNotificationText", {field: "deleteMandateText", text: "Ok"}, {root: true})
				context.commit('displayNotification', true, {root: true})
			}
		}).catch(() => {
			context.commit("setGeneralNotificationText", {field: "generalErrorText", text: "Ok"}, {root: true})
			context.commit('displayNotification', true, {root: true})
		})
	},
	async editMandate(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.editMandate.replace(":entityId", payload.entityId).replace(":clientId", payload.clientId).replace(":mandateId", payload.mandateId)

		return await axios.post(url, payload.requestData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(async () => {
				await context.commit("setGeneralNotificationText", {field: "editMandateText", text: "Ok"}, {root: true})
				await context.commit('displayNotification', true, {root: true})
			}
		)
			.catch(() => {
				context.commit('displayEditMandate', false, {root: true})
				context.commit("setGeneralNotificationText", {field: "generalErrorText", text: "Ok"}, {root: true})
				context.commit('displayNotification', true, {root: true})
			})
	},
	downloadMandate(context, payload) {
		let url = url = context.rootState.domainName + context.rootState.API.downloadMandate.replace(":legalEntityId", payload.legalEntityIds[0]).replace(":mandateId", payload.mandateId)

		return axios.get(url, {
			responseType: 'blob',
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		})
			.then(res => {
				let url = window.URL.createObjectURL(new Blob([res.data]))
				const link = document.createElement('a')
				link.href = url
				link.setAttribute('download', payload.mandateId + '.pdf')
				document.body.appendChild(link)
				link.click()
			})
			.catch((error) => {
				if (error.response.status === 404) {
					context.commit('setErrorNotificationType', "MandateNoPdf", {root: true})
				}
				context.commit('displayErrorNotification', true)
			})
	},
	checkMandateOnboardingData(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.mandateOnboarding

		return axios.post(url, payload)
			.then((res) => {
				if (res.data.data.numResults > 0) {
					context.commit('accessCodeReplyLength', 1, {root: true})
				} else {
					context.commit('accessCodeReplyLength', 0, {root: true})
				}
			})
			.catch((err) => console.error(err))
	},
	mandateCancelPublic(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.mandateCancelPublic.replace(':entityId', payload.entityId).replace(':clientId', payload.clientId).replace(':mandateId', payload.mandateId)
		return axios.post(url, {reason: payload.reason}, {
				headers: {
					'Content-Type': "application/json"
				}
			}
		).then(() => router.push('/cancelMandateSuccessful'))
			.catch(() => {
				router.push('/cancelMandateFail')
			})
	},
	mandateCreate(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.mandateCreate.replace(':entityId', payload.legalEntityId).replace(':clientId', payload.clientId)
		return axios.post(url, payload.data)
			.then((res) => {
				if (res.data.data.url !== '') {
					window.location.replace(res.data.data.url)
				} else {
					context.commit("setGeneralNotificationText", {field: "CouldNotCreateMandate", text: "Ok"}, {root: true})
					context.commit('displayNotification', true, {root: true})
				}
			}).catch((err) => {
				console.error(err)
				context.rootState.errorNotification.display = true
			})
	},
	getMandateData(context, payload) {
		let apiSecondPart = context.rootState.API.getMandateOnboarding
		apiSecondPart = apiSecondPart.replace(':accessCode', payload.mandateOnboardingAccessCode)
		let url = context.rootState.domainName + apiSecondPart
		return axios.post(url, payload.requestData)
			.then((response) => {
				context.commit('setUserOnboardingMandates', response.data.data.items, {root: true})
			})
			.catch(() => router.push('/notFound'))
	},
	mandateSaveChoice(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.mandateSaveChoice
		return axios.post(url, payload)
			.then((res) => {
				if (res.data.data.numResults > 0) {
					context.commit('setOnboardingSuccessfulData', res.data.data.items[0], {root: true})
					router.push('/onboardingSuccessful')

				} else router.push('/onboardingUnsuccessful')
			})
			.catch((err) => {
				console.error(err)
				context.rootState.errorNotification.display = true
			})
	},
	checkMandate(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.checkMandate.replace(":documentId", payload)

		return axios.get(url)
			.then(res => context.commit('setMandateExists', res.data.data, {root: true}))
			.catch((err) => console.error(err))
	},
	reofferTransaction(context, payload) {
		let mandateUrl = context.rootState.domainName + context.rootState.API.mandates

		let mandatePayload = {
			supplierGroupId: payload.entityId,
			pageNo: 1,
			pageSize: 1,
			quick: payload.mandateId,
		}

		return axios.post(mandateUrl, mandatePayload, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then((res) => {
			if (res.data.data.items[0].status === "CANCELLED") {
				context.commit("setGeneralNotificationText", {
					field: "reofferTransactionMandateCancelled",
					text: 'OK'
				}, {root: true})
				context.commit('displayNotification', true, {root: true})
			} else {
				let url = context.rootState.domainName + context.rootState.API.reofferTransaction.replace(':entityId', payload.entityId).replace(':transactionId', payload.transactionId)

				return axios.post(url, {}, {
						headers: {
							'Authorization': "bearer " + context.rootState.token,
						}
					}
				).then(() => {
					context.commit("setGeneralNotificationText", {
						field: "reofferTransaction",
						text: 'OK'
					}, {root: true})
					context.commit('displayNotification', true, {root: true})
				})
					.catch((error) => {
						if (error.response?.data && error.response.data.includes('code')) {
							context.commit("setGeneralNotificationText", {
								field: "reofferTransaction",
								text: error.response.data
							}, {root: true})
							context.commit('displayNotification', true, {root: true})
						} else context.rootState.errorNotification.display = true
					})
			}
		})
	},
	fetchGetOnboardingData(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.mandateOnboarding;

		return axios.post(url, payload)
			.then((response) => {
				if (response.data.data.numResults > 0) {
					context.commit('setOnboardindDocumentData', response.data.data, {root: true});

					let mandatePayload = {
						requestData: {
							supplierGroupId: payload.supplierGroupId,
							receiverId: response.data.data.items[0].receiverId,
							legalEntityId: response.data.data.items[0].legalEntityId
						},
						mandateOnboardingAccessCode: payload.mandateOnboardingAccessCode
					}
					context.dispatch('getMandateData', mandatePayload);
				} else router.push('/onboardingUnsuccessful')
			})
			.catch(() => {
					router.push('/onboardingUnsuccessful')
				}
			)
	},
	sendFlowCompleted(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.flowCompleted
		return axios.post(url, payload)
			.catch(() => {
				context.rootState.errorNotification.display = true
			})
	},
	retrieveBic(context, payload) {
		let url = context.rootState.API.retrieveBic.replace(":ibanNumber", payload);

		return axios.get(url)
			.then((res) => context.commit("ibanIsChecked", {ibanData: res.data, isChecked: true}, {root: true}))
			.catch(() => context.commit("ibanIsChecked", {ibanData: {}, isChecked: true}, {root: true}))
	}
}