import i18n from "@/i18n"
export default function getStatusesHtml(printStatuses, print) {
	if (print?.printStatuses) {
		let html = '<div class="print-statuses">'
		print.printStatuses.forEach((printItem) => {
			if (printItem.status !== "PRINTER_PROCESSING") printStatuses.push(printItem.status.replaceAll('_', '').toLowerCase())
			let key = 'dropdowns.printStatuses.' + printItem.status.replaceAll('_', '').toLowerCase()
			if(key.includes("error")) {
				key = 'dropdowns.printStatuses.printererror'
			}
			html = html + '<div class="' + printItem.status.replaceAll('_', '').toLowerCase() + '">' + i18n.t(key) + '</div>'
		})

		html = html + '</div>'
		return html
	}
}