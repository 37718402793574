<template>
	<pop-up-window
		:show="this.$store.state.validatePopUp"
		:showClose="false"
		class="doc-statuses-popup"
		additional-info=""
		:positionFixed="true"
		:top0="true"
	>
		<template #title>
			<h1 class="text-2xl">{{ $t('ubl.ublPopUpStatus') }}</h1>
		</template>
		<template #content>
			<div v-if="this.$store.state.validationResponse.success === true" class="validate-message">
				{{ $t('ubl.ublSuccess') }}
			</div>
			<div v-if="this.$store.state.validationResponse.success === false" class="validate-message error-spacing ">
				{{ $t('ubl.ublError') }}
			</div>
			<div v-if="this.$store.state.validationResponse.success === false" class="files-list-outer">
				<div class="files-list">
					<div
						v-for="(item,index) in this.$store.state.validationResponse.errors"
						:key=index
						class="error-item"
					>
						{{ index + 1 }}. {{ item }}
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-center my-6 grey-top-border">
				<div>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="close">
						{{ $t('buttons.close') }}
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../UI/PopUpWindow"

export default {
	name: "ViewUblDetail",
	components: {PopUpWindow},
	props: {
		errors: {
			type: Array,
			default: () => []
		}
	},
	computed: {
		error() {
			return this.$store.state.validationResponse ? this.$store.state.validationResponse.errors : []
		}

	},
	methods: {
		close() {
			this.$store.commit('setValidatePopUp', false)
		}
	}
}
</script>

<style scoped>
.files-list-outer {
	max-height: 300px;
	overflow: scroll;
}

.files-list {
	display: block;
	grid-template-rows: repeat(10, auto);
	grid-template-columns: 50% 50%;
	grid-auto-flow: row;
}

.error-item {
	margin-bottom: 20px;
}

.validate-message {
	text-align: center;
}

.error-spacing {
	margin-bottom: 20px;
}
</style>