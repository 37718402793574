<template>
	<transition name="dialog"
				v-if="this.$store.state.supplierGroup.data.channelsConfig.payment && currentUserDataIsReady">
		<page-container class="page-content">
			<top-banner>
				<div class="w-9/12">
					<h1 class="text-3xl text-center">{{ $t('payments.header.title') }}</h1>
				</div>
			</top-banner>
			<div class="box page-content intro-y box p-5 mt-5" v-if="checkResetPassword">
				<div class="button-container">
					<div class="button-container-inner">
						<div v-for="(type, index) in paymentTypes" :key="index">
							<button
								class="btn custom-button"
								:class="{'inactive' : inactiveItem(type.value)}"
								@click=setActivePaymentType(type.value)
							>
								{{ type.label }}
							</button>
						</div>
					</div>

				</div>
				<div class="component-container">
					<mandates-overview v-if="displayComponent('mandates')"></mandates-overview>
					<failed-transactions v-if="displayComponent('failedTransactions')"></failed-transactions>
				</div>
			</div>
		</page-container>
	</transition>
</template>

<script>
import TopBanner from "../components/TopBanner"
import PageContainer from "../components/PageContainer"
import i18n from '../i18n'
import MandatesOverview from "../components/payments/mandates/MandatesOverview.vue"
import routerData from "../routerData"
import userGroups from "../userGoups"
import store from "../store"
import FailedTransactions from "../components/payments/failedTransactions/FailedTransactions"

const newLoginPage = window.newLoginPage

export default {
	components: {
		FailedTransactions, PageContainer, TopBanner, MandatesOverview,
	},
	data() {
		return {
			currentUserDataIsReady: this.$store.state.currentUserData.data.id,
			i18n: i18n,
			activeType: "mandates",
			userGroups: userGroups,
			routerData: routerData,
		}
	},
	mounted() {
		this.passwordReset()
		this.$store.dispatch("checkAcceptedTos", {requiresAuth: this.$route.meta.requiresAuth})
		this.checkUser()
	},
	computed: {
		checkResetPassword() {
			return !(newLoginPage &&
				((this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
					|| (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined)))
		},
		activeTab() {
			return this.$store.state.paymentActiveTab
		},
		currentUserData() {
			return this.$store.state.currentUserData.data
		},
		paymentTypes() {
			return [
				{label: i18n.t('payments.types.mandates'), value: "mandates"},
				{label: i18n.t('payments.types.failedTransactions'), value: "failedTransactions"},
			]
		},
		locale() {
			return this.$store.state.locale
		}
	},
	watch: {
		currentUserData() {
			this.$store.dispatch('checkAcceptedTos', {requiresAuth: this.$route.meta.requiresAuth})
			this.checkUser()
			this.currentUserDataIsReady = true
			this.passwordReset()
		},
		activeTab(newVal) {
			if (newVal !== '') {
				this.setActivePaymentType(newVal)
			}
		},
		locale() {
			let tooltipsData = [
				{cellName: "cancel-mandate", translationField: 'cancelMandate'},
				{cellName: "edit-mandate", translationField: 'editMandate'},
				{cellName: "edit-mandate-disabled", translationField: 'editMandateDisabled'},
				{cellName: "click-to-copy", translationField: 'clickToCopy'},
				{cellName: "download-mandate", translationField: 'downloadMandate'},
				{cellName: "view-mandate", translationField: 'viewMandate'},
				{cellName: "view-document", translationField: 'viewDocument'},
				{cellName: "bank-account", translationField: 'bankAccount'},
				{cellName: "no-bank-account", translationField: 'noBankAccount'},
				{cellName: "reoffer", translationField: 'reoffer'},
				{cellName: "mark-handled", translationField: 'markHandled'},
				{cellName: "transaction-handled", translationField: 'transactionHandled'},
			]
			tooltipsData.forEach(tooltip => {
				this.changeTooltipsTranslation(tooltip.cellName, tooltip.translationField)
			})
		}
	},
	methods: {
		passwordReset() {
			if (newLoginPage &&
				((this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
					|| (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined))) {
				this.$store.commit("setDisplayResetOwnPassword", true)
			}
		},
		changeTooltipsTranslation(cellName, cellTranslationField) {
			let cellClass = '.' + cellName
			let cells = document.querySelectorAll(cellClass)
			let key = 'tooltips.' + cellTranslationField
			if ((cells.length) !== 0) {
				cells.forEach((cell) => {
					cell.innerHTML = i18n.t(key)
				})

				if (cellName === 'reoffer') {
					cells.forEach((cell) => {
						let classes = cell.classList.value

						if (this.$store.state.locale !== 'nl') {
							if (!classes.includes('tooltip-ml-min40')) cell.classList.add('tooltip-ml-min40')
							if (!classes.includes('tooltip-ml-min90')) cell.classList.remove('tooltip-ml-min90')
						} else {
							if (classes.includes('tooltip-ml-min40')) cell.classList.remove('tooltip-ml-min40')
							if (!classes.includes('tooltip-ml-min90')) cell.classList.add('tooltip-ml-min90')
						}
					})
				}
			}
		},
		checkUser() {
			if (this.$store.state.currentUserData.data.id) {
				if (!((userGroups.supplierNames.includes(store.state.currentUserData.data.group) || userGroups.optipostNames.includes(store.state.currentUserData.data.group))
					&& this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.showMandates)) {
					this.$router.push({path: routerData.noAccess.link})
				}
			}
		},
		setActivePaymentType(item) {
			this.$store.dispatch("updateToken")
			this.activeType = item
			this.$store.commit("setActivePaymentTab", "")
		},
		inactiveItem(type) {
			return type !== this.activeType
		},
		displayComponent(paymentType) {
			return paymentType === this.activeType
		}
	}
}
</script>

<style scoped>
.inactive {
	background-color: var(--theme-primary-100);
	border-color: rgba(85, 85, 85, var(--theme-primary-100));
}

.button-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: end;
}

.button-container-inner {
	display: grid;
	grid-template-columns: repeat(20, auto);
	grid-template-rows: 100%;
}

.custom-button {
	margin-left: 10px;
}

.component-container {
	margin-top: var(--generalPaddings);
	padding-top: var(--generalPaddings);
	border-top: 1px solid var(--theme-primary-50);
}

@media only screen and (max-width: 750px) {
	.button-container > div > div {
		grid-template-columns: 100% !important;
	}
}

</style>