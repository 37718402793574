<template>
	<div class="reports-container intro-y">
		<div class="button-container-outer">
			<div class="button-container-inner">
				<h1>{{ $t('reports.choseReportType') }}</h1>
				<v-select
					v-model="activeReport"
					:options='options'
				>
					<template #no-options="{search}">
						{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
					</template>
				</v-select>
			</div>
		</div>
		<div class="report-form">
			<reportForm :activeReport="activeReport.value"/>
		</div>
	</div>
</template>

<script>
import ReportForm from "./ReportForm"
import i18n from '../../i18n'

export default {
	name: "ReportsContainer",
	components: {ReportForm},
	data() {
		return {
			tabs: {
				mailErrors: {
					isActive: true
				},
				channelDetails: {
					isActive: false
				}
			},
			i18n: i18n,
			activeReport: {
				label: i18n.t('buttons.selectReportType'),
				value: ""
			},
		}
	},
	computed: {
		options() {
			let reports = []
			reports.push({label: i18n.t('buttons.channelDetailed'), value: "CHANNEL_DETAIL"})
			reports.push({label: i18n.t('buttons.channelDetailedArchived'), value: "CHANNEL_ARCHIVED_DETAIL"})
			reports.push({label: i18n.t('buttons.deliveryReports'), value: "MAIL_ERRORS"})
			reports.push({label: i18n.t('buttons.sgSpecificErrorsReport'), value: 'SG_SPECIFIC_ERRORS'})
			if (this.$store.state.supplierGroup.data.holdRelease) {
				reports.push({label: i18n.t('buttons.documentsHeld'), value: 'DOCUMENTS_HELD'})
			}

			if (this.$store.state.supplierGroup.data.channelsConfig?.docImage?.channelChoice?.generateOnboardingAccessCode) {
				reports.push({label: i18n.t('buttons.channelChoice'), value: 'CHANNEL_CHOICE'})
			}
			if (this.$store.state.supplierGroup.data.channelsConfig.payment?.transactionsViaTwikey) {
				reports.push({label: i18n.t('buttons.transactionReport'), value: "TRANSACTIONS"})
			}
			if (this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.showMandates) {
				reports.push({label: i18n.t('buttons.mandateChoicesReport'), value: 'MANDATES_CHOICES'})
				reports.push({label: i18n.t('buttons.mandateOverviewReport'), value: 'MANDATES_OVERVIEW'})
			}
			if (this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.generalPost) {
				reports.push({label: i18n.t('buttons.postDropReport'), value: 'POST_DROP'})
			}
			if (this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.peppol) {
				reports.push({label: i18n.t('buttons.ublStatusReport'), value: 'UBL_STATUS_REPORT'})
			}
			this.sortObjectAlphabetically(reports)
			return reports
		},
		locale() {
			return this.$store.state.locale
		}
	},
	watch: {
		locale() {
			switch (this.activeReport.value) {
				case '':
					this.activeReport.label = i18n.t('buttons.selectReportType')
					break
				case 'mailErrors':
					this.activeReport.label = i18n.t('buttons.deliveryReports')
					break
				case 'channelDetails':
					this.activeReport.label = i18n.t('buttons.channelDetailed')
					break
				case 'channelDetailsArchived':
					this.activeReport.label = i18n.t('buttons.channelDetailedArchived')
					break
				case 'transactions':
					this.activeReport.label = i18n.t('buttons.transactionReport')
					break
				case 'mandateChoices':
					this.activeReport.label = i18n.t('buttons.mandateChoicesReport')
					break
				case 'mandateOverview':
					this.activeReport.label = i18n.t('buttons.mandateOverviewReport')
					break
				case 'channelOnboarding':
					this.activeReport.label = i18n.t('buttons.channelOnboarding')
					break
				case 'postDropReport':
					this.activeReport.label = i18n.t('buttons.postDropReport')
					break
				case 'sgSpecificErrorsReport':
					this.activeReport.label = i18n.t('buttons.sgSpecificErrorsReport')
					break
				case 'ublStatusReport':
					this.activeReport.label = i18n.t('buttons.ublStatusReport')
					break
			}
		}
	},
	methods: {
		sortObjectAlphabetically(obj) {
			return obj.sort(function (a, b) {
				let textA = a.label.toUpperCase()
				let textB = b.label.toUpperCase()
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
			})
		}
	}
}
</script>

<style scoped>
.button-container-outer {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	width: 600px;
	justify-self: center;
}

.report-form {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	justify-items: center;
}

.reports-container {
	display: grid;
	grid-template-rows: auto auto;
	grid-template-columns: 100%;
	justify-items: center;
}

h1 {
	font-weight: bolder;
	font-size: 14px;
	margin-bottom: calc(0.5 * var(--generalPaddings));
}

@media only screen and (max-width: 730px) {
	.report-form {
		justify-self: stretch
	}

	.button-container-outer {
		width: 100%;
	}
}
</style>