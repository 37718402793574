<template>
	<transition name="dialog">
		<div class="page-container">
			<div class="banner" :style="{'background-image': bannerUrl, 'background-color' : bgColor}"></div>
			<div class="login-container-outer">
				<div class="login-container">
					<div class="input-container-outer">
						<div class="input-container">
							<div class="input-header">
								<div
									:style="{'border-bottom-color' : this.$store.state.supplierGroup.branding.secondColor}">
									{{ $t('general.resetPassword') }}
								</div>
							</div>
							<input
								id='email'
								v-model="password"
								type='password'
								@click='cleanAllErrors'
								@keyup='checkPassword'
								class="form-control text-popup"
								:placeholder="$t('placeholders.password')"
							/>
							<div>
								<p :class="{
									'weakPassword' : passwordCheckStyle.weakPassword,
                            		'mediumPassword' : passwordCheckStyle.mediumPassword,
                            		'strongPassword' : passwordCheckStyle.strongPassword,
                        			}"
								>
									{{ passwordCheckText }}
								</p>

								<p class="error mt-2" v-if="errors.passwordsAreNotTheSame">
									{{ $t('errors.passwordsAreNotTheSame') }}
								</p>
								<p class="error mt-2" v-if="errors.passwordsAreEmpty">
									{{ $t('errors.passwordsAreEmpty') }}
								</p>
							</div>
							<input
								v-model="passwordConfirmation"
								class="form-control text-popup"
								type="password"
								:placeholder="$t('placeholders.passwordConfirmation')"
								@click='cleanAllErrors'
							/>
							<div class="button-container">
								<div>
									<button
										class="btn custom-button shadow-md mr-2 showStatuses text-popup"
										:style="{'background-color' : this.$store.state.supplierGroup.branding.mainColor}"
										@click="reset"
									>
										{{ $t('general.resetPassword') }}
									</button>
								</div>
							</div>
						</div>
					</div>
					<div class="login-texts">
						<div class="header-outer">
							<h1
								class="header"
								:style="{'border-bottom-color' : this.$store.state.supplierGroup.branding.secondColor}"
							>
								{{ header }}
							</h1>
						</div>

						<div class="description">
							<h2 class="description-header">
								{{ descriptionHeader }}
							</h2>
							<div v-if='descriptionItems.length'>
								<div v-for="(item, index) in descriptionItems" :key=index>
									<h3>- {{ item }}</h3>
								</div>
							</div>
							<div v-if="this.descriptionItemsFooter.length">
								<div v-for="(item, index) in this.descriptionItemsFooter" :key=index>
									<h3 class="description-footer">{{ item }}</h3>
								</div>
							</div>
							<div v-if="this.$store.state.supplierGroup.branding.contactEmail">
								<h1 class="contactEmail">{{ $t('loginPage.contactEmail') }}
									<a :href="href">
										{{ this.$store.state.supplierGroup.branding.contactEmail }}
									</a>
								</h1>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</transition>
</template>

<script>
import textEN from "../locales/en.json"
import textFR from "../locales/fr.json"
import textNL from "../locales/nl.json"
import textDE from "../locales/de.json"
import i18n from "../i18n"

export default {
	name: "ResetPassword",
	data() {
		return {
			header: "",
			descriptionHeader: "",
			descriptionItems: [],
			descriptionItemsFooter: [],
			descriptionItemsFull: {
				en: textEN.loginPage.description.items,
				fr: textFR.loginPage.description.items,
				nl: textNL.loginPage.description.items,
				de: textDE.loginPage.description.items,
			},
			descriptionFooterFull: {
				en: textEN.loginPage.footer.items,
				fr: textFR.loginPage.footer.items,
				nl: textNL.loginPage.footer.items,
				de: textDE.loginPage.footer.items,
			},
			i18n: i18n,
			passwordCheckText: '',
			passwordCheckStyle: {
				weakPassword: false,
				mediumPassword: false,
				strongPassword: false
			},
			errors: {
				passwordsAreNotTheSame: false,
				passwordsAreEmpty: false,
			},
			password: '',
			passwordConfirmation: ''
		}
	},
	mounted() {
		this.setTexts()
	},
	beforeUnmounted() {
		this.close()
	},
	computed: {
		currentUser() {
			return this.$store.state.currentUserData.data
		},
		bannerUrl() {
			let url = ''
			if (this.$store.state.supplierGroup.branding.bannerUrl) {
				url = 'url("' + this.$store.state.supplierGroup.branding.bannerUrl + '")'
			}
			return url
		},
		bgColor() {
			let color = ''
			if (this.$store.state.supplierGroup.branding.mainColor) {
				color = this.$store.state.supplierGroup.branding.mainColor
			}
			return color
		}
	},
	methods: {
		reset() {
			if (this.password === '' && this.passwordConfirmation === '') {
				this.setError('passwordsAreEmpty', true)
				return
			}
			if (this.password !== this.passwordConfirmation) {
				this.setError('passwordsAreNotTheSame', true)
				return
			}
			let formData = {
				password: this.password,
				passwordConfirmation: this.passwordConfirmation
			}
			this.$store.commit('fromResetPassword', true)
			this.$store.dispatch("changePassword", formData)
		},
		close() {
			this.setPasswordCheckText('')
			this.setPasswordCheckStyle(false, false, false)
			this.passwordConfirmation = ''
			this.password = ''
			this.setError('passwordsAreNotTheSame', false)
			this.setError('passwordsAreEmpty', false)
			this.$store.state.displayResetOwnPassword = false
			this.$store.commit('fromResetPassword', false)
		},
		checkPassword() {
			if (this.password.length === 0) {
				this.setPasswordCheckText('')
				this.setPasswordCheckStyle(false, false, false)
			}
			if (this.password.length > 0 && this.password.length <= 6) {
				this.setPasswordCheckText(i18n.t('password.weak'))
				this.setPasswordCheckStyle(true, false, false)
			}
			if (this.password.length > 6 && this.password.length <= 10) {
				this.setPasswordCheckText(i18n.t('password.medium'))
				this.setPasswordCheckStyle(false, true, false)
			}
			if (this.password.length > 10) {
				let containNumber = /\d/.test(this.password)
				let containUpperCase = /[A-Z]/.test(this.password)
				let containSpecialCharacter = /[!@#$%^&*()_-]/.test(this.password)
				if (this.password.includes('+') || this.password.includes('-') || this.password.includes('|')) {
					containSpecialCharacter = true
				}
				if (containNumber && containUpperCase && containSpecialCharacter) {
					this.setPasswordCheckText(i18n.t('password.strong'))
					this.setPasswordCheckStyle(false, false, true)
				} else {
					this.setPasswordCheckText(i18n.t('password.medium'))
					this.setPasswordCheckStyle(false, true, false)
				}
			}
		},
		setPasswordCheckStyle(weak, medium, strong) {
			this.passwordCheckStyle = {
				weakPassword: weak,
				mediumPassword: medium,
				strongPassword: strong
			}
		},
		setPasswordCheckText(text) {
			this.passwordCheckText = text
		},
		cleanAllErrors() {
			this.setError('passwordsAreNotTheSame', false)
			this.setError('passwordsAreEmpty', false)
		},
		setError(field, value) {
			this.errors[field] = value
		},
		setTexts() {
			if (this.$store.state.supplierGroup.data.sgType) {
				let sgType = this.$store.state.supplierGroup.data.sgType.toLowerCase()
				let headerKey = 'loginPage.header.' + sgType
				this.header = i18n.t(headerKey).replace("INSERT_SG", this.$store.state.supplierGroup.data.name)
				let descriptionHeaderKey = 'loginPage.description.header.' + sgType
				this.descriptionHeader = i18n.t(descriptionHeaderKey).replace("INSERT_SG", this.$store.state.supplierGroup.data.name)
				let descriptionTexts = this.descriptionItemsFull[this.$store.state.locale][sgType]
				this.descriptionItems = []
				for (let item in descriptionTexts) {
					if (descriptionTexts[item] !== '') {
						this.descriptionItems.push(descriptionTexts[item])
					}
				}
				let descriptionFooterTexts = this.descriptionFooterFull[this.$store.state.locale][sgType]
				this.descriptionItemsFooter = []
				for (let item in descriptionFooterTexts) {
					if (descriptionFooterTexts[item] !== '') {
						this.descriptionItemsFooter.push(descriptionFooterTexts[item])
					}
				}
			}
		},
	}
}
</script>

<style scoped>
.page-container {
	min-height: calc(100vh - var(--headerheight) - 100px);
}

.banner {
	height: 350px;
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100vw;
	position: relative;
	margin-left: -2rem;
}

.login-container-outer {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	align-items: center;
	height: 100%;
	margin-top: 40px;
}

.login-container {
	margin-top: 40px;
	display: grid;
	grid-template-columns: 40% 60%;
	grid-template-rows: 100%;
}

.input-container-outer {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	border: 1px solid red;
}

.login-texts {
	margin-left: 40px;
	margin-right: 20%;
}

.input-container {
	border: 1px solid green;
	width: 400px;
	display: grid;
	grid-row-gap: 8px;
	grid-template-columns: 100%;
	grid-template-rows: auto auto;
	grid-auto-flow: row;
	justify-self: end;
	align-self: center;
}

.header-outer {
	display: grid;
	grid-auto-flow: column;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: start;
}

.header-outer .header {
	border-bottom-width: 2px;
	border-bottom-style: solid;
}

.button-container > div {
	width: 350px;
}

.input-header {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	grid-auto-flow: row;
	justify-items: start;
	margin-bottom: 10px;
	width: 350px
}

.input-header > div {
	font-size: 16px;
	font-weight: bolder;
	border-bottom-width: 2px;
	border-bottom-style: solid;
}

.button-container p {
	margin-bottom: 4px;
}

.header {
	font-size: 28px;
	font-weight: bold;
	line-height: 1.5em;
}

h2 {
	margin-top: 30px;
	font-size: 18px;
	line-height: 1.5em;
}

h3 {
	margin-top: 10px;
	font-size: 16px;
	margin-left: 20px;
}

.description-footer {
	line-height: 1.5em;
	margin-top: 30px;
	margin-left: 0;
}

@media only screen and (max-width: 1450px) and (min-width: 1350px) {
	.banner {
		height: 300px;
	}
}

@media only screen and (max-width: 1349px) and (min-width: 1250px) {
	.banner {
		height: 280px;
	}

}

@media only screen and (max-width: 1249px) and (min-width: 950px) {
	.banner {
		height: 250px;
	}
}

@media only screen and (max-width: 949px) and (min-width: 650px) {
	.banner {
		height: 200px;
	}
}

@media only screen and (max-width: 639px) and (min-width: 0px) {
	.banner {
		height: 180px;
		margin-left: -1rem;
	}

	.login-container-outer {
		margin-top: 0;
	}
}

@media only screen and (max-width: 750px) and (min-width: 0px) {
	.banner {
		margin-top: -40px;
	}

}

@media only screen and (max-width: 900px) and (min-width: 0px) {
	.login-container {
		grid-template-columns: 100%;
		grid-template-rows: auto auto;
	}

	.input-container-outer {
		grid-column: 1/2;
		grid-row: 2/3;
		width: 350px;
		margin: 10px auto;
	}

	.login-texts {
		grid-column: 1/2;
		grid-row: 1/2;
		margin-left: 0;
		margin-right: 0;
	}

	.header {
		font-size: 24px;
		text-align: center;
	}

	h2 {
		font-size: 18px;
	}

	h3 {
		font-size: 14px;
	}

	.description-footer {
		margin-top: 20px;
	}
}

@media only screen and (max-width: 420px) and (min-width: 0px) {
	.input-header {
		width: 290px;
	}

	.input-container {
		justify-items: start;
	}
}

@media only screen and (max-width: 450px) and (min-width: 0px) {
	.header {
		font-size: 20px;
	}

	h2, .contactEmail {
		font-size: 16px;
		margin-top: 20px;
	}

	h3 {
		font-size: 12px;
	}
}

.contactEmail {
	margin-top: 10px;
	font-size: 16px;
}

.contactEmail a:hover {
	color: var(--infoColor);
}

.weakPassword {
	color: var(--errorColor)
}

.mediumPassword {
	color: var(--warningColor)
}

.strongPassword {
	color: var(--successeColor)
}
</style>