export default function calcDueDate(itemData, itemOpTableInfo) {
	let dueDate = ''

	if (itemOpTableInfo?.documentDates?.dueDate) {
		dueDate = itemOpTableInfo.documentDates.dueDate
	} else {
		if (itemData.dueDate) dueDate = itemData.dueDate
		if (itemData.reimbursement?.dueDate) dueDate = itemData.reimbursement.dueDate
	}

	if (itemData.mandateOnboardingData?.validityEnd) {
		dueDate = itemData.mandateOnboardingData.validityEnd
	}

	if (itemData.generalDocumentData?.dueDate) {
		dueDate = itemData.generalDocumentData.dueDate
	}
	return dueDate
}