import axios from "axios"
import router from "@/router"
import Keycloak from "keycloak-js"
import userGroups from "@/userGoups";
import routerData from "@/routerData";

const qs = require('qs')
const env = window.environment
export default {
	login(context, payload) {
		let url = context.rootState.API.keycloakToken
		let body = qs.stringify(payload)

		return axios.post(url, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then((res) => {
				context.commit('setUserAuth', true, {root: true})
				context.commit('setToken', res.data.access_token, {root: true})
				context.commit('setRefreshToken', res.data.refresh_token, {root: true})


				context.dispatch('users/fetchCurrentUserData', context.rootState.token, {root: true})
				context.dispatch('fetchLegalEntitiesData', {
					token: context.rootState.token,
					sgId: context.rootState.supplierGroup.data.supplierGroupId
				}, {root: true})

				router.push({path: context.rootState.redirectPageAfterLogin})
			}
		)
			.catch((error) => {
				if (error.response.data.error_description === 'Invalid user credentials') {
					context.commit('setErrors', {field: 'loginCredentials', value: true}, {root: true})
				}
			})
	},
	logout(context) {
		context.commit("redirectPageAfterLogin", '/overview', {root: true})
		context.commit('setUserAuth', false, {root: true})
		context.commit('setToken', '', {root: true})
		context.commit('setRefreshToken', '', {root: true})
		context.commit('setCurrentUserData', {currentUserData: {}, isReady: false}, {root: true})
		context.rootState.userIsAuthenticated = false
	},

	async updateToken(context) {
		let dataToSend = {
			client_id: env.keycloakClient,
			grant_type: "refresh_token",
			refresh_token: context.rootState.refreshToken
		}
		let body = qs.stringify(dataToSend)
		await axios.post(context.rootState.API.keycloakToken, body, {
				headers: {
					'Content-Type': "application/x-www-form-urlencoded",
				}
			}
		).then(res => {
			context.commit('setToken', res.data.access_token, {root: true})
			context.commit('setRefreshToken', res.data.refresh_token, {root: true})
			localStorage.setItem('authToken',  res.data.refresh_token )
			context.rootState.tokenIsValid = true
		})
			.catch(() => {
				context.rootState.tokenIsValid = false
				localStorage.setItem('didLogOut', "yes")
				context.commit("setGeneralNotificationText", {field: "sessionExpiredText", text: "Ok"}, {root: true})
				context.commit('displayNotification', true, {root: true})
			})
	},
	cleanUp(context) {
		let url = context.rootState.domainName + context.rootState.API.cleanUp

		return axios.post(url, {}, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		})
			.then(() => {
				context.commit("setGeneralNotificationText", {field: "cleanUp", text: "Ok"}, {root: true})
				context.commit('displayNotification', true, {root: true})
			})
			.catch(() => {
				context.commit("setGeneralNotificationText", {field: "generalErrorText", text: "Ok"}, {root: true})
				context.commit('displayNotification', true, {root: true})
			})
	},
	resetPassword(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.resetPassword
		return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).catch(() => context.rootState.errorNotification.display = true)
	},
	changePassword(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.changePassword
		return axios.post(url, payload, {
			headers: {
				'Authorization': "bearer " + context.rootState.token,
			}
		})
			.then(() => {
				context.dispatch('users/fetchCurrentUserData', context.rootState.token, {root: true})

				if (context.rootState.currentUserData.data.lastLogin === undefined) {
					context.dispatch('users/updateUserLogin', {
						token: context.rootState.token,
						userId: context.rootState.currentUserData.data.id
					}, {root: true})
				}

				context.commit("setGeneralNotificationText", {field: "passwordChanged", text: "Ok"}, {root: true})
				context.commit('displayNotification', true, {root: true})

				if (context.rootState.fromResetPassword) {
					if(userGroups.receiverNames.includes(this.$store.state.currentUserData.data.group)) {
						if (context.rootState.docType === "BILLING") {
							if (!context.rootState.currentUserData.data.termsAndConditionsAcceptedOn) router.push({path: routerData.tos.link})
							else router.push({path: context.rootState.redirectPageAfterLogin})
						} else router.push({path: context.rootState.redirectPageAfterLogin})
					} else router.push({path: context.rootState.redirectPageAfterLogin})
					// context.dispatch("checkAcceptedTos", {root: true})
				}
			})
			.catch(() => context.rootState.errorNotification.display = true)
	},
	proxyLogin(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.proxyLogin.replace(':token', payload.proxyIdpToken)

		return axios.get(url).then((response) => {
			let refreshToken = response.data.data.refresh_token
			let token = response.data.data.access_token

			let initOptions = {
				url: env.keycloakUrl,
				realm: env.keycloakRealm,
				clientId: env.keycloakClient,
				token: token,
				refreshToken: refreshToken,
			}
			let keycloak = new Keycloak(initOptions)
			keycloak.init({token, refreshToken}).then(() => {
				keycloak.authenticated = true
			})

			context.commit('setUserAuth', true, {root: true})
			context.commit("setToken", token, {root: true})
			context.commit("setRefreshToken", refreshToken, {root: true})
			localStorage.setItem("proxyToken", true)
			context.dispatch('users/fetchCurrentUserData', context.rootState.token, {root: true})
			context.dispatch('fetchLegalEntitiesData', {
				token: context.rootState.token,
				sgId: context.rootState.supplierGroup.data.supplierGroupId
			}, {root: true})
			context.rootState.currentUserData.data.language = context.rootState.supplierGroup.data.languages[0].toLowerCase()
			router.push('/overview')
		})
			.catch(() => {
				context.rootState.errorNotification.display = true
			})
	},
	resetPasswordOnLogin(context, payload) {
		let url = context.rootState.domainName + context.rootState.API.resetPassword
		return axios.post(url, payload.formData, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			}
		).then(() => context.commit('setPasswordResetDisplay', true, {root: true}))
			.catch(() => context.rootState.errorNotification.display = true)
	},
	downloadBillingTerms(context, payload) {
		let url = context.state.domainName + context.state.API.sgTerms

		return axios.post(url, {}, {
				responseType: 'blob',
				headers: {
					'Authorization': "bearer " + payload,
				}
			}
		).then((response) => {
			const url = window.URL.createObjectURL(new Blob([response.data]))
			const link = document.createElement('a')
			link.href = url
			link.setAttribute('download', 'TermsAndConditions.pdf') //or any other extension
			document.body.appendChild(link)
			link.click()
		}).catch(() => context.rootState.errorNotification.display = true)
	},
	acceptTos(context) {
		let url = context.rootState.domainName + context.rootState.API.acceptTos
		return axios.get(url, {
				headers: {
					'Authorization': "bearer " + context.rootState.token,
				}
			})
			.then((res) => {
				let curerntUserData = res.data.data.user
				context.commit('setCurrentUserData', {
					currentUserData: curerntUserData,
					isReady: true
				})
				router.push('/overview')

			})
			.catch(() => router.push('/errorPage'))
	},
}