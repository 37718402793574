<template>
	<div class="overview" v-if="currentUserData && displayComponentAfterSamlRedirect">
		<error-notification/>
		<top-banner>
			<div class="w-9/12">
				<h1 class="text-3xl text-center">{{ $t('overview.header.title') }}</h1>
			</div>
		</top-banner>
		<router-view/>
		<div
			v-if="this.$store.state.currentUserData.isReady && this.$store.state.supplierGroup.isReady && displayComponent && checkResetPassword">
			<tabulator
				v-if="checkTerms"
				:supplierGroupId="this.supplierGroupId"
				:general="this.docTypeGeneral"
				:salary="this.docTypeSalary"
				:billing="this.docTypeBilling"
				:token="this.$store.state.token"
				:requestUrl="this.requestUrl"
			/>
		</div>
	</div>
</template>

<script>
import TopBanner from "../components/TopBanner.vue"
import routerData from "../routerData"
import i18n from '../i18n'
import Tabulator from '../components/overview/Main.vue'
import ErrorNotification from "../components/UI/ErrorNotification.vue"
import router from "../router"
import userGroups from "../userGoups"
import store from "../store"

const newLoginPage = window.newLoginPage

export default {
	components: {ErrorNotification, TopBanner, Tabulator},
	mounted() {
		this.$store.commit("setResendBatch", true)
	},
	created() {
		this.passwordReset()
		this.checkTosAccepted()
		this.watchSupplierGroupId()
		this.checkPortalSetting()
	},
	data() {
		return {
			currentUserDataIsReady: this.$store.state.currentUserData.data.id,
			overviewText: 'top banner text',
			routerObject: routerData,
			selectedDocumentIds: [],
			supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId,
			docTypeGeneral: false,
			docTypeSalary: false,
			docTypeBilling: false,
			requestUrl: this.$store.state.domainName + this.$store.state.API.search,
			currentUser: this.$store.state.currentUserData.isReady,
			supplierGroup: this.$store.state.supplierGroup.isReady,
			displayComponent: false,
			i18n: i18n,
			routerData: routerData,
		}
	},

	computed: {
		checkResetPassword() {
			return !(newLoginPage &&
				((this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
					|| (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined)))
		},
		displayComponentAfterSamlRedirect() {
			return !(this.$store.state.route.from === 'saml' && this.$store.state.route.to === "overview")
		},
		currentUserData() {
			return this.$store.state.currentUserData.data
		},
		checkTerms() {
			if (userGroups.receiverNames.includes(this.$store.state.currentUserData.data.group)) {
				return !(!(this.$store.state.currentUserData.data.termsAndConditionsAcceptedOn) && this.$store.state.docType === "BILLING")
			} else return true

		},
		locale() {
			return this.$store.state.locale
		},
		overviewTableRefreshed() {
			return this.$store.state.overviewTableRefreshed
		},
		overviewPageNumber() {
			return this.$store.state.overviewPageNumber
		},
		extendedSearch() {
			return this.$store.state.extendedSearchValue
		},
		overviewTableSort() {
			return this.$store.state.overviewTableSort
		}
	},
	watch: {
		currentUserData() {
			this.checkTosAccepted()
			this.passwordReset()
			this.currentUserDataIsReady = true
		},
		overviewTableRefreshed(newVal) {
			if (newVal) {
				this.setColumnsTranslation()
				this.$store.commit("setOverviewTableRefreshed", false)
			}
		},
		locale() {
			let payNow = document.querySelectorAll('.pay-now')
			if (payNow.length) {
				payNow.forEach(item => {
					item.innerHTML = i18n.t('tooltips.payNow')
				})
			}
			let paymentStatusesEl = document.querySelectorAll('.payment-status')
			if (paymentStatusesEl.length) {
				paymentStatusesEl.forEach(el => {
					let elClasses = el.className.replace('tooltip tooltip-billing payment-status ', '')
					if (elClasses !== '') {
						let key = 'paymentStatusesTooltips.' + elClasses
						el.innerHTML = i18n.t(key)
					}
				})
			}
			this.setColumnsTranslation()
		},
	},
	methods: {
		checkPortalSetting() {
			if (this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal?.additionalPortalConfig?.billing) {
				if ((this.$store.state.currentUserData.data.group === "SupplierSubUser" && this.$store.state.currentUserData.data.legalEntities.length === 0)) {
					router.push({path: routerData.postDrop.link})
				}
			} else {
				if (this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal?.additionalPortalConfig?.peppol) {
					if (userGroups.optipostNames.includes(store.state.currentUserData.data.group)
						|| ((userGroups.supplierNames.includes(store.state.currentUserData.data.group)
							|| (this.$store.state.currentUserData.data.group === "SupplierSubUser" && this.$store.state.currentUserData.data.legalEntities.length > 0)))
					) {
						router.push({path: routerData.ubl.link})
					}
					if ((this.$store.state.currentUserData.data.group === "SupplierSubUser" && this.$store.state.currentUserData.data.legalEntities.length === 0)) {
						router.push({path: routerData.postDrop.link})
					}
				} else {
					if (this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal?.additionalPortalConfig?.generalPost) {
						router.push({path: routerData.postDrop.link})
					}
				}
			}
		},
		passwordReset() {
			if (newLoginPage &&
				((this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
					|| (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined))
			) {
				this.$store.commit("setDisplayResetOwnPassword", true)
			}
		},
		checkTosAccepted() {
			if (userGroups.receiverNames.includes(this.$store.state.currentUserData.data.group)) {
				if (this.$store.state.currentUserData.data.id) {
					if (this.$store.state.docType === "BILLING"
						&& ((this.$route.meta && this.$route.meta.requiresAuth) || this.$route.name !== "undefined")
						&& (!this.$store.state.currentUserData.data.termsAndConditionsAcceptedOn)) {
						if (this.$store.state.route.from !== "saml") router.push({path: routerData.tos.link})
					}
				}
			}
		},
		setColumnsTranslation() {
			if (this.$store.state.docType === "BILLING") {
				if ((document.querySelectorAll('.billing-doc-type').length) === 0) {
					setTimeout(this.setColumnsTranslation, 100)
					return
				}
				if ((document.querySelectorAll('.billing-doc-type').length) !== 0) {
					let docTypeCells = document.querySelectorAll('.billing-doc-type')
					docTypeCells.forEach(cell => {
						let newCellText = ''
						let dataDoctype = cell.getAttribute('data-doctype')
						let key = 'billing.' + dataDoctype
						newCellText = i18n.t(key)
						cell.innerHTML = newCellText
					})
				}
			}
			if (this.$store.state.docType === "SALARY") {
				if ((document.querySelectorAll('.salary-doc-type').length) === 0) {
					setTimeout(this.setColumnsTranslation, 100)
					return
				}
				if ((document.querySelectorAll('.salary-doc-type').length) !== 0) {
					let docTypeCells = document.querySelectorAll('.salary-doc-type')
					docTypeCells.forEach(cell => {
						let newCellText
						let salaryType = cell.getAttribute('data-doctype')
						let key = 'salary.' + salaryType
						newCellText = i18n.t(key)
						cell.innerHTML = newCellText
					})
				}
			}
		},
		watchSupplierGroupId() {
			if (!this.$store.state.supplierGroup.isReady) {
				setTimeout(() => {
					this.watchSupplierGroupId()
				}, 100)
				return
			}
			if (this.$store.state.supplierGroup.data.sgType === "GENERAL") this.docTypeGeneral = true
			if (this.$store.state.supplierGroup.data.sgType === "SALARY") this.docTypeSalary = true
			if (this.$store.state.supplierGroup.data.sgType === "BILLING") this.docTypeBilling = true

			this.supplierGroupId = this.$store.state.supplierGroup.data.supplierGroupId
			this.displayComponent = true

			this.setColumnsTranslation()
		},
	},
	beforeUnmount() {
		this.$store.commit("setDocumentErrorHandled", false)
	}
}
</script>

<style scoped>
.overview {
	min-height: calc(100vh - var(--headerheight) - var(--footerHeight) - var(--generalPaddings));
}
</style>