<template>
	<transition name="dialog">
		<page-container class="page-content">
			<top-banner>
				<div class="w-9/12">
					<h1 class="text-3xl text-center">{{ $t('help.header.title') }}</h1>
				</div>
			</top-banner>
			<div class="box page-content intro-y box p-5 mt-5">
				<div>
					<div class="downloadUserInfo divider" v-if="this.$store.state.manualExist">
						<h1 class="header font-semibold title">{{ $t('help.content.userGuide.title') }}</h1>
						<div class="user-guid-text">
							<h1 class="text ml-8">
								{{ $t('help.content.userGuide.text') }}
								<span class="link" @click="downloadGuide(locale)">
                               		{{ $t('general.link') }}
                           		</span>.
							</h1>
						</div>
					</div>
					<div v-if="displayPortalUsage" class="divider">
						<h1 class="header font-semibold capitalize title">
							{{ $t('help.content.portalUsage.title') }}
						</h1>
						<div class="text ml-8"
							 v-for="(value, key) in questions" v-bind:key="key">
							<div @click="openQuestionWindow(key)" class="question-title text">
								- {{ $t('help.content.portalUsage.items.' + key + '.title') }}
							</div>
							<pop-up-window
								:show=questionKeys[key]
								:showClose=false
								fixed
								:positionFixed=true
							>
								<template #title>
									<h1 class="header portal-usage-title">
										{{ $t('help.content.portalUsage.items.' + key + '.title') }}
									</h1>
								</template>

								<template #content>
									<h2 class="text portal-usage-text">
										{{ $t('help.content.portalUsage.items.' + key + '.content') }}
									</h2>
									<div class="button-container">
										<button class="btn custom-button shadow-md mr-2 showStatuses" @click=close(key)>
											{{ $t('buttons.close') }}
										</button>
									</div>
								</template>
							</pop-up-window>
						</div>
					</div>
				</div>
			</div>
		</page-container>
	</transition>
</template>

<script>
import TopBanner from "../components/TopBanner"
import questionItems from "../locales/en.json"
import PopUpWindow from "../components/UI/PopUpWindow"
import routerData from "../routerData"
import PageContainer from "../components/PageContainer"
import {useI18n} from "vue3-i18n"

export default {
	components: {TopBanner, PopUpWindow, PageContainer},
	mounted() {
		if (this.$route.redirectedFrom && this.$route.redirectedFrom.params && this.$route.redirectedFrom.params.lang) {
			this.i18n.setLocale(this.$route.redirectedFrom.params.lang.toString().toLowerCase())
			this.$store.commit('setLocale', this.$route.redirectedFrom.params.lang.toString().toLowerCase())
			document.getElementById('lang').setAttribute('value', this.$route.redirectedFrom.params.lang.toString().toLowerCase())
		}

		this.checkDocType()
		this.watchSupplierGroupId()
		this.getQuestionItemsKeys()
		this.checkManual(this.$store.state.locale)
	},
	data() {
		return {
			supplierGroupId: false,
			questions: questionItems.help.content.portalUsage.items,
			showQuestionWindow: false,
			questionKeys: {},
			i18n: useI18n(),
		}
	},
	computed: {
		locale() {
			return this.$store.state.locale.toUpperCase()
		},
		displayPortalUsage() {
			return this.$store.state.docType === "BILLING"
		}
	},
	watch: {
		locale: function (newVal) {
			this.locale = newVal
		}
	},
	methods: {
		checkManual(lang) {
			this.$store.dispatch('checkGuide', lang.toUpperCase())
		},
		checkDocType() {
			if (this.$store.state.docType === "SALARY") {
				this.$router.push({path: routerData.notFound.link})
			}
		},
		downloadGuide(lang) {
			this.$store.dispatch('downloadGuide', lang)
		},
		getQuestionItemsKeys() {
			for (let item in this.questions) {
				this.questionKeys[item] = false
			}
		},
		close(key) {
			this.questionKeys[key] = false
		},
		openQuestionWindow(key) {
			this.questionKeys[key] = !this.questionKeys[key]
		},
		watchSupplierGroupId() {
			if (!this.$store.state.supplierGroup.isReady) {
				setTimeout(() => {
					this.watchSupplierGroupId()
				}, 100)
				return
			}
			if (this.$store.state.supplierGroup.data.sgType === "GENERAL") {
				this.docTypeGeneral = true
			}

			this.supplierGroupId = this.$store.state.supplierGroup.data.supplierGroupId
		},
	}

}
</script>

<style scoped>
.button-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	grid-auto-flow: column;
	justify-items: center;
	margin-top: var(--generalPaddings)
}

.question-title {
	cursor: pointer;
}

.question-title:hover {
	color: var(--infoColor);
}

.title {
	margin-bottom: var(--generalPaddings);
}

.divider {
	margin-bottom: var(--generalPaddings);
	padding: var(--generalPaddings);
	border-bottom: 1px solid var(--theme-primary-100);
}

.user-guid-text {
	display: grid;
	grid-template-rows: auto auto;
	grid-template-columns: 100%;
	grid-auto-flow: column;
}

.user-guid-text div button {
	margin: var(--generalPaddings) 0;
}

.portal-usage-text {
	text-align: justify;
	font-size: 1.2em;
	line-height: 1.4em;
}

.portal-usage-title {
	padding: var(--generalPaddings);
	text-align: center;
}

.header {
	font-size: 24px;
}

.text {
	font-size: 20px;
	line-height: 26px;
}

@media only screen and (min-width: 0px) and (max-width: 900px) {
	.header {
		font-size: 20px;
	}

	.text {
		font-size: 16px;
		line-height: 22px;
	}
}

@media only screen and (min-width: 0px) and (max-width: 650px) {
	.divider {
		padding: 10px;
	}

	.header {
		font-size: 18px;
	}

	.text {
		font-size: 16px;
		line-height: 20px;
	}
}
</style>