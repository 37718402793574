<template>
	<div>
		<p class="font-bold form-label mb-2">{{ $t('ubl.peppolParticipants.labelInput') }}</p>
		<div class="w-1/2">
			<textarea
				rows="8"
				v-model.trim="identifiers"
				@click="setErrors('identifiers', false)"
				class="textarea-style"
				@focusout="countRows"
			/>
		</div>
		<p class="error" v-if="errors.identifiers">{{ $t('ubl.peppolParticipants.errors.emptyInput') }}</p>
		<p class="error" v-if="errors.maxRows">{{ $t('ubl.peppolParticipants.errors.maxRows') }}</p>
		<div class="mt-2 w-1/2 button-container">
			<button
				@click="getParticipants"
				class="btn custom-button"
			>
				{{ $t('ubl.peppolParticipants.buttonViewParticipants') }}
			</button>
		</div>
		<div v-if="this.informationParticipants.length !== 0 || showInfo === true">
			<div class="w-1/2" v-if="this.informationParticipants.length <=0">
				<p>{{ $t('ubl.peppolParticipants.noInfo')}}</p>
			</div>
			<div v-if="this.informationParticipants.length > 0">
				<smp-participants-table :tableData="informationParticipants"/>
			</div>
		</div>
	</div>
</template>

<script>
import SmpParticipantsTable from "@/components/ubl/smpParticipantsTable.vue"
export default {
	name: "findPeppolParticipants",
	components: {SmpParticipantsTable},
	data() {
		return {
			identifiers: '',
			errors: {
				identifiers: false,
				maxRows: false
			},
			selectedIdentifier: '',
			buttonIsClicked: false,
			showInfo: false,
			informationParticipants: []
		}
	},
	mounted() {
		this.$store.state.peppolDataRetrieved = false
	},
	computed: {
		smpParticipants() {
			return this.$store.state.smpParticipants
		}
	},
	watch: {
		identifiers(){
			this.showInfo = false
			this.informationParticipants.length = []
		}
	},
	methods: {
		countRows(){
			this.errors.maxRows = this.identifiers.split("\n").length > 50
		},
		setErrors(field, value) {
			this.errors[field] = value
		},
		async getParticipants() {
			this.showInfo = false
			if (this.errors.maxRows || this.errors.identifiers) return
			this.$store.state.peppolDataRetrieved = false
			if (this.identifiers === '') {
				this.setErrors('identifiers', true)
				return
			}
			let peppolIdentifiers = this.identifiers.toLowerCase().split("\n")
			let peppolIdentifiersFiltered = []

			peppolIdentifiers.forEach(item => {
				if (item !== '') {
					peppolIdentifiersFiltered.push(item.trim())
				}
			})
			this.informationParticipants = []
			try {
				await this.$store.dispatch('peppol/getSmpParticipants', {identifiers: peppolIdentifiersFiltered}).then((response) => {
					for (let identifier in response.data.data.information) {
						for (let i in response.data.data.information[identifier]) {
							if (!response.data.data.information[identifier][i].serviceDescription.toLowerCase().includes('hermes')){
								this.informationParticipants.push({
									identifier: identifier,
									accessPointUrl: response.data.data.information[identifier][i].accessPointUrl,
									documentType: response.data.data.information[identifier][i].documentType.toUpperCase() !== 'UNKNOWN' ? response.data.data.information[identifier][i].documentType  : response.data.data.information[identifier][i].documentIdentifier ,
									serviceDescription: response.data.data.information[identifier][i].serviceDescription
								})
							}
						}
					}
				})
				this.showInfo = true
			} catch (err) {
				console.error(err)
				this.$store.commit('displayNotification', true)
			}
		},
	}
}
</script>

<style scoped>
.textarea-style {
	border: 1px solid rgb(226, 232, 240);
	border-radius: 4px;
	width: 100%;
}

.textarea-style:focus, .textarea-style:focus-visible {
	box-shadow: rgb(255, 255, 255) 0px 0px 0px 0px, rgba(59, 130, 246, 0.5) 0px 0px 0px 3px, rgba(0, 0, 0, 0.05) 0px 1px 2px 0px !important;
	border: 2px solid rgb(226, 232, 240) !important;
	outline-style: hidden !important;
}

.button-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	grid-auto-flow: row;
	justify-items: end;
}
</style>