<template>
	<div id="report-settings">
		<error-notification/>
		<div class="dropdown-report">
			<h1>{{ $t('reportSettings.selectReportLevel') }}</h1>
			<v-select
				v-model="selectedLevel"
				:options='options'
				class="width-elements"
			>
				<template #no-options="{search}">
					{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
				</template>
			</v-select>
		</div>
		<div class="dropdown-report" v-if="selectedLevel.value === 'leLevel'">
			<h1>{{ $t('reportSettings.choseLE') }}</h1>
			<v-select
				v-model="selectedLe.label"
				:options='optionsLE'
				class="width-elements"
			>
				<template #no-options="{search}">
					{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
				</template>
			</v-select>
		</div>
		<div class="dropdown-report">
			<h1>{{ $t('reportSettings.choseReportType') }}</h1>
			<v-select
				v-model="selectedReportType"
				:options='loadOptionsReportType'
				class="width-elements"
				:placeholder="$t('buttons.selectReportType')"
				@click="this.errorRapportType = false"
			>
				<template #no-options="{search}">
					{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
				</template>
			</v-select>
			<p v-if="errorRapportType" class="error">{{ $t('errors.errorSelectRapportType') }}</p>
		</div>
		<div class="dropdown-report" style="width: 38%">
			<email-language-form
				:input-existing-contacts="currentContacts"
				:error-given-input="errorGivenInput"
				:languages="languages"
				@remove-contact="removeOldContact"
				ref="reportSettingsForm"
			/>
		</div>
		<div class="dropdown-report" style="display: flex; flex-direction: row">
			<h1>{{ $t('reportSettings.generateXlsx') }}</h1>
			<input class="customCheckbox"
				   type="checkbox"
				   v-model="generateXlsx"
				   style="margin-left: 10px"
			/>
		</div>
		<div class="dropdown-report width-elements" style="text-align: center">
			<button class="btn custom-button shadow-md mr-2 text-popup" @click="updateReportSettings">
				{{ $t('buttons.updateSettings') }}
			</button>
		</div>

		<confirmation-with-props
			:description="$t('reports.deleteEmailDescription')"
			:show-dialog="showWindow"
			@cancel-action="cancelDeleteMail"
			@approve-action="approveAction"
		/>
	</div>
</template>

<script>
import EmailLanguageForm from "../UI/EmailLanguageForm.vue"
import ErrorNotification from "../UI/ErrorNotification"
import i18n from "../../i18n"
import ConfirmationWithProps from "@/components/UI/ConfirmationWithProps.vue"

export default {
	name: "ReportSettings",
	components: {ConfirmationWithProps, ErrorNotification, EmailLanguageForm},
	data() {
		return {
			selectedLevel: {label: i18n.t('buttons.sgLevel'), value: "sgLevel"},
			selectedLe: {
				label: '',
				value: ''
			},
			selectedReportType: '',
			optionsLE: [],
			optionsReportType: {label: i18n.t('buttons.selectReportType'), value: ""},

			currentContacts: [],
			errorGivenInput: [],
			removedOldContact: false,
			showWindow: false,
			confirmedDelete: false,
			generateXlsx: false,
			errorRapportType: false,
			languages: this.$store.state.supplierGroup.data.languages
		}
	},
	mounted() {
		this.loadLE()
	},
	watch: {
		locale() {
			let key = 'buttons.' + this.selectedLevel.value
			this.selectedLevel.label = i18n.t(key)
		},
		selectedLevel() {
			this.sendCorrectReportingConfig()
		},
		selectedLe: {
			deep: true,
			handler() {
				let allLegalEntities = this.$store.state.legalEntitiesData.data.legalEntities
				for (const le in allLegalEntities) {
					if (allLegalEntities[le].internalName === this.selectedLe.label || allLegalEntities[le].name === this.selectedLe.label){
						this.selectedLe.value = le
					}
				}
				this.sendCorrectReportingConfig()
			}
		},
		selectedReportType() {
			this.sendCorrectReportingConfig()
		},
	},
	computed: {
		locale() {
			return this.$store.state.locale
		},
		options() {
			return [
				{label: i18n.t('buttons.sgLevel'), value: "sgLevel"},
				{label: i18n.t('buttons.leLevel'), value: "leLevel"}
			]
		},
		loadOptionsReportType() {
			let reports = []

			reports.push({label: i18n.t('buttons.channelDetailed'), value: 'CHANNEL_DETAIL'})
			reports.push({label: i18n.t('buttons.channelDetailedArchived'), value: 'CHANNEL_DETAIL_ARCHIVED'})
			reports.push({label: i18n.t('buttons.deliveryReports'), value: 'MAIL_ERRORS'})
			if (this.$store.state.supplierGroup.data.holdRelease) {
				reports.push({label: i18n.t('buttons.documentsHeld'), value: 'DOCUMENTS_HELD'})
			}

			if (this.selectedLevel.value === "sgLevel") reports.push({
				label: i18n.t('buttons.sgSpecificErrorsReport'),
				value: 'SG_SPECIFIC_ERRORS'
			})

			if (this.$store.state.supplierGroup.data.channelsConfig) {
				if (this.$store.state.supplierGroup.data.channelsConfig.payment?.transactionsViaTwikey) {
					reports.push({label: i18n.t('buttons.transactionReport'), value: "TRANSACTIONS"})
				}
				if (this.$store.state.supplierGroup.data.channelsConfig.docImage) {
					if (this.$store.state.supplierGroup.data.channelsConfig.docImage.channelChoice?.generateOnboardingAccessCode) {
						reports.push({label: i18n.t('buttons.channelChoice'), value: 'CHANNEL_CHOICE'})
					}
					if (this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal?.additionalPortalConfig) {
						if (this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.showMandates) {
							reports.push({label: i18n.t('buttons.mandateChoicesReport'), value: 'MANDATES_CHOICES'})
							reports.push({label: i18n.t('buttons.mandateOverviewReport'), value: 'MANDATES_OVERVIEW'})
						}
						if (this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost) {
							if (this.selectedLevel.value === "sgLevel") reports.push({
								label: i18n.t('buttons.postDropReport'),
								value: 'POST_DROP'
							})
						}
						if (this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.peppol) {
							reports.push({label: i18n.t('buttons.ublStatusReport'), value: 'UBL_STATUS_REPORT'})
						}
					}
				}
			}
			this.sortObjectAlphabetically(reports)
			return reports
		}
	},
	methods: {
		loadLE() {
			let allLegalEntities = this.$store.state.legalEntitiesData.data.legalEntities
			for (const le in allLegalEntities) {
				if (allLegalEntities[le].internalName !== undefined) {
					this.optionsLE.push(allLegalEntities[le].internalName)
				} else if (allLegalEntities[le].name !== undefined) {
					this.optionsLE.push(allLegalEntities[le].name)
				} else this.optionsLE.push(le)
			}
			this.optionsLE.sort(function (a, b) {
				let textA = a.toUpperCase()
				let textB = b.toUpperCase()
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
			})
		},
		sortObjectAlphabetically(obj) {
			return obj.sort(function (a, b) {
				let textA = a.label.toUpperCase()
				let textB = b.label.toUpperCase()
				return (textA < textB) ? -1 : (textA > textB) ? 1 : 0
			})
		},
		// to show the Current email address with language
		sendCorrectReportingConfig() {
			this.currentContacts = []
			this.errorGivenInput = []
			if (this.selectedLevel.value !== '' && this.selectedReportType !== '') {
				let temp
				if (this.selectedLevel.value === 'sgLevel') {
					if (this.$store.state.supplierGroup.data.reportingConfig === undefined) {
						this.$store.state.supplierGroup.data.reportingConfig = {
							receiverEmails: {},
							generateXlsx: {}
						}
					}
					if (this.$store.state.supplierGroup.data.reportingConfig.receiverEmails === undefined) this.$store.state.supplierGroup.data.reportingConfig.receiverEmails = {}
					if (this.$store.state.supplierGroup.data.reportingConfig.generateXlsx === undefined) {
						this.$store.state.supplierGroup.data.reportingConfig.generateXlsx = {}
					}
					temp = this.$store.state.supplierGroup.data.reportingConfig.receiverEmails[this.selectedReportType?.value]
					this.generateXlsx = this.$store.state.supplierGroup.data.reportingConfig.generateXlsx[this.selectedReportType?.value]
				} else if (this.selectedLe.value !== '') {
					if (this.$store.state.supplierGroup.data.supplierLegalEntities[this.selectedLe.value].legalEntityConfig?.reportingConfig === undefined) {
						this.$store.state.supplierGroup.data.supplierLegalEntities[this.selectedLe.value].legalEntityConfig.reportingConfig = {
							receiverEmails: {},
							generateXlsx: {}
						}
					}
					if (this.$store.state.supplierGroup.data.supplierLegalEntities[this.selectedLe.value].legalEntityConfig.reportingConfig.receiverEmails === undefined) {
						this.$store.state.supplierGroup.data.supplierLegalEntities[this.selectedLe.value].legalEntityConfig.reportingConfig.receiverEmails = {}
					}
					if (this.$store.state.supplierGroup.data.supplierLegalEntities[this.selectedLe.value].legalEntityConfig.reportingConfig.generateXlsx === undefined) {
						this.$store.state.supplierGroup.data.supplierLegalEntities[this.selectedLe.value].legalEntityConfig.reportingConfig.generateXlsx = {}
					}

					temp = this.$store.state.supplierGroup.data.supplierLegalEntities[this.selectedLe.value].legalEntityConfig.reportingConfig.receiverEmails[this.selectedReportType?.value]
					this.generateXlsx = this.$store.state.supplierGroup.data.supplierLegalEntities[this.selectedLe.value].legalEntityConfig.reportingConfig.generateXlsx[this.selectedReportType?.value]
				}
				if (temp) {
					let keys = Object.keys(temp)

					keys.forEach(email => {
						this.currentContacts.push({email: email, language: temp[email]})
						this.errorGivenInput.push(false)
					})
				}
			}
		},
		// when clicked on trash can then remove from temp list (remove in backend when clicked on update settings button)
		removeOldContact(index) {
			this.currentContacts.splice(index, 1)
			if (this.removedOldContact === false) {
				this.removedOldContact = true
			}
			this.confirmedDelete = false
		},
		cancelDeleteMail() {
			this.showWindow = false
			this.sendCorrectReportingConfig()
		},
		approveAction() {
			this.confirmedDelete = true
			this.updateReportSettings()
		},
		updateReportSettings() {
			this.errorGivenInput = []
			this.errorRapportType = false
			this.currentContacts.forEach(() => {
				this.errorGivenInput.push(false)
			})

			const newEmailContacts = this.$refs.reportSettingsForm.newContacts
			let errorInInput = false
			// confirm delete
			if (this.selectedLevel.value === 'sgLevel' && this.confirmedDelete === false) {
				if (this.removedOldContact) {
					this.showWindow = true
					return
				}
			} else if (this.selectedLe.value !== '' && this.confirmedDelete === false) {
				if (this.removedOldContact) {
					this.showWindow = true
					return
				}
			}

			let reportingConfig = {
				reportingConfig: {
					receiverEmails: {},
					generateXlsx: null
				}
			}

			// load original data
			if (this.selectedLevel.value === "sgLevel") reportingConfig.reportingConfig = this.$store.state.supplierGroup.data.reportingConfig
			else reportingConfig.reportingConfig = this.$store.state.supplierGroup.data.supplierLegalEntities[this.selectedLe.value].legalEntityConfig.reportingConfig

			if (reportingConfig.reportingConfig.generateXlsx === undefined) reportingConfig.reportingConfig.generateXlsx = {}
			if (reportingConfig.reportingConfig.receiverEmails === undefined) reportingConfig.reportingConfig.receiverEmails = {}

			// if remove of email is confirmed make the refill field of the chosen reportType empty
			if (this.removedOldContact === true) {
				reportingConfig.reportingConfig.receiverEmails[this.selectedReportType.value] = {}
				this.currentContacts.forEach(contact => {
					if (contact.email !== "") {
						reportingConfig.reportingConfig.receiverEmails[this.selectedReportType.value][contact.email] = contact.language
					}
				})
			}

			if (this.selectedReportType.value === undefined) {
				this.errorRapportType = true
				return
			}
			// add new mails if there are any
			if (newEmailContacts.length > 1) {
				newEmailContacts.forEach(contact => {
					const isValid = this.validateContact(contact)
					if (isValid) {
						if (reportingConfig.reportingConfig.receiverEmails[this.selectedReportType.value] === undefined) {
							reportingConfig.reportingConfig.receiverEmails[this.selectedReportType.value] = {}
						}
						reportingConfig.reportingConfig.receiverEmails[this.selectedReportType.value][contact.email] = contact.language
					} else {
						this.errorGivenInput[newEmailContacts.indexOf(contact)] = true
						errorInInput = true
					}
				})

			} else if (newEmailContacts.length === 1) {
				if (newEmailContacts[0].email === "" && newEmailContacts[0].language === "") {
					console.info('no new contact')
				} else {
					const isValid = this.validateContact(newEmailContacts[0])
					if (isValid) {
						if (reportingConfig.reportingConfig.receiverEmails[this.selectedReportType.value] === undefined) {
							reportingConfig.reportingConfig.receiverEmails[this.selectedReportType.value] = {}
						}
						reportingConfig.reportingConfig.receiverEmails[this.selectedReportType.value][newEmailContacts[0].email] = newEmailContacts[0].language
					} else {
						errorInInput = true
						this.errorGivenInput[0] = true
					}
				}
			}
			this.loadOptionsReportType.forEach(type => {
				if (reportingConfig.reportingConfig.receiverEmails[type.value] !== undefined) {
					if (Object.keys(reportingConfig.reportingConfig.receiverEmails[type.value]).length === 0) {
						delete reportingConfig.reportingConfig.receiverEmails[type.value]
					}
				}
			})

			// update the generateXlsx
			if (this.generateXlsx === undefined) this.generateXlsx = false
			reportingConfig.reportingConfig.generateXlsx[this.selectedReportType.value] = this.generateXlsx

			Object.keys(reportingConfig.reportingConfig.generateXlsx).forEach(type => {
				if (reportingConfig.reportingConfig.generateXlsx[type] === false) {
					delete reportingConfig.reportingConfig.generateXlsx[type]
				}
			})

			if (Object.keys(reportingConfig.reportingConfig.generateXlsx).length === 0) delete reportingConfig.reportingConfig.generateXlsx
			if (Object.keys(reportingConfig.reportingConfig.receiverEmails).length === 0) delete reportingConfig.reportingConfig.receiverEmails
			if (errorInInput === false) {
				const payload = {
					formData: reportingConfig,
					legalEntityNumber: this.selectedLe.value,
				}

				if (this.selectedLevel.value === "sgLevel") {
					this.$store.dispatch('report/reportSettingsSgLevel', payload)
				} else {
					this.$store.dispatch('report/reportSettingsLeLevel', payload)
				}

				this.showWindow = false
				this.$refs.reportSettingsForm.newContacts = [{
					email: '',
					language: ''
				}]
				this.removedOldContact = false
				this.sendCorrectReportingConfig()
			}
		},
		validateContact(contact) {
			let isValid = true
			if (contact.email === '') isValid = false
			else if (!this.$store.state.regex.emailRegex.test(contact.email)) isValid = false
			if (contact.language === '') isValid = false

			return isValid
		}
	}
}
</script>

<style scoped>
.dropdown-report {
	position: relative;
	left: 32.5%;
	margin-bottom: 15px;
}

.button-container-inner button {
	margin-left: 10px;
}

h1 {
	font-weight: bolder;
	font-size: 14px;
	margin-bottom: calc(0.5 * var(--generalPaddings));
}

.width-elements {
	width: 35%
}

@media (max-width: 1100px) {
	.width-elements {
		width: 50%;
	}
}

@media (max-width: 860px) {
	.width-elements {
		width: 70%;
	}

	.dropdown-report {
		position: relative;
		left: 10%;
		margin-bottom: 15px;
	}
}

@media (max-width: 600px) {
	.width-elements {
		width: 95%
	}
}

@media only screen and (max-width: 700px) {
	.report-form > div {
		width: 100%;
	}

}
</style>