<template>
	<transition name="dialog">
		<page-container v-if="supplierGroupId">
			<MandatesTable
				:supplierGroupId="this.supplierGroupId"
				:token="this.$store.state.token"
				:requestUrl="this.requestUrl"
			/>
		</page-container>
	</transition>
</template>
<script>
import MandatesTable from "./MandatesTable"
import PageContainer from "../../PageContainer"

export default {
	components: {MandatesTable, PageContainer},
	created() {
		this.watchSupplierGroupId()
	},
	beforeUnmount() {
		this.$store.commit("setMandateTableSearch", '')
	},
	data() {
		return {
			supplierGroupId: '',
			requestUrl: this.$store.state.domainName + this.$store.state.API.mandates,
		}
	},
	methods: {
		watchSupplierGroupId() {
			if (!this.$store.state.supplierGroup.isReady) {
				setTimeout(() => {
					this.watchSupplierGroupId()
				}, 100)
				return
			}
			this.supplierGroupId = this.$store.state.supplierGroup.data.supplierGroupId
		},
	}
}
</script>