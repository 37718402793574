<template>
	<transition name="dialog">
		<div v-if="this.$store.state.supplierGroup.isReady">
			<slot></slot>
		</div>
	</transition>
</template>

<script>
export default {
	name: "PageContainer",
	data() {
		return {
			dataIsReady: {
				SG: this.$store.state.supplierGroup.isReady,
				currentUser: this.$store.state.currentUserIsReady
			}
		}
	},
	watch: {
		SGIsReady(newVal) {
			this.dataIsReady.SG = newVal
		},
		currentUser(newVal) {
			this.dataIsReady.currentUser = newVal
		}
	},
	computed: {
		currentUser() {
			return this.$store.state.currentUserIsReady
		},
		SGIsReady() {
			return this.$store.state.supplierGroup.isReady
		}
	}
}
</script>