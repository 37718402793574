<template>
	<pop-up-window
		:show="this.$store.state.userManagement.displayDeleteUser"
		:showClose=false
		@close="closeDeleteUser"
		class="doc-statuses-popup"
		additional-info=""
		:positionFixed=true
		:stickToScrollPosition=true
		:top10=true
		fixed
	>
		<template #title>
			<h1 class="text-2xl">{{ $t('management.deleteUser.title') }}</h1>
		</template>
		<template #content>
			<div class="user-info divider">
				<h2 class="text-lg text-center">
					<i class="icon-notification warning"/> {{ $t('management.deleteUser.text') }}:
				</h2>
				<h2 class="text-center">
					{{ this.$store.state.userManagement.userData.email }}
				</h2>
			</div>
			<div class="grid grid-rows-1 justify-items-center my-6">
				<div>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="closeDeleteUser">
						{{ $t('buttons.cancel') }}
					</button>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="deleteUser">
						{{ $t('buttons.delete') }}
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow.vue"

export default {
	name: "DeleteUser",
	components: {PopUpWindow},
	emits: ['userDeleted'],
	methods: {
		closeDeleteUser() {
			this.$store.commit('displayDeleteUser', false)
		},
		deleteUser() {
			this.$store.dispatch('users/deleteUser', {id: this.$store.state.userManagement.userData.id})
			this.closeDeleteUser()
			this.$emit("userDeleted")
		}
	}
}
</script>

<style scoped>
.divider {
	padding-bottom: calc(0.5 * var(--generalPaddings));
	border-bottom: 1px solid rgba(226, 232, 240, var(--tw-border-opacity));
}
</style>
