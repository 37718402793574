<template>
	<pop-up-window
		:show="this.$store.state.generalNotification.display"
		:showClose=false
		class="doc-statuses-popup"
		additional-info=""
		:top20=true
		fixed
		:positionFixed=fixed
		:absolute=!fixed
		:zIndexTop="true"
		:session-expired="this.$store.state.generalNotification.sessionExpiredText !== ''"
	>
		<template #title>
			<h1 class="text-2xl">{{ $t('generalNotification.title') }}</h1>
		</template>
		<template #content>
			<div class="user-info">
				<h2 class="text-popup-header text-center">
					<div v-if="this.$store.state.uploadedFileErrorOccurred">
						{{ successfulUploadText }}
					</div>
					<i class="icon-notification"
					   :class="{'error' : error, 'warning' : warning, 'success' : success }"
					></i>
					{{ notificationText }}
				</h2>
				<div v-if="batchId" class="text-center">
					{{ $t('generalNotification.batchId') }} {{ batchId }}
				</div>
				<div v-if="this.$store.state.uploadedFileNamesError" class="file-names">
					<div v-for="(name, index) in this.$store.state.uploadedFileNamesError" :key="index">
						{{ index + 1 }}. {{ name }}
					</div>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-center my-6 grey-line">
				<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="closeNotification">
					{{ $t('buttons.close') }}
				</button>
				<div v-if=generalView >
					<button
						v-if="this.$store.state.generalNotification.fileUploaded"
						class="btn custom-button shadow-md mr-2 showStatuses text-popup"
						@click="startPrint"
					>
						{{ $t('buttons.startPrint') }}
					</button>
					<button
						v-if="this.$store.state.uploadedFileErrorOccurred
                            && !this.$store.state.resendErrorUploadedFiles"
						class="btn custom-button shadow-md mr-2 showStatuses text-popup"
						@click="resendErrorUploadedFiles"
					>
						{{ $t('buttons.retry') }}
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "./PopUpWindow"
import i18n from '../../i18n'

const newLoginPage = window.newLoginPage
export default {
	name: "GeneralNotification",
	components: {PopUpWindow},
	data() {
		return {
			i18n: i18n,
			error: false,
			warning: false,
			success: true,
			successfulUploadText: '',
			batchId: '',
			notificationTexts: [
				'setCookiesWarningText',
				'text',
				'deleteMandateText',
				'sessionExpiredText',
				'errorText',
				'generalErrorText',
				'emailReactivated',
				'fileUploaded',
				'passwordChanged',
				'editMandateText',
				'fileUploadedError',
				'errorsHandled',
				"startPrint",
				"departmentExists",
				"reofferTransaction",
				"deleteBatch",
				"reofferTransactionMandateCancelled",
				"cleanUp",
				"markHandledFailedTransaction",
				"couldNotCancelMandate",
				"couldNotCreateMandate",
				"documentDetailEmailReactivated"
			]
		}
	},
	computed: {
		fixed() {
			return this.$store.state.generalNotification.fileUploadedError === ''
		},
		generalView() {
			if ((this.$store.state.generalNotification.emailReactivated !== '' || this.$store.state.generalNotification.emailCorrected !== '')) {
				return this.$route.name === 'management'
			}
			return true
		},
		notificationText() {
			if (this.$store.state.generalNotification.sessionExpiredText !== '') {
				this.setIconColor(false, true, false)
				return i18n.t('generalNotification.sessionExpired')
			}
			if (this.$store.state.generalNotification.text !== '') {
				let key = 'onboarding.texts.' + this.$store.state.generalNotification.text
				return i18n.t(key)
			}
			if (this.$store.state.generalNotification.couldNotCancelMandate !== ''){
				return i18n.t('generalNotification.couldNotCancelMandate')
			}
			if (this.$store.state.generalNotification.couldNotCreateMandate !== ''){
				return i18n.t('generalNotification.couldNotCreateMandate')
			}
			if (this.$store.state.generalNotification.deleteMandateText !== '') {
				return i18n.t('generalNotification.deleteMandateText')
			}
			if (this.$store.state.generalNotification.cookiesWarningText !== '') {
				this.setIconColor(false, true, false)
				return i18n.t('generalNotification.cookiesWarningText')
			}
			if (this.$store.state.generalNotification.generalErrorText !== '') {
				this.setIconColor(true, false, false)
				return i18n.t('generalNotification.generalErrorText');
			}
			if (this.$store.state.generalNotification.cleanUp !== '') {
				return i18n.t('generalNotification.cleanUp')
			}

			if (this.$store.state.generalNotification.emailReactivated !== '') {
				return i18n.t('generalNotification.emailReactivated')
			}
			if (this.$store.state.generalNotification.markHandledFailedTransaction !== '') {
				return i18n.t('generalNotification.markHandledFailedTransaction')
			}
			if (this.$store.state.generalNotification.deleteBatch !== '') {
				return i18n.t('generalNotification.deleteBatch')
			}
			if (this.$store.state.generalNotification.fileUploaded !== '') {
				this.setBatchId()
				return this.$store.state.uploadedFileNames.length + " "
					+ i18n.t('generalNotification.of') + " "
					+ this.$store.state.totalFilesNumber + " "
					+ i18n.t('generalNotification.fileUploaded')
			}
			if (this.$store.state.generalNotification.passwordChanged !== '') {
				return i18n.t('generalNotification.passwordChanged')
			}
			if (this.$store.state.generalNotification.editMandateText !== '') {
				return i18n.t('generalNotification.editMandateText')
			}
			if (this.$store.state.generalNotification.fileUploadedError !== '') {
				this.setSuccessfulUploadedText();
				this.setIconColor(true, false, false)
				return i18n.t('generalNotification.fileUploadedError')
			}
			if (this.$store.state.generalNotification.errorsHandled !== '') {
				return i18n.t('generalNotification.errorsHandled')
			}
			if (this.$store.state.generalNotification.startPrint !== '') {
				return i18n.t('generalNotification.startPrint')
			}
			if (this.$store.state.generalNotification.departmentExists !== '') {
				this.setIconColor(true, false, false)
				return i18n.t('generalNotification.departmentExists')
			}
			if (this.$store.state.generalNotification.reofferTransactionMandateCancelled !== '') {
				this.setIconColor(false, true, false)
				return i18n.t('generalNotification.reofferTransactionMandateCancelled')
			}
			if (this.$store.state.generalNotification.reofferTransaction !== '') {
				if (this.$store.state.generalNotification.reofferTransaction !== "OK") {
					this.setIconColor(true, false, false)
					if (this.$store.state.generalNotification.reofferTransaction.includes("err_no_contract")) {
						return i18n.t('generalNotification.noContractFound')
					}
					if (this.$store.state.generalNotification.reofferTransaction.includes("err_no_transaction")) {
						return i18n.t('generalNotification.noMandateFound')
					}
					if (this.$store.state.generalNotification.reofferTransaction.includes("err_invalid_params")) {
						return i18n.t('generalNotification.invalidParams')
					}
					return i18n.t('generalNotification.reofferrTransactionError')
				}
				return i18n.t('generalNotification.reofferTransactionSuccesfull')
			}
			if (this.$store.state.generalNotification.documentDetailEmailReactivated === true) {
				return i18n.t('generalNotification.documentDetailEmailReactivated')
			}
			return i18n.t('generalNotification.requestSentSuccessfully')
		}
	},
	methods: {
		startPrint() {
			this.$store.dispatch('startPrint', [this.batchId])
			this.$store.commit("setPrintBatchAfterUpload", true)
			this.closeNotification()
		},
		setBatchId() {
			this.batchId = this.$store.state.postDrop.uploadBatchId
		},
		setSuccessfulUploadedText() {
			this.successfulUploadText = this.$store.state.uploadedFileNames.length + " "
				+ i18n.t('generalNotification.of') + " "
				+ this.$store.state.totalFilesNumber + " "
				+ i18n.t('generalNotification.fileUploaded')
		},
		resendErrorUploadedFiles() {
			this.$store.commit("setResetErrorUploadedFiles", true)
			this.closeNotification()
		},
		setIconColor(error, warning, success) {
			this.error = error
			this.warning = warning
			this.success = success
		},
		closeNotification() {
			if (this.$store.state.resendErrorFiles) {
				this.$store.commit('setUploadFileErrorOccurred', false)
				this.$store.state.errorUploadedFiles = []
				this.$store.state.uploadedFileNames = []
				this.$store.state.uploadedFileNamesError = []
				this.$store.commit("setResetErrorUploadedFiles", false)
			}
			if (this.$store.state.generalNotification.sessionExpiredText !== '') {
				if (newLoginPage) {
					this.$store.dispatch('logout')
				} else {
					this.$root.keycloak.logout()
				}
			}
			this.$store.commit('displayNotification', false);
			if (this.$store.state.generalNotification.fileUploaded !== '' && !this.$store.state.postDrop.printBatchAfterUpload) {
				this.$store.commit("setDisplayExtraInfo", true)
			}
			this.$store.state.generalNotification.documentDetailEmailReactivated = false
			this.$store.state.uploadedFileNamesError = []
			this.successfulUploadText = ''
			this.notificationTexts.forEach(text => {
				this.$store.commit("setGeneralNotificationText", {field: text, text: ""})
			})
			this.setIconColor(false, false, true)
			this.batchId = ''
			this.$store.commit("setUploadBatchId", '')
			this.$store.commit("setPrintBatchAfterUpload", false)
		},
		resendDocument() {
			this.closeNotification();
			this.$store.state.documentDetail.displayResendDocument = true
		}
	}
}
</script>

<style scoped>
.error {
	color: var(--errorColor);
}

.warning {
	color: var(--warningColor);
}

.success {
	color: var(--successeColor);
}

.buttons-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: center;
	grid-auto-flow: row;
}

.file-names {
	display: grid;
	grid-template-rows: repeat(10, auto);
	grid-template-columns: 50% 50%;
	margin-top: 10px;
}
</style>