<template>
    <div>
        <the-header/>
        <loading-notification/>
        <router-view/>
        <the-footer/>
    </div>
</template>
<script>
import TheHeader from "./TheHeader"
import TheFooter from "./TheFooter"
import LoadingNotification from "./UI/LoadingNotification"

export default {
    name: "MainContainer",
    components: {LoadingNotification, TheFooter, TheHeader},
}
</script>
