<template>
	<div>
		<div class="button-container">
			<button class="btn custom-button" @click="onExportCsv">
				{{ $t('buttons.download') }}
			</button>
		</div>
		<div id="tabulator" ref="tableRef" class="mt-5 table-report table-report--tabulator"></div>
	</div>
</template>

<script>
import {onMounted, ref, watch} from "vue"
import {TabulatorFull as Tabulator} from 'tabulator-tables'
import en from "@/locales/en.json"
import nl from "@/locales/nl.json"
import de from "@/locales/de.json"
import fr from "@/locales/fr.json"
import sk from "@/locales/sk.json"
import es from "@/locales/es.json"
import i18n from "@/i18n"
import {useStore} from "vuex"

export default {
	name: "SmpParticipantsTable",
	props: {
		tableData: Array,
	},
	setup(props) {
		watch(() => props.tableData, () => {
			initTabulator()
		})

		const store = useStore()
		const tableRef = ref()
		const tabulator = ref()
		const tableIsLoading = ref(false)
		const initTabulator = () => {
			tabulator.value = new Tabulator(tableRef.value, {
				data: props.tableData,
				printAsHtml: true,
				headerSortElement: '',
				printStyled: true,
				pagination: "local",
				paginationSize: 10,
				layout: "fitColumns",
				reactiveData: true,
				responsiveLayout: "collapse",
				placeholder: i18n.t('placeholders.noMatchingFound'),
				locale: true,
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px;'></div>`,
				langs: {
					"en-us": en,
					"nl-nl": nl,
					"de-de": de,
					"fr-fr": fr,
					"sk-sk": sk,
					"es-es": es,
				},
				columns: [
					{
						title: "IDENTIFIER",
						field: "identifier",
						headerHozAlign: "center",
						hozAlign: "center",
						width: '250',
						formatter(cell) {
							return `<div class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1" >
                          				<div>
                               				${cell.getData().identifier}
                          				</div>
                    				</div>`
						}
					},
					{
						title: "DOCUMENT TYPE",
						field: "documentType",
						hozAlign: "center",
						headerHozAlign: "center",
						formatter(cell) {
							return `<div class="font-medium whitespace-nowrap grid grid-cols-1 grid-rows-1">
                          				<div>
                                			${cell.getData().documentType}
                          				</div>
                    				</div>`;
						}
					}
				]
			})
			tabulator.value.on('dataLoading', ()=> {
				tableIsLoading.value = true
			})
			tabulator.value.on('dataLoaded', () => {
				tableIsLoading.value = false
				setLocale()
			})
		}
		watch(() => store.state.locale, () => {
			setLocale()
		})
		const setLocale = () => {
			if (store.getters.getLocale === 'en') tabulator.value.setLocale(store.getters.getLocale + '-us')
			else tabulator.value.setLocale(store.getters.getLocale + '-' + store.getters.getLocale)
		}
		watch(tableIsLoading, () => {
			if(tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
		const onExportCsv = () => {
			tabulator.value.download('csv', 'Peppol_Info.csv', {delimiter: ";"})
		}

		onMounted(() => {
			initTabulator()
		})

		return {
			tableRef,
			onExportCsv
		}
	}
}
</script>

<style scoped>
.button-container {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	grid-auto-flow: row;
	justify-items: end;
}
</style>