<template>
	<transition name="dialog">
		<page-container v-if="supplierGroupId">
			<failed-transactions-table
				:supplierGroupId="this.supplierGroupId"
				:token="this.$store.state.token"
				:requestUrl="this.requestUrl"
			/>
		</page-container>
	</transition>
</template>
<script>
import PageContainer from "../../PageContainer"
import FailedTransactionsTable from "./FailedTransactionsTable"

export default {
	name: "FailedTransactions",
	components: {PageContainer, FailedTransactionsTable},
	created() {
		this.watchSupplierGroupId()
	},
	data() {
		return {
			supplierGroupId: '',
			requestUrl: this.$store.state.domainName + this.$store.state.API.failedTransasctions,
		}
	},
	methods: {
		watchSupplierGroupId() {
			if (!this.$store.state.supplierGroup.isReady) {
				setTimeout(() => {
					this.watchSupplierGroupId()
				}, 100)
				return
			}
			this.supplierGroupId = this.$store.state.supplierGroup.data.supplierGroupId
		}
	}
}
</script>