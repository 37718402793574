<template>
	<transition name="dialog" v-if="displayPostDrop && currentUserDataIsReady && this.$store.state.departmentsSet">
		<page-container class="page-content">
			<error-notification/>
			<top-banner>
				<div class="w-9/12">
					<h1 class="text-3xl text-center">{{ $t('postDrop.title') }}</h1>
				</div>
			</top-banner>
			<div class="box page-content intro-y box p-5 mt-5" v-if="checkResetPassword">
				<div class="button-container">
					<div class="button-container-inner">
						<div v-for="(tab, index) in tabs" :key="index">
							<button
								class="btn custom-button"
								:class="{'inactive' : inactiveTab(tab.value)} "
								@click=setActiveTab(tab.value)
							>
								{{ tab.label }}
							</button>
						</div>
					</div>
				</div>
				<div class="component-container">
					<nymdrop-overview v-if="displayComponent('statuses')"/>
					<upload-files v-if="displayComponent('uploadFiles')"/>
					<uploaded-files v-if="displayComponent('uploadedFiles')"/>
					<validate-file v-if="displayComponent('validateFile')"/>
				</div>
			</div>
		</page-container>
	</transition>
</template>

<script>
import TopBanner from "../components/TopBanner"
import PageContainer from "../components/PageContainer"
import routerData from "../routerData"
import i18n from '../i18n'
import NymdropOverview from '../components/postDrop/NymdropOverview.vue'
import UploadFiles from '../components/postDrop/UploadFiles'
import UploadedFiles from '../components/postDrop/UploadedFiles'
import ValidateFile from '../components/postDrop/ValidateFile'

import userGroups from "../userGoups"
import store from "../store"
import ErrorNotification from "../components/UI/ErrorNotification"

const newLoginPage = window.newLoginPage

export default {
	name: "PostDrop",
	components: {
		PageContainer, TopBanner, NymdropOverview, UploadFiles, UploadedFiles, ErrorNotification, ValidateFile
	},
	mounted() {
		this.$store.dispatch('checkAcceptedTos', {requiresAuth: this.$route.meta.requiresAuth})
		this.passwordReset()
		this.checkUser()
		this.setDepartments()
	},
	data() {
		return {
			activeTab: 'statuses',
			i18n: i18n,
			currentUserDataIsReady: this.$store.state.currentUserData.data.id,
			tabs: [
				{label: i18n.t('postDrop.tabs.statuses'), value: "statuses"},
				{label: i18n.t('postDrop.tabs.uploadFiles'), value: "uploadFiles"},
				{label: i18n.t('postDrop.tabs.uploadedFiles'), value: "uploadedFiles"},
				{label: i18n.t('postDrop.tabs.validateFile'), value: "validateFile"},
			]
		}
	},
	computed: {
		locale() {
			return this.$store.state.locale
		},
		displayPostDrop() {
			if (this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.generalPost) {
				return this.$store.state.supplierGroup.data.channelsConfig.docImage.configPortal.additionalPortalConfig.generalPost
			}
			return false
		},
		checkResetPassword() {
			return !(newLoginPage &&
				((this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
					|| (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined)))
		},
	},
	watch: {
		locale() {
			this.setTabsTranslation()

			let tooltipsData = [
				{cellName: "upload-files", translationField: 'uploadFiles'},
				{cellName: "user-can-not-upload-files", translationField: 'userCanNotUploadFiles'},
				{cellName: "queued", translationField: 'queued'},
				{cellName: "sent-to-printer", translationField: 'sentToPrinter'},
				{cellName: "printer-processing", translationField: 'printerProcessing'},
				{cellName: "processed-by-printer", translationField: 'processedByPrinter'},
				{cellName: "printed", translationField: 'printed'},
				{cellName: "reprocessedbyprinter", translationField: 'reprocessedByPrinter'},
				{cellName: "resenttoprinter", translationField: 'resentToPrinter'},
				{cellName: "requeued", translationField: 'requeued'},
				{cellName: "printer-returned", translationField: 'printerReturned'},
				{cellName: "printer-error", translationField: 'printerError'},
				{cellName: "printer-returned-handled", translationField: 'printerReturnedHandled'},
				{cellName: "resent-to-printer", translationField: 'resentToPrinter'},
				{cellName: "reprocessed-by-printer", translationField: 'reprocessedByPrinter'},
				{cellName: "printer-returned", translationField: 'printerReturned'},
				{cellName: "start-print", translationField: 'startPrint'},
				{cellName: "disabled-start-print", translationField: 'disabledStartPrint'},
				{cellName: "view-files", translationField: 'viewFiles'},
				{cellName: "delete-batch", translationField: 'deleteBatch'},
				{cellName: "disabled-delete-batch", translationField: 'disabledDeleteBatch'},
			]
			tooltipsData.forEach(tooltip => {
				this.changeTooltipsTranslation(tooltip.cellName, tooltip.translationField)
			})
		}
	},
	methods: {
		setDepartments() {
			let data = {
				pageNo: 1,
				pageSize: 100,
				supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId
			}
			this.$store.dispatch('department/getDepartmentsData', data)
		},
		setTabsTranslation() {
			this.tabs.forEach(tab => {
				let key = 'postDrop.tabs.' + tab.value
				tab.label = i18n.t(key)
			})
		},
		changeTooltipsTranslation(cellName, cellTranslationField) {
			let cellClass = '.' + cellName
			let cells = document.querySelectorAll(cellClass)
			let key = 'tooltips.' + cellTranslationField
			if ((cells.length) !== 0) {
				cells.forEach((cell) => {
					cell.innerHTML = i18n.t(key)
				})
			}
		},
		displayComponent(tab) {
			return tab === this.activeTab
		},
		setActiveTab(tab) {
			this.activeTab = tab
		},
		inactiveTab(tab) {
			return tab !== this.activeTab
		},
		checkUser() {
			if (this.$store.state.currentUserData.data.id) {
				if (!(this.$store.state.supplierGroup.data.channelsConfig?.docImage?.configPortal?.additionalPortalConfig?.generalPost)) {
					this.$router.push({path: routerData.notFound.link})
				}
				if (!((userGroups.supplierNames.includes(store.state.currentUserData.data.group)
						|| (userGroups.optipostNames.includes(store.state.currentUserData.data.group)) || this.$store.state.currentUserData.data.departments)
				)) {
					this.$router.push({path: routerData.noAccess.link})
				}
			}
		},
		passwordReset() {
			if (newLoginPage &&
				((this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.resetPassword && this.$store.state.currentUserData.data.resetPassword === true)
					|| (this.$store.state.currentUserData.data.id && this.$store.state.currentUserData.data.lastLogin === undefined))
			) {
				this.$store.commit("setDisplayResetOwnPassword", true)
			}
		}
	}
}
</script>

<style scoped>
.inactive {
	background-color: var(--theme-primary-100);
	border-color: rgba(85, 85, 85, var(--theme-primary-100));
}

.button-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: end;
}

.button-container-inner {
	display: grid;
	grid-template-columns: repeat(20, auto);
	grid-template-rows: 100%;
}

.custom-button {
	margin-left: 10px;
}

.component-container {
	margin-top: var(--generalPaddings);
	padding-top: var(--generalPaddings);
	border-top: 1px solid var(--theme-primary-50);
}

@media only screen and (max-width: 750px) {
	.button-container > div > div {
		grid-template-columns: 100% !important;
	}
}
</style>