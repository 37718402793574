import {createI18n} from "vue3-i18n";

const en = require('./locales/en.json')
const nl = require('./locales/nl.json')
const fr = require('./locales/fr.json')
const de = require('./locales/de.json')
const es = require('./locales/es.json')
const sk = require('./locales/sk.json')

const messages = {
	en: en,
	nl: nl,
	fr: fr,
	de: de,
	es: es,
	sk: sk
}

const i18n = createI18n({
	locale: "",
	messages: messages,
})

export default i18n
