<template>
	<transition name="dialog">
		<page-container class="page-content">
			<div>
				<top-banner>
					<div class="w-9/12">
						<h1 class="text-3xl text-center">{{ $t('mandateOk.title') }}</h1>
					</div>
				</top-banner>
				<div class="box page-content intro-y box p-5 mt-5">
					<div class="content-container">
						<h1 class="text-center">
							{{ $t('mandateOk.description') }}
						</h1>
					</div>
				</div>
			</div>
		</page-container>
	</transition>
</template>

<script>

import TopBanner from "../components/TopBanner"
import PageContainer from "../components/PageContainer"
import i18n from '../i18n'

export default {
	name: "MandateOk",
	components: {TopBanner, PageContainer},

	data() {
		return {
			i18n: i18n,
		}
	}
}
</script>

<style scoped>

.content-container {
	width: 60%;
	margin: 60px 20%;
}

.text-center {
	text-align: center;
}

.content-container h1 {
	line-height: 2em;
	font-size: 20px;
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
	.content-container {
		width: 80%;
		margin: 40px 10%;
	}

	.content-container h1 {
		line-height: 1.8em;
		font-size: 18px;
	}
}

@media only screen and (min-width: 00px) and (max-width: 599px) {
	.content-container {
		width: 90%;
		margin: 20px 5%;
	}

	.content-container h1 {
		line-height: 1.6em;
		font-size: 16px;
	}
}
</style>