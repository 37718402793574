<template>
	<div class="intro-y">
		<create-department/>
		<edit-department/>
		<delete-department/>
		<add-department-user/>
		<delete-department-user/>
		<view-department-users/>
		<department-upload-files/>
		<div class="buttons-container">
			<div>
				<button
					type="button"
					class="btn custom-button w-full shadow-md"
					@click="createDepartment"
					v-if="displayButton()"
				>
					{{ $t('buttons.createDepartment') }}
				</button>
			</div>
		</div>

		<div v-if="this.$store.state.supplierGroup.data.supplierGroupId">
			<departments-table
				:supplierGroupId="this.$store.state.supplierGroup.data.supplierGroupId"
				:requestUrl="this.requestUrl"
			/>
		</div>
	</div>
</template>

<script>
import CreateDepartment from "./CreateDepartment"
import EditDepartment from "./EditDepartment"
import DepartmentsTable from "./DepartmentsTable"
import DeleteDepartment from "./DeleteDepartment"
import AddDepartmentUser from "./AddDepartmentUser"
import DeleteDepartmentUser from "./DeleteDepartmentUser"
import ViewDepartmentUsers from "./ViewDepartmentUsers"
import DepartmentUploadFiles from "./DepartmentUploadFiles"
import userGroups from "../../../userGoups"

export default {
	components: {
		ViewDepartmentUsers,
		DeleteDepartmentUser,
		AddDepartmentUser,
		DeleteDepartment,
		CreateDepartment,
		DepartmentsTable,
		EditDepartment,
		DepartmentUploadFiles
	},
	mounted() {
		this.$store.dispatch("users/fetchSupplierUsers")
	},
	data() {
		return {
			requestUrl: this.$store.state.domainName + this.$store.state.API.departments,
		}
	},
	methods: {
		displayButton() {
			return userGroups.supplierNames.includes(this.$store.state.currentUserData.data.group)
				|| userGroups.optipostNames.includes(this.$store.state.currentUserData.data.group)
		},
		createDepartment() {
			this.$store.dispatch("updateToken")
			this.$store.commit("setCreateDepartment", true)
		}
	}
}
</script>

<style scoped>
.buttons-container {
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: 100%;
	justify-items: end;
	margin-bottom: 10px;
}
</style>