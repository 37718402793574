<template>
	<div>cancel crate</div>
</template>

<script>
export default {
	name: "CancelMandateCreate",
	created() {
		this.getRedirectPageData()
		this.redirectToOnboarding()
	},
	data() {
		return {
			lang: '',
			accessCode: ''
		}
	},
	methods: {
		getRedirectPageData() {

			this.lang = window.location.href.split('/')[3]
			let accessCodeTemp = window.location.href.split('/')[4]
			this.accessCode = accessCodeTemp.split('#')[0]
		},
		redirectToOnboarding() {
			let url = '/' + this.lang + "/onboarding/" + this.accessCode
			this.$router.push(url)
		}
	}
}
</script>

<style scoped>

.content-container h1 {
	line-height: 2em;
	font-size: 20px;
}

@media only screen and (min-width: 600px) and (max-width: 900px) {
	.content-container h1 {
		line-height: 1.8em;
		font-size: 18px;
	}
}

@media only screen and (min-width: 00px) and (max-width: 599px) {
	.content-container h1 {
		line-height: 1.6em;
		font-size: 16px;
	}
}
</style>