<template>
	<pop-up-window
		:show="this.$store.state.mandates.displayEditMandate && this.$store.state.mandates.mandateData.mandateId"
		:showClose=false
		class="doc-statuses-popup"
		:stick-to-scroll-position="true"
	>
		<template #title>
			<h1 class="text-2xl">{{ $t('management.mandates.editMandate.title') }}</h1>
		</template>
		<template #content>
			<p class="text-center mb-4" style="font-size: 18px">
				{{ this.$store.state.mandates.mandateData.mandateId }}
			</p>
			<div>
				<div class="one-item">
					<label for="email">{{ $t('general.email') }}:</label>
					<input
						class="form-control text-popup"
						id="email"
						v-model="email"
						@click="setError('email', false)"
						:placeholder="emailPlaceholder"
					/>
				</div>
				<p class="error" v-if="errors.email">{{ $t('errors.correctEmail') }}</p>
				<div class="one-item one-item-iban">
					<label for="iban">{{ $t('management.mandates.editMandate.iban') }}:</label>
					<input
						class="form-control text-popup"
						id="iban"
						v-model="iban"
						@click="setError('iban', false)"
						@keyup='formatIban($event)'
						:placeholder="ibanPlaceholder"
					/>
					<div class="retrieve-bic">
						<button
							class="btn custom-button shadow-md mr-2 showStatuses text-popup "
							:disabled="disableRetrieveBic"
							@click="retrieveBic"
						>
							{{ $t('buttons.retriveBic') }}
						</button>
					</div>
				</div>

				<p class="error" v-if="errors.iban">{{ $t('errors.iban') }}</p>
			</div>
			<div class="one-item">
				<label for="bic">{{ $t('management.mandates.bankAccountInfo.bic') }}:</label>
				<input class="form-control text-popup"
					   id="bic"
					   v-model="bic"
					   :placeholder="bicPlaceholder"
				/>
			</div>
			<div class="flex flex-row justify-center my-6 grey-top-border">
				<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="close">
					{{ $t('buttons.cancel') }}
				</button>
				<button
					class="btn custom-button shadow-md mr-2 showStatuses text-popup"
					:disabled='disableSave'
					@click="editMandate"
				>
					{{ $t('buttons.edit') }}
				</button>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow"
const IBAN = require('iban')

export default {
	name: "EditMandate",
	components: {PopUpWindow},
	emits: ['updatedMandate'],
	data() {
		return {
			email: '',
			iban: '',
			bic: '',
			emailPlaceholder: '',
			ibanPlaceholder: '',
			bicPlaceholder: '',
			IBAN: IBAN,
			ibanIsCorrect: false,
			errors: {
				email: false,
				iban: false,
				bic: false,
			},
			bicRetrieved: false,
		}
	},
	watch: {
		ibanIsCheck(newVal) {
			if (newVal) {
				if (this.$store.state.iban.data.valid !== undefined) {
					if (this.$store.state.iban.data.valid === false) {
						this.setError('iban', true);
						this.ibanIsCorrect = false;
						this.bic = this.$store.state.iban.data.bankData.bic
					} else {
						this.ibanIsCorrect = true;
						this.bic = this.$store.state.iban.data.bankData.bic
					}
				}
			}
		},
		mandateData(newVal) {
			if (newVal.mandateId) {
				this.ibanPlaceholder = ''
				this.emailPlaceholder = newVal.receiverEmail;
				this.bicPlaceholder = newVal.bicCode;
				let ibanArray = newVal.bankAccount.split('')

				ibanArray[3] += ' '
				ibanArray[7] += ' '
				ibanArray[11] += ' '
				this.ibanPlaceholder = ibanArray.join('')
			}
		}
	},
	computed: {
		disableRetrieveBic() {
			return this.iban === ''
		},
		disableSave() {
			if ((this.iban === '' && this.email === '')) {
				return true
			}
			return !this.ibanIsCorrect && this.iban !== ''
		},
		ibanIsCheck() {
			return this.$store.state.iban.isChecked
		},
		mandateData() {
			return this.$store.state.mandates.mandateData
		}
	},
	methods: {
		retrieveBic() {
			this.$store.commit("ibanIsChecked", {ibanData: {}, isChecked: false})
			this.$store.dispatch("retrieveBic", this.iban)
		},
		formatIban(event) {
			let iban = this.iban
			iban = iban.replaceAll(".", '').replaceAll(' ', "")
			if (iban.length >= 4 && iban.length <= 16) {
				let ibanArray = iban.split("")
				if (event.key !== 'Backspace') {
					for (let i = 1; i < 4; i++) {
						if (ibanArray[4 * i - 1] !== undefined) {
							ibanArray[4 * i - 1] = ibanArray[4 * i - 1] + ' '
						}
					}
					this.iban = ibanArray.join("")
				} else {
					this.iban = this.iban.trim()
				}
			}
			if (iban.length > 16) {
				let iban = this.iban.slice(0, 19)
				this.iban = iban
			}
		},
		setError(field, value) {
			this.errors[field] = value
		},
		async editMandate() {
			let formData = {}
			if (this.email !== '') {
				if (!(this.validateEmail(this.email))) {
					this.setError('email', true)
					return
				} else {
					formData.email = this.email
				}
			}
			let iban = this.iban.replaceAll(' ', '')
			let ibanIsValid = this.ibanIsCorrect
			if (this.iban !== '') {
				if (!(ibanIsValid)) {
					this.setError('iban', true)
					return
				} else {
					formData.iban = iban
					formData.bicCode = this.bic
				}
			}
			await this.$store.dispatch('editMandate', {
				requestData: formData,
				entityId: this.$store.state.mandates.mandateData.legalEntityIds[0],
				clientId: this.$store.state.mandates.mandateData.receiverId,
				mandateId: this.$store.state.mandates.mandateData.mandateId
			})
			this.$emit('updatedMandate')
			this.close()
		},
		validateEmail(email) {
			const re = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/)
			return re.test(email)
		},
		close() {
			this.$store.commit('displayEditMandate', false)
			this.setError('email', false)
			this.setError('iban', false)
			this.setError('bic', false)
			this.email = ''
			this.iban = ''
			this.bic = ''
			this.ibanPlaceholder = ''
			this.emailPlaceholder = ''
			this.bicPlaceholder = ''
			this.$store.commit("ibanIsChecked", {ibanData: {}, isChecked: false})
			this.$store.commit('setMandateData', {})
		},
	}
}
</script>

<style scoped>
.one-item {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 80px auto;
	margin-bottom: 10px;
	align-items: center;
}

.one-item-iban {
	grid-template-columns: 80px auto 120px;
}

.retrieve-bic {
	justify-self: end;
}
</style>
