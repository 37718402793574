<template>
	<pop-up-window
		:show="this.$store.state.postDrop.displayDepartmentUploadFiles"
		:showClose=false
		class="doc-statuses-popup"
		:positionFixed=true
		:top0=true
		fixed
	>
		<template #title>
			<h1 class="text-2xl">{{ $t('postDrop.departmentUploadFilesTitle') }}</h1>
			<h1>{{ name }}</h1>
		</template>
		<template #content>
			<div class="one-line">
				<p>{{ $t('postDrop.addAttachment') }}</p>
				<div>
					<input
						type="file"
						multiple="multiple"
						ref="file"
						class="form-control text-popup "
						@change="uploadFile"
						@click="setErrors('files', false)"
					/>
				</div>
			</div>

			<div class="mt-2">
				<p class="error" v-if="errors.files">{{ $t('errors.attachFileIsEmpty') }}</p>
			</div>
			<div class="one-line">
				<p>{{ $t('postDrop.selectPrintJob') }}:</p>

				<v-select
					@click="setErrors('printJob' , false)"
					v-model="printJob"
					:options=this.printJobOptions
					:clearable="false"
				>
					<template #no-options="{search}">
						{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
					</template>
				</v-select>
			</div>
			<div class="mt-2">
				<p class="error" v-if="errors.printJob">{{ $t('errors.attachFileIsEmpty') }}</p>
			</div>
			<div class="one-line">
				<p>{{ $t('postDrop.postDropType') }}</p>
				<v-select
					v-model="postalType"
					:options='options'
					:clearable='false'
				>
					<template #no-options="{search}">
						{{ $t('placeholders.noResultFound') }} <span class="info">{{ search }}</span>
					</template>
				</v-select>
			</div>
			<div class="grid grid-rows-1 justify-items-center my-6">
				<div>
					<button
						class="btn custom-button shadow-md mr-2 showStatuses text-popup"
						@click="uploadDepartmentFiles"
					>
						{{ $t('buttons.upload') }}
					</button>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="close">
						{{ $t('buttons.cancel') }}
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>

</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow"
import i18n from "../../../i18n"

export default {
	name: "DepartmentUploadFiles",
	components: {PopUpWindow},
	data() {
		return {
			i18n: i18n,
			departmentId: '',
			name: "",
			departmentCode: '',
			errors: {
				printJob: false,
				files: false,
			},
			files: [],
			printJob: {label: "", value: ""},
			postalType: {label: 'Regular', value: 'REGULAR'},
			options: [
				{label: 'Regular', value: 'REGULAR'},
				{label: 'Registered', value: 'REGISTERED'},
			]
		}
	},
	computed: {
		departmentData() {
			return this.$store.state.postDrop.departmentData
		},
		printJobOptions() {
			let printJobOptions = []
			if (this.$store.state.supplierGroup.data.channelsConfig?.print?.printJobs) {
				this.$store.state.supplierGroup.data.channelsConfig.print.printJobs.forEach(job => {
					let tempItem = {
						label: job.name,
						value: job.name
					}
					printJobOptions.push(tempItem)
				})
			} else {
				printJobOptions.push({
					label: i18n.t('general.noPrintJob'),
					value: ''
				})
			}
			return printJobOptions
		}
	},
	watch: {
		departmentData(newVal) {
			this.setDepartmentData(newVal)
		}
	},
	methods: {
		uploadFile() {
			this.files = this.$refs.file.files
		},
		setErrors(field, value) {
			this.errors[field] = value
		},
		uploadDepartmentFiles() {
			if (this.files.length === 0) {
				this.setErrors('files', true)
				return
			}
			if (this.printJob.value === "") {
				this.setErrors('printJob', true)
				return
			}
			const formData = new FormData()

			for (let i = 0; i < this.files.length; i++) {
				formData.append('file', this.files[i])
			}
			this.$store.dispatch('departmentUploadFiles', {
				filesToSend: formData,
				departmentCode: this.departmentCode,
				printJob: this.printJob.value,
				postalType: this.postalType.value
			})
			this.close()
		},
		setDepartmentData(data) {
			if (data.departmentId) {
				this.departmentId = data.departmentId
				this.name = data.departmentName
				this.departmentCode = data.code
			}
		},
		close() {
			this.$store.commit('displayDepartmentUploadFiles', false)
			this.departmentId = ''
			this.departmentCode = ''
			this.name = "";
			this.$store.commit('setDepartmentData', {})
			this.$store.commit("setUsersDataForPostDrop", [])
			this.setErrors('files', false)
			this.setErrors('printJob', false)
			this.files = []
			this.printJob = {label: "", value: ""}
			this.postalType = {label: 'Regular', value: 'REGULAR'}
		}
	}
}
</script>

<style scoped>
.one-line {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 140px auto;
	align-items: center;
	margin-top: 10px;
}
</style>