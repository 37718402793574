<template>
	<div class="footer" v-if="displayComponentAfterSamlRedirect">
		<div class="footer-inner " v-html="brandSettings.contactText"></div>
		<div class="footer-img">
			<a target="_blank" href="https://nymus.be">
				<img src="../assets/images/projectImages/nymus_logo.png" alt="Optipost Logo">
			</a>
		</div>
	</div>
</template>
<script>
export default {
	name: "TheFooter",
	computed: {
		displayComponentAfterSamlRedirect() {
			return !(this.$store.state.route.from === 'saml' && this.$store.state.route.to === "overview");
		},
		brandSettings() {
			return this.$store.state.supplierGroup.branding;
		}
	}
}
</script>

<style scoped>
.footer {
	min-height: var(--footerHeight);
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: calc(100% - 180px) 180px;
	grid-auto-flow: row;
	justify-items: center;
	padding: var(--generalPaddings);
	margin-top: var(--generalPaddings);
	border-top: 1px solid var(--theme-primary-100);
}

.footer-inner {
	display: grid;
	grid-template-rows: repeat(10, auto);
	grid-template-columns: 100%;
	grid-auto-flow: row;
	grid-column-gap: var(--generalPaddings);
	font-size: 0.8rem;
	line-height: 1.4rem;
}

.title {
	font-weight: bolder;
}

.footer-img {
	background-color: white;
}

@media only screen and (max-width: 950px) {
	.footer {
		grid-template-rows: calc(100% - 40px) 40px;
		grid-template-columns: 100%;
		grid-auto-flow: row;
	}

	.footer-inner {
		justify-items: center;
		text-align: center;
	}

	.footer-img {
		align-self: center;
	}

	.footer-img img {
		width: 220px;
	}
}

@media only screen and (max-width: 600px) {
	.footer-inner {
		grid-template-rows: auto auto auto;
		grid-template-columns: 100%;
		justify-content: center;
	}
}

>>> span {
	font-size: 13px;
}

>>> .extra-margin {
	margin-right: 5px;
}

>>> a {
	font-size: 13px;
}

>>> div > div > div {
	display: flex;
	flex-direction: row;
	align-items: center;
}

#container {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	font-size: 13px;
}
</style>