<template>
	<div class="error-page">
		<top-banner>
			<div class="w-9/12">
				<h1 class="text-3xl text-center">{{ $t('errorPage.title') }}</h1>
			</div>
		</top-banner>
		<div class="box error-page-content">
			<h1 class="text-3xl text-center">{{ $t('errorPage.text') }}</h1>
		</div>
	</div>
</template>

<script>
import TopBanner from "../components/TopBanner"

export default {
	name: "ErrorPage",
	components: {TopBanner}
}
</script>

<style scoped>
.error-page {
	min-height: calc(100vh - var(--headerheight) - var(--footerHeight) - var(--generalPaddings));
	display: grid;
	grid-template-columns: 100%;
	grid-template-rows: var(--headerheight) calc(100% - var(--headerheight));
	grid-auto-flow: column;
}

.error-page-content {
	margin: var(--generalPaddings) 0;
	padding: calc(2 * var(--generalPaddings));
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	grid-auto-flow: column;
}

</style>