<template>
	<div>
		<error-notification/>
		<!-- BEGIN: HTML Table Data -->
		<div id="tabulatorOuter">
			<div class="tabulator-buttons-container">
				<div class="extended-search">
					<input
						type='text'
						v-model="filter.value"
						@keyup="quickSearch"
						:placeholder="$t('placeholders.search')"
						class="form-control mr-1.5"
					/>
					<div class="flex flex-row add-margin-small-screen">
						<extended-search-filter
							current-page="departments"
							:two-columns="false"
							v-if="this.$store.state.supplierUsersList.length"
							:margin-left-small-screen="8"
							@search="onFilter"
							@reset-filter="cleanExtendedSearch"
							class="mr-1.5"
						/>

						<button
							class="btn custom-button shadow-md mr-2 showStatuses text-popup"
							@click="cleanQuickSearch"
						>
							{{ $t('buttons.reset') }}
						</button>
					</div>
				</div>
			</div>
			<filter-label @remove-label="onFilter"/>
			<div class="totalNumberDocuments">{{ $t('table.totalNumberDepartments') }}: {{ totalDocuments }}</div>

			<div class="overflow-x-auto scrollbar-hidden test">
				<div
					id="tabulator"
					ref="tableRef"
					class="mt-5 table-report table-report--tabulator"
				/>
			</div>
		</div>
		<!-- END: HTML Table Data -->
	</div>
</template>

<script>
import {computed, defineComponent, onMounted, reactive, ref, watch} from 'vue'
import feather from 'feather-icons'
import {TabulatorFull as Tabulator} from 'tabulator-tables'
import {useStore} from 'vuex'
import i18n from "../../../i18n"
import ErrorNotification from "../../UI/ErrorNotification.vue"
import cash from "cash-dom"
import en from "../../../locales/en.json"
import nl from "../../../locales/nl.json"
import de from "../../../locales/de.json"
import fr from "../../../locales/fr.json"
import sk from "../../../locales/sk.json"
import es from "../../../locales/es.json"
import axios from 'axios'
import qs from "qs"
import userGroups from "../../../userGoups"
import ExtendedSearchFilter from "../../extendedSearch/ExtendedSearchFilter.vue"
import FilterLabel from "../../extendedSearch/FilterLabel.vue"

const env = window.environment

export default defineComponent({
	components: {FilterLabel, ExtendedSearchFilter, ErrorNotification},
	emits: ['update:modelValue'],
	props: {
		token: String,
		supplierGroupId: String,
		requestUrl: String,
		modelValue: String,
	},
	setup(props) {
		const store = useStore()

		const requestUrl = ref(props.requestUrl)
		const supplierGroupId = ref(props.supplierGroupId)
		const totalDocuments = ref('')
		const searchTableValue = ref('')
		const tableIsLoading = ref(false)

		const tableRef = ref()
		const tabulator = ref()
		const pageSize = ref(10)
		const filter = reactive({
			value: searchTableValue
		})
		let tableItems = ref(0)
		let tableColumns = computed(() => {
			let columns = []

			if ((userGroups.supplierNames.includes(store.state.currentUserData.data.group)
				|| userGroups.optipostNames.includes(store.state.currentUserData.data.group))) {
				columns = [
					{
						title: 'DEPARTMENT CODE',
						field: 'departmentCode',
						cssClass: "text-centered",
						headerSort: true,
						minWidth: 460,
						formatter(cell) {
							const a = cash(
								`<div  class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center cell-style">
                          					<div>
                          						${cell.getData().code}
                        					</div>
                    					</div>`
							)
							return a[0]
						}
					},
					{
						title: 'DEPARTMENT NAME',
						field: 'departmentName',
						cssClass: "text-centered",
						headerSort: true,
						minWidth: '400',
						formatter(cell) {
							const a = cash(
								`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-center">
                                     		<div>${cell.getData().departmentName}</div>
                                		</div>`
							)
							return a[0]
						}
					},
					{
						title: 'VIEW DEPARTMENT USER',
						field: 'viewDepartmentUser',
						width: "60",
						cssClass: "text-centered color-grey",
						headerSort: false,
						formatter(cell) {
							if (cell.getData().allData.users && cell.getData().allData.users.length) {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start">
                                             				<div class="tooltip-outer">
                                                 				<i class="icon-eye icon-color font-16"></i>
                                                  				<span class="tooltip-inner view-department-users">
																	${i18n.t('tooltips.viewDepartmentUsers')}
																</span>
                                            				</div>
														</div> `)
								cash(a).on('click', function () {
									store.commit('setPageYScrollPosition', window.pageYOffset)
									store.commit('setDepartmentData', cell.getData().allData)
									store.commit('displayViewDepartmentUser', true)
								})
								return a[0]
							} else {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start">
                                             				<div class="tooltip-outer">
                                                 				<i class="icon-eye icon-color icon-color-disable font-16"></i>
                                                  				<span class="tooltip-inner  no-department-users">
																	${i18n.t('tooltips.noDepartmentUsers')}
																</span>
                                            				</div>
														</div> `)
								return a[0]
							}
						}
					},
					{
						title: 'ADD DEPARTMENT USER',
						field: 'addDepartmentUser',
						width: "60",
						cssClass: "text-centered color-grey",
						headerSort: false,
						formatter(cell) {
							const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start">
                                             			<div class="tooltip-outer">
                                                 			<i class="icon-user-plus1 icon-color font-16"></i>
                                                  			<span class="tooltip-inner add-user add-department-user">
																${i18n.t('tooltips.addDepartmentUser')}
															</span>
                                            			</div>
													</div> `)
							cash(a).on('click', function () {
								store.dispatch("updateToken")
								store.commit('setPageYScrollPosition', window.pageYOffset)
								store.commit('setDepartmentData', cell.getData().allData)
								store.commit('displayAddDepartmentUser', true)
							})
							return a[0]
						}
					},
					{
						title: 'DELETE DEPARTMENT USER',
						field: 'deleteDepartmentUser',
						width: "60",
						cssClass: "text-centered color-grey",
						headerSort: false,
						formatter(cell) {
							if (cell.getData().allData.users && cell.getData().allData.users.length) {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start">
                                             				<div class="tooltip-outer">
                                                 				<i class="icon-user-minus icon-color font-16"></i>
                                                  				<span class="tooltip-inner delete-department-users">
																	${i18n.t('tooltips.deleteDepartmentUsers')}
																</span>
                                            				</div>
														</div>`)
								cash(a).on('click', function () {
									store.dispatch("updateToken")
									store.commit('setPageYScrollPosition', window.pageYOffset)
									store.commit('setDepartmentData', cell.getData().allData)
									store.commit('displayDeleteDepartmentUser', true)
								})
								return a[0]
							} else {
								const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start">
                                             				<div class="tooltip-outer">
                                                 				<i class="icon-user-minus icon-color icon-color-disable font-16"></i>
                                                  				<span class="tooltip-inner  no-department-users">
																	${i18n.t('tooltips.noDepartmentUsers')}
																</span>
                                            				</div>
														</div>`)
								return a[0]
							}
						}
					},
					{
						title: 'EDIT DEPARTMENT',
						field: 'editDepartment',
						width: "60",
						cssClass: "text-centered color-grey",
						headerSort: false,
						formatter(cell) {
							const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start">
                                             			<div class="tooltip-outer">
                                                 			<i class="icon-pencil  icon-color "></i>
                                                  			<span class="tooltip-inner edit-department">
																${i18n.t('tooltips.editDepartment')}
															</span>
                                            			</div>
													</div> `)
							cash(a).on('click', function () {
								store.dispatch("updateToken")
								store.commit('setPageYScrollPosition', window.pageYOffset)
								store.commit('setDepartmentData', cell.getData().allData)
								store.commit('displayEditDepartment', true)
							})
							return a[0]
						}
					},
					{
						title: 'DELETE DEPARTMENT',
						field: 'deleteDepartment',
						width: "60",
						cssClass: "text-centered color-grey",
						headerSort: false,
						formatter(cell) {
							const a = cash(`<div class="font-medium whitespace-nowrap grid grid-rows-1 justify-items-start ">
                                             			<div class="tooltip-outer">
                                                 			<i class="icon-bin  icon-color "></i>
                                                  			<span class="tooltip-inner delete-department">${i18n.t('tooltips.deleteDepartment')}</span>
                                            			</div>
													</div> `)
							cash(a).on('click', function () {
								store.dispatch("updateToken")
								store.commit('setPageYScrollPosition', window.pageYOffset)
								store.commit('setDepartmentData', cell.getData().allData)
								store.commit('displayDeleteDepartment', true)
							})
							return a[0]
						}
					}
				]
			}
			return columns
		})

		watch(() => store.state.postDrop.departmentsUpdated, (newVal) => {
			if (newVal) {
				setTimeout(() => {
					initTabulator()
					store.commit("departmentsUpdated", false)
				}, 1000)
			}
		})

		watch(() => store.getters.getLocale, function () {
			tabulator.value.setLocale(store.getters.getLocale)
		})

		const initTabulator = () => {
			tabulator.value = new Tabulator(tableRef.value)

			let params = {
				supplierGroupId: supplierGroupId.value,
				pageNo: 1,
				pageSize: pageSize.value,
				quick: filter.value,
			}

			if (store.state.extendedSearchValue.extendedSearch.userId && store.state.extendedSearchValue.extendedSearch.userId.value !== '') {
				params.userId = store.state.extendedSearchValue.extendedSearch.userId.value
			}

			if (filter.value === '') {
				delete params.quick
			}
			tabulator.value = new Tabulator(tableRef.value, {
				ajaxURL: requestUrl.value,
				ajaxConfig: {
					method: "POST", //set request type to Position
					headers: {
						'Authorization': 'bearer ' + store.state.token,
						'Accept': 'application/json',
					},
				},
				ajaxParams: params,
				ajaxResponse: function (url, params, response) {
					let data = []
					const res = response.data.items

					totalDocuments.value = response.data.numResults
					tableItems.value = response.data.items.length

					for (let item of res) {
						let tempItem = {
							code: item.code,
							departmentId: item.departmentId,
							departmentName: item.departmentName,
							supplierGroupId: item.supplierGroupId,
							users: item.users,
							allData: item,
						}
						data.push(tempItem)
					}

					let last_page = Math.ceil(response.data.numResults / pageSize.value)
					return {
						last_page: last_page,
						data: data,
					}
				},
				dataSendParams: {
					page: "pageNo",
					size: "page_size",
					filter: "quick"
				},
				ajaxURLGenerator: function (url) {
					updateToken()
					return url
				},
				headerSortElement: "<span><i class='noSorting'></i><i class='arrow-up'></i> <i class='arrow-down'></i></span>",
				dataLoaderLoading: `<div id="textLoading" style='font-size:15px'></div>`,
				ajaxContentType: "json",
				ajaxFiltering: true,
				printAsHtml: true,
				printStyled: true,
				pagination: true,
				paginationMode: 'remote',
				paginationSize: pageSize.value,
				paginationInitialPage: store.state.tableInitialPage.mandates,
				layout: 'fitColumns',
				responsiveLayout: 'collapse',
				placeholder: i18n.t('placeholders.noMatchingFound'),
				locale: true,
				langs: {
					"en": en,
					"nl": nl,
					"de": de,
					"fr": fr,
					"sk": sk,
					"es": es,
				},
				columns: tableColumns.value,
			})
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
				tableIsLoading.value = false
			})
			tabulator.value.on('dataLoading', () => {
				tableIsLoading.value = true
			})
		}
		watch(tableIsLoading, () => {
			if (tableIsLoading.value) {
				const loading = document.getElementById('textLoading')
				if (loading !== null) loading.innerText = i18n.t('placeholders.loading')
			}
		})
		const tokenIsExpired = () => {
			let exp

			if (JSON.parse(atob(store.state.token.split('.')[1])).exp) {
				exp = parseInt(JSON.parse(atob(store.state.token.split('.')[1])).exp)
			}
			let now = parseInt(new Date().getTime() / 1000)
			return exp - now <= 0
		}
		const updateToken = () => {
			if (tokenIsExpired()) {
				let payload = {
					client_id: env.keycloakClient,
					grant_type: "refresh_token",
					refresh_token: store.state.refreshToken
				}

				let body = qs.stringify(payload)
				axios.post(store.state.API.keycloakToken, body, {
						headers: {
							'Content-Type': "application/x-www-form-urlencoded",
						}
					}
				).then(res => {
					store.commit('setToken', res.data.access_token)
					store.commit('setRefreshToken', res.data.refresh_token)
					initTabulator()
				}).catch(() => {
					store.commit("setGeneralNotificationText", {field: "sessionExpiredText", text: "Ok"})
					store.commit('displayNotification', true);
				})
			}
		}

		const cleanQuickSearch = () => {
			store.state.extendedSearchValue.extendedSearch = {}
			filter.value = ''
			onFilter()
		}
		const setLocale = () => {
			tabulator.value.on('dataLoaded', () => {
				tabulator.value.setLocale(store.getters.getLocale)
			})
		}

		const reInitOnResizeWindow = () => {
			window.addEventListener('resize', () => {
				tabulator.value.redraw();
				feather.replace({
					'stroke-width': 1.5
				})
			})
		}

		const quickSearch = () => {
			store.commit("setMandateTableSearch", filter.value)
			store.commit("setTablePage", {field: "mandates", pageNo: 1})
			onFilter()
		}

		const onFilter = () => {
			initTabulator()
		}

		const cleanExtendedSearch = () => {
			store.state.extendedSearchValue.extendedSearch.userId = {label: 'postDrop.selectUser', value: ''}
			onFilter()
		}

		onMounted(() => {
			initTabulator()
			reInitOnResizeWindow()
			setLocale()
		})

		return {
			tableRef,
			filter,
			onFilter,
			totalDocuments,
			quickSearch,
			tabulator,
			cleanQuickSearch,
			tableItems,
			cleanExtendedSearch
		}
	}
})
</script>

<style scoped>
.extended-search {
	display: flex;
	flex-direction: row;
	width: 400px;
	height: 37px;
}

.leg-entity-container img {
	padding-right: var(--generalPaddings);
}

.leg-entity-container .table-report img {
	border: none;
	box-shadow: none !important;
}

.totalNumberDocuments {
	display: grid;
	grid-template-rows: 100%;
	grid-template-columns: 100%;
	justify-items: end;
	padding-top: var(--generalPaddings);
}

.tabulator-buttons-container {
	display: grid;
	grid-template-columns: 90% 10%;
	grid-auto-flow: column;
	align-items: center;
}

.arrow-up:after {
	content: ' \2191';
}

.arrow-down:after {
	content: ' \2193';
}

/* Hide all icons by default */
.tabulator-col .tabulator-col-sorter i {
	display: none;
}

/* Display the fa-sort icon when the column is not sorted */
.tabulator-col[aria-sort="none"] .tabulator-col-sorter i.no-sorting {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-up icon when the column is sorted in ascending order */
.tabulator-col[aria-sort="asc"] .tabulator-col-sorter i.arrow-up {
	display: inline-block;
	color: var(--theme-primary-100);
}

/* Display the fa-sort-down icon when the column is sorted in descending order */
.tabulator-col[aria-sort="desc"] .tabulator-col-sorter i.arrow-down {
	display: inline-block;
	color: var(--theme-primary-100);
}

.download-outer button {
	margin-left: 5px;
}

.download-buttons .btn span {
	grid-column: 1/2;
	grid-row: 1/2;
}

.extended-search-check-box span {
	margin-right: 10px;
	position: relative;
	top: -5px;
}

@media only screen and (max-width: 739px) {
	.extended-search {
		display: flex;
		flex-direction: column;
	}

	.add-margin-small-screen {
		margin-top: 5px
	}
}
</style>