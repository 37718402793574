<template>
	<pop-up-window
		:show="this.$store.state.postDrop.displayViewDepartmentUser"
		:showClose=false
		class="doc-statuses-popup"
		additional-info=""
		:positionFixed="true"
		:top10="true"
		:stickToScrollPosition="true"
		:bottomDiv="false"
	>
		<template #title>
			<h1 class="text-2xl">{{ $t('postDrop.viewDepartmentUserTitle') }}</h1>
			<h1>{{ name }}</h1>
		</template>
		<template #content>
			<div class="department-users">
				<div class="user-item" v-for="(user) in departmentUsers" :key="user.id">
					<span>- &nbsp;{{ user.email }}</span>
				</div>
			</div>
			<div class="grid grid-rows-1 justify-items-center grey-line">
				<div>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="close">
						{{ $t('buttons.close') }}
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow"

export default {
	name: "ViewDepartmentUsers",
	components: {PopUpWindow},
	data() {
		return {
			departmentId: '',
			name: "",
			departmentUsers: [],
		}
	},
	computed: {
		departmentData() {
			return this.$store.state.postDrop.departmentData
		},
	},
	watch: {
		departmentData(newVal) {
			this.setDepartmentData(newVal)
		}
	},
	methods: {
		setDepartmentData(data) {
			if (data.departmentId) {
				this.departmentId = data.departmentId
				this.name = data.departmentName
				this.departmentUsers = data.users
			}
		},
		close() {
			this.$store.commit('displayViewDepartmentUser', false)
			this.departmentId = ''
			this.departmentUsers = []
			this.name = ""
			this.$store.commit('setDepartmentData', {})
			this.$store.commit("setUsersDataForPostDrop", [])
		}
	}
}
</script>

<style scoped>
.department-users {
	display: grid;
	grid-template-columns: 50% 50%;
	grid-template-rows: auto auto auto;
	grid-auto-flow: row;
}

.user-item {
	padding-bottom: 4px;
	overflow: hidden;
}

.user-item:nth-child(odd) {
	margin-right: 4px;
}

.user-item:nth-child(even) {
	margin-left: 4px;
}

@media only screen and  (max-width: 750px) {
	.department-users {
		grid-template-columns: 100%;
	}

	.user-item:nth-child(odd) {
		margin-right: 0;
	}

	.user-item:nth-child(even) {
		margin-left: 0;
	}
}
</style>