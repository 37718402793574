<template>
	<transition name="dialog">
		<div v-if="load">
			<page-container class="page-content">
				<tabulator
					:supplierGroupId="this.supplierGroupId"
					:general="this.docTypeGeneral"
					:token="this.$root.token"
					:requestUrl="this.requestUrl"
				/>
			</page-container>
		</div>
	</transition>
</template>

<script>
import PageContainer from "../../PageContainer.vue"
import Tabulator from './UsersTable.vue'

export default {
	name: "ManageUserContent",
	components: {PageContainer, Tabulator},
	beforeMount() {
		this.$store.dispatch("updateToken")
	},
	created() {
		this.watchSupplierGroupId()
		this.load = true
	},
	data() {
		return {
			supplierGroupId: '',
			docTypeGeneral: false,
			requestUrl: this.$store.state.domainName + this.$store.state.API.getUsers,
			load: false
		}
	},
	methods: {
		watchSupplierGroupId() {
			if (this.$store.state.supplierGroup.data.sgType === "GENERAL") {
				this.docTypeGeneral = true;
			}
			this.supplierGroupId = this.$store.state.supplierGroup.data.supplierGroupId
		}
	}
}
</script>