import i18n from "@/i18n"

export default function getStatusBar(printStatuses, errorsHandled) {
	// let statusBar
	if (printStatuses.length === 1 && printStatuses.includes('queued')) {
		return '<div class="tooltip-outer">'
			+ '<div class="status-bar status-bar-info">'
			+ '<div class="width-25 status-bar-outer">'
			+ '</div>'
			+ '</div>'
			+ '<span class="tooltip-inner tooltip-ml-0 queued">'
			+ i18n.t("tooltips.queued")
			+ '</span>'
			+ '</div>'
	}

	if (printStatuses.length === 2 && printStatuses.includes('queued') && printStatuses.includes('senttoprinter')) {

		return '<div class="tooltip-outer">'
			+ '<div class="status-bar status-bar-info">'
			+ '<div class="width-50 status-bar-outer">'
			+ '</div>'
			+ '</div>'
			+ '<span class="tooltip-inner tooltip-ml-0 sent-to-printer">'
			+ i18n.t("tooltips.sentToPrinter")
			+ '</span>'
			+ '</div>'
	}

	if (printStatuses.length === 3 && printStatuses.includes('queued') && printStatuses.includes('senttoprinter') && printStatuses.includes('printerprocessing')) {
		return '<div class="tooltip-outer">'
			+ '<div class="status-bar status-bar-info">'
			+ '<div class="width-75 status-bar-outer">'
			+ '</div>'
			+ '</div>'
			+ '<span class="tooltip-inner tooltip-ml-0 printer-processing">'
			+ i18n.t("tooltips.printerProcessing")
			+ '</span>'
			+ '</div>'
	}
	if (printStatuses.length === 3 && printStatuses.includes('queued') && printStatuses.includes('senttoprinter') && printStatuses.includes('processedbyprinter')) {

		return '<div class="tooltip-outer">'
			+ '<div class="status-bar status-bar-info">'
			+ '<div class="width-100 status-bar-outer">'
			+ '</div>'
			+ '</div>'
			+ '<span class="tooltip-inner tooltip-ml-0 processed-by-printer">'
			+ i18n.t("tooltips.processedByPrinter")
			+ '</span>'
			+ '</div>'
	}
	if (printStatuses.length === 3 && printStatuses.includes('queued') && printStatuses.includes('senttoprinter') && printStatuses.includes('printerreturned')) {
		if (errorsHandled === undefined) {
			return '<div class="tooltip-outer">'
				+ '<div class="status-bar status-bar-error">'
				+ '<div class="width-100 status-bar-outer">'
				+ '</div>'
				+ '</div>'
				+ '<span class="tooltip-inner tooltip-ml-0 printer-returned">'
				+ i18n.t("tooltips.printerReturned")
				+ '</span>'
				+ '</div>'
		} else {
			return '<div class="tooltip-outer">'
				+ '<div class="status-bar status-bar-error">'
				+ '<div class="width-100 status-bar-outer">'
				+ '</div>'
				+ '</div>'
				+ '<span class="tooltip-inner tooltip-ml-0 printer-returned-handled">'
				+ i18n.t("tooltips.printerReturnedHandled")
				+ '</span>'
				+ '</div>'
		}
	}
	if (printStatuses.length === 3 && printStatuses.includes('queued') && printStatuses.includes('senttoprinter') && printStatuses.includes('resenttoprinter')) {

		return '<div class="tooltip-outer">'
			+ '<div class="status-bar status-bar-info">'
			+ '<div class="width-50 status-bar-outer">'
			+ '</div>'
			+ '</div>'
			+ '<span class="tooltip-inner tooltip-ml-0 resent-to-printer">'
			+ i18n.t("tooltips.resentToPrinter")
			+ '</span>'
			+ '</div>'
	}
	if (printStatuses.length === 3 && printStatuses.includes('queued') && printStatuses.includes('senttoprinter') && printStatuses.includes('printererror')) {
		return '<div class="tooltip-outer">'
			+ '<div class="status-bar status-bar-error">'
			+ '<div class="width-100 status-bar-outer">'
			+ '</div>'
			+ '</div>'
			+ '<span class="tooltip-inner tooltip-ml-0 printer-error">'
			+ i18n.t("tooltips.printerError")
			+ '</span>'
			+ '</div>'
	}
	if (printStatuses.length === 3 && printStatuses.includes('queued') && printStatuses.includes('requeued') && printStatuses.includes('senttoprinter')) {
		return '<div class="tooltip-outer">'
			+ '<div class="status-bar status-bar-info">'
			+ '<div class="width-50 status-bar-outer">'
			+ '</div>'
			+ '</div>'
			+ '<span class="tooltip-inner tooltip-ml-0 resent-to-printer">'
			+ i18n.t("tooltips.sentToPrinter")
			+ '</span>'
			+ '</div>'
	}

	if (printStatuses.length >= 3
		&& !(printStatuses.includes('queued')
			|| printStatuses.includes('senttoprinter')
			|| printStatuses.includes('processedbyprinter')
			|| printStatuses.includes('printerprocessing')
			|| printStatuses.includes('resenttoprinter')
			|| printStatuses.includes('printerreturned'))) {

		let key = "tooltips." + printStatuses[printStatuses.length - 1]
		let classEl = '<div class="status-bar status-bar-info">'
		let toolTipEl = ''

		if (printStatuses[printStatuses.length - 1] === "senttoprinter") {
			key = 'tooltips.sentToPrinter'
			toolTipEl = '<div class="sent-to-printer tooltip-inner tooltip-ml-0">'
		} else if (printStatuses[printStatuses.length - 1] === "processedbyprinter") {
			key = 'tooltips.processedByPrinter'
			toolTipEl = '<div class="processed-by-printer tooltip-inner tooltip-ml-0">'
		} else if (printStatuses[printStatuses.length - 1] === "printerprocessing") {
			key = 'tooltips.printerProcessing'
			toolTipEl = '<div class="printer-processing tooltip-inner tooltip-ml-0">'
		} else if (printStatuses[printStatuses.length - 1] === "printerreturned") {
			classEl = '<div class="status-bar status-bar-error">'
		}
		return '<div class="tooltip-outer">'
			+ classEl
			+ '<div class="width-100 status-bar-outer">'
			+ '</div>'
			+ '</div>'
			+ toolTipEl
			+ i18n.t(key)
			+ '</span>'
			+ '</div>'
	}
	if (printStatuses.length === 4
		&& (printStatuses.includes('queued')
			|| printStatuses.includes('senttoprinter')
			|| printStatuses.includes('processedbyprinter')
			|| printStatuses.includes('printerprocessing')
			|| printStatuses.includes('resenttoprinter')
			|| printStatuses.includes('printerreturned'))) {

		let key = "tooltips." + printStatuses[printStatuses.length - 1]
		let toolTipEl = ''
		let statusEl = ''
		let classEl = '<div class="status-bar status-bar-info">'

		if (printStatuses[printStatuses.length - 1] === "senttoprinter") {
			key = 'tooltips.sentToPrinter'
			toolTipEl = '<div class="sent-to-printer tooltip-inner tooltip-ml-0">'
			statusEl = '<div class="width-50 status-bar-outer">'
		}

		if (printStatuses[printStatuses.length - 1] === "processedbyprinter") {
			key = 'tooltips.processedByPrinter'
			toolTipEl = '<div class="processed-by-printer tooltip-inner tooltip-ml-0">'
			statusEl = '<div class="width-100 status-bar-outer">'
		}
		if (printStatuses[printStatuses.length - 1] === "printerprocessing") {
			key = 'tooltips.printerProcessing'
			toolTipEl = '<div class="printer-processing tooltip-inner tooltip-ml-0">'
			statusEl = '<div class="width-75 status-bar-outer">'
		}
		if (printStatuses[printStatuses.length - 1] === "reprocessedbyprinter") {
			key = 'tooltips.reprocessedByPrinter'
			toolTipEl = '<div class="reprocessed-by-printer tooltip-inner tooltip-ml-0">'
			statusEl = '<div class="width-100 status-bar-outer">'
		}

		if (printStatuses[printStatuses.length - 1] === "printerreturned") {
			key = 'tooltips.printerReturned'
			toolTipEl = '<div class="printer-returned tooltip-inner tooltip-ml-0">'
			statusEl = '<div class="width-100 status-bar-outer">'
			classEl = '<div class="status-bar status-bar-error">'
		}

		return '<div class="tooltip-outer">'
			+ classEl
			+ statusEl
			+ '</div>'
			+ '</div>'
			+ toolTipEl
			+ i18n.t(key)
			+ '</span>'
			+ '</div>'
	}

	if (printStatuses.includes("sendtoprintererror")) {
		return '<div class="tooltip-outer">'
			+ '<div class="status-bar status-bar-error">'
			+ '<div class="width-25 status-bar-outer">'
			+ '</div>'
			+ '</div>'
			+ '<span class="tooltip-inner tooltip-ml-0 printer-returned">'
			+ i18n.t("tooltips.printerError")
			+ '</span>'
			+ '</div>'
	}
}