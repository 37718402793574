<template>
	<pop-up-window
		:show="this.$store.state.mandates.displayCancelMandate"
		:showClose=false
		class="doc-statuses-popup"
		additional-info=""
		:positionFixed=true
		fixed
	>
		<template #title>
			<h1>{{ $t('management.mandates.cancelMandate.title') }}</h1>
			<h1 v-if='mandate.mandateId'>
				{{ $t('management.mandates.cancelMandate.mandateId') }} {{ mandate.mandateId }}
			</h1>
			<h1 v-if='mandate.receiverEmail'>Email: {{ mandate.receiverEmail }}</h1>
		</template>
		<template #content>
			<div v-if="dataIsReady"
				 class="grid grid-rows-1 justify-items-center my-6">
				<div class="width-100 grey-bottom-border">
					<div class="one-item">
						<p class="base-text header-text">
							{{ $t('management.mandates.cancelMandate.cancelReason') }}
						</p>
						<input
							@click="setError('reason', false)"
							v-model="reason"
							type="text"
							class="form-control sm:w-40 2xl:w-full sm:mt-0"
						/>
						<p v-if="errors.reason" class="error">{{ $t('errors.cancelReason') }}</p>
					</div>
				</div>
				<div>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="close">
						{{ $t('buttons.cancelMandateNot') }}
					</button>
					<button class="btn custom-button shadow-md mr-2 showStatuses text-popup" @click="cancelMandate">
						{{ $t('buttons.cancelMandate') }}
					</button>
				</div>
			</div>
		</template>
	</pop-up-window>
</template>

<script>
import PopUpWindow from "../../UI/PopUpWindow"

export default {
	name: "CancelMandate",
	components: {PopUpWindow},
	data() {
		return {
			dataIsReady: false,
			mandate: {},
			reason: '',
			errors: {
				reason: false
			}
		}
	},
	computed: {
		mandateData() {
			return this.$store.state.mandates.mandateData
		}
	},
	watch: {
		mandateData(newVal) {
			if (Object.keys(newVal).length !== 0) {
				this.mandate = newVal
				this.dataIsReady = true
			}
		}
	},
	methods: {
		setError(field, value) {
			this.errors[field] = value
		},
		close() {
			this.reason = '';
			this.setError("reason", false)
			this.$store.commit('displayCancelMandate', false)
			this.$store.commit('setMandateData', {})
		},
		async cancelMandate() {
			if (this.reason === '') {
				this.setError("reason", true)
				return
			}
			let payload = {
				supplierGroupId: this.$store.state.supplierGroup.data.supplierGroupId,
				entityId: this.mandate.legalEntityIds[0],
				clientId: this.mandate.receiverId,
				mandateId: this.mandate.mandateId,
				reason: this.reason,
			}
			await this.$store.dispatch('mandateCancel', payload)
			this.close()
		}
	}
}
</script>

<style scoped>
.width-100 {
	width: 100%;
}

.one-item {
	display: grid;
	grid-template-columns: auto auto;
	grid-template-rows: 100%;
	align-items: center;
	margin-bottom: 20px;
	width: 100%;
}
</style>